
import { defineComponent, ref } from 'vue'
import api from '@/api/api'
import { ElMessage } from 'element-plus'
export default defineComponent({
  setup() {
    const tableNumber = ref([])
    const show = ref(false)
    const count = ref(0)
    const isLoading = ref(false)
    const open = groupId => {
      show.value = true
      tableNumber.value = []
      count.value = 0
      setTimeout(() => {
        isLoading.value = true
      }, 1000)
      api.reservedNumber
        .lookList(groupId)
        .then((res: any) => {
          tableNumber.value = res.list.map(item => {
            return {
              value: item
            }
          })
          count.value = res.count
          console.log('list', res)
          isLoading.value = false
        })
        .catch(err => {
          isLoading.value = false
          ElMessage.error(err.errMsg)
        })
    }
    return {
      tableNumber,
      show,
      open,
      count,
      isLoading
    }
  }
})
