
import { defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import api from '@/api/api'
import { ElMessage, ElMessageBox } from 'element-plus'
export default defineComponent({
  emits: ['showSidebar'],
  setup (prop:any, context:any) {
    const store = useStore()
    const name = store.state.name
    const role = store.state.role
    const isTrue = ref(false)
    const handleLogout = () => {
      ElMessageBox.confirm('是否确定退出', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        api.session.logout().then((res:any) => {
          console.log(res) // null
          window.sessionStorage.clear()
          window.location.reload()
        })
      }).catch(() => {
        ElMessage('取消成功')
      })
    }
    const handleIcon = () => {
      context.emit('showSidebar', isTrue.value = !isTrue.value)
    }
    return {
      name,
      role,
      handleLogout,
      handleIcon,
      isTrue
    }
  }
})
