<template>
  <suspense>
    <router-view></router-view>
  </suspense>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="sass">
@import '@/assets/styles/main'

body
  margin: 0
  font-family: 'Microsoft YaHei', Arial, Helvetica, sans-serif, '宋体'
  color: #545454
  font-size:15px
  background-color: #F2F2F2
#app
  font-family: Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
</style>
