
import { defineComponent, Ref, ref } from 'vue'
import numberInfo from './reservedNumber.vue'
import { useStore } from 'vuex'
import api from '@/api/api'
export default defineComponent({
  components: { numberInfo },
  setup() {
    const groups: Ref<any> = ref([])
    const showInfo = ref(false)
    const infoRef = ref()
    const store = useStore()
    const eventsId = store.state.eventsId
    // 获取列表
    const getList = () => {
      // api.reservedNumber.getGroupList(eventsId).then((res: any) => {
      api.player.getEventsToGroups(eventsId).then((res: any) => {
        console.log('组列表', res)
        if (res && res.length) {
          groups.value = res
        }
      })
    }
    getList()
    const checkDetail = group => {
      showInfo.value = true
      infoRef.value.open(group)
    }
    return { groups, checkDetail, showInfo, infoRef }
  }
})
