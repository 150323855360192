<template>
  <el-container>
      <div class="playerManagement">
        <div class="device-title">
          <span>设备管理</span>
        </div>
        <!-- 搜索 -->
        <el-row class="row-bg">
            <div class="playerManagement-search">
              <el-input placeholder="请输入赛事名称/设备序列号" v-model.trim="eventName"></el-input>
              <el-button type="primary" @click="handleGetInputSearchValue">搜索</el-button>
            </div>
            <div>
              <el-button @click="addAccountNumber">新增</el-button>
            </div>
        </el-row>
        <!-- table -->
        <el-card shadow="hover">
          <el-table :data="tableData" style="width: 100%" :header-cell-style="{textAlign: 'center', background: '#EFF7FF', color: '#222222'}" :cell-style="{ textAlign: 'center', color: '#666666' }">
            <el-table-column type="index" :index="tableIndex" label="序号" ></el-table-column>
            <el-table-column prop="name" label="设备名称" width="200" show-overflow-tooltip></el-table-column>
            <el-table-column prop="seq" label="设备序列号" width="200"></el-table-column>
            <el-table-column prop="events" label="绑定赛事" show-overflow-tooltip></el-table-column>
            <el-table-column prop="creator" label="添加人" width="100"></el-table-column>
            <el-table-column prop="createdAt" label="添加时间" width="200"></el-table-column>
            <el-table-column prop="bizType" label="业务类型" width="120px"></el-table-column>
            <el-table-column prop="user" label="绑定账号" width="180" show-overflow-tooltip></el-table-column>
            <el-table-column label="操作" width="150px" fixed="right" >
              <template #default="scope">
                <span @click="handleUnbound(scope.row)" class="unboundStyle">解绑</span>
                <span @click="handleEditDevice(scope.row)" class="editStyle">编辑</span>
                <span @click="handleDeleteEvent(scope.row)" class="deleteStyle">删除</span>
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
        <paging class="fixedRightBottom" :pageTotal="pageTotal" :currentPageSize="currentPageSize" @handle-current-change="handleCurrentChange"></paging>
        </el-card>
        <!-- 新增编辑设备 -->
        <el-dialog :title="titleMap[dialogStatus]" :close-on-click-modal="false" v-model="newEquipmentDialog" width="30%" center @close="resetForm">
          <el-form status-icon ref="ruleRef" :model="newEquipmentParamsObject" :rules="rules" label-suffix=" :" label-width="110px" class="demo-ruleForm">
          <el-form-item label="设备名称" prop="name">
            <el-input v-model.trim="newEquipmentParamsObject.name" autocomplete="off" placeholder="请输入设备名称"></el-input>
          </el-form-item>
          <el-form-item label="设备序列号" prop="seq">
            <el-input v-model.trim="newEquipmentParamsObject.seq" autocomplete="off" placeholder="请输入设备序列号"></el-input>
          </el-form-item>
          <el-form-item class="binding" v-if="titleMap[dialogStatus]=== '编辑设备'" label="绑定赛事">
            <span :class="[eventsName ? '' : 'emptyColor' ]">{{eventsName? eventsName : '暂无'}}</span>
          </el-form-item>
          <el-form-item class="binding" v-if="titleMap[dialogStatus]=== '编辑设备'" label="绑定账号">
            <el-tooltip class="item" effect="dark" :disabled="isShowTooltip" :content="accountName" placement="top">
              <span class="ells" @mouseover="onMouseOver(refName)">
                <span :ref="refName" :class="[accountName ? '' : 'emptyColor' ]" >{{accountName? accountName : '暂无'}}</span>
              </span>
            </el-tooltip>
            <el-button v-if="accountName"  plain type="primary" @click="handleAccountRelieve">解绑</el-button>
          </el-form-item>
           </el-form>
           <template #footer>
            <span class="dialog-footer">
              <el-button type="primary" plain @click="handleAddConfirm()">确认</el-button>
              <el-button type="info" plain @click="resetForm">取消</el-button>
            </span>
          </template>
        </el-dialog>
      </div>
  </el-container>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, watch, unref } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import paging from '../paging.vue'
import api from '@/api/api'
import { FormValidate } from '@/utils/format'
export default defineComponent({
  components: { paging },
  setup () {
    const eventName = ref()
    const tableData = ref()
    const ruleRef = ref(null)
    const editId = ref()
    const dialogStatus:any = ref()
    const titleMap:any = ref({
      addEquipment: '添加设备',
      editEquipment: '编辑设备'
    })
    const newEquipmentDialog = ref(false)
    const newEquipmentParamsObject = ref({
      name: '',
      seq: ''
    })
    const eventsId = ref()
    const eventsName = ref()
    const accountName = ref()
    // 表单验证
    const rules = ref({
      name: [
        { validator: FormValidate.FormOne().Name, trigger: 'blur', required: true }
      ],
      seq: [
        { trigger: 'blur', required: true, message: '请输入设备序号' }
      ]
    })
    const bizTypeId:any = ref()
    const pageTotal = ref(100)
    // 当前页数
    const currentPage = ref(1)
    // 当前页数条数
    const currentPageSize = ref(15)
    onMounted(() => {
      getDeviceList()
    })
    // 删除
    const handleDeleteEvent = (row:any) => {
      ElMessageBox.confirm('是否确定删除该设备', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 这里请求删除api
        api.device.deleteDevice(row.seq).then((res:any) => {
          if (res) {
            ElMessage.success('删除成功')
            getDeviceList()
          }
        }).catch((err) => {
          ElMessage.error(err.errMsg)
        })
      }).catch(() => {
        ElMessage('取消成功')
      })
    }
    // 新增
    const addAccountNumber = () => {
      newEquipmentParamsObject.value.name = ''
      newEquipmentParamsObject.value.seq = ''
      dialogStatus.value = 'addEquipment'
      newEquipmentDialog.value = true
    }
    // 编辑 绑定赛事
    const refName = ref()
    const handleEditDevice = (row:any) => {
      refName.value = ref(null as HTMLInputElement | null)
      newEquipmentDialog.value = true
      dialogStatus.value = 'editEquipment'
      editId.value = row.id
      eventsId.value = row.eventsId
      newEquipmentParamsObject.value = JSON.parse(JSON.stringify(row))
      eventsName.value = row.events
      accountName.value = row.user
      bizTypeId.value = row.bizType
    }
    // 编辑解绑
    const handleAccountRelieve = () => {
      accountName.value = ''
    }
    // 列表解绑
    const handleUnbound = (row:any) => {
      ElMessageBox.confirm('是否确定解绑', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 这里请求删除api
        api.device.relieveEvents(row.id).then((res:any) => {
          if (res) {
            ElMessage.success('解绑成功')
            newEquipmentDialog.value = false
            getDeviceList()
          }
        }).catch((err) => {
          ElMessage.error(err.errMsg)
        })
      }).catch(() => {
        ElMessage('取消成功')
      })
    }
    const handleAddConfirm = async () => {
      const form: any = unref(ruleRef)
      if (!form) return
      try {
        await form.validate()
        if (dialogStatus.value === 'addEquipment') {
          api.device.addDevice(newEquipmentParamsObject.value).then((res:any) => {
            if (res) {
              ElMessage.success('添加成功')
              newEquipmentDialog.value = false
              getDeviceList()
            }
          }).catch(err => {
            const result = err.errMsg.split(' ')
            if (result[result.length - 3] === 'name:') {
              ElMessage.error('设备名称已存在，请勿重复操作')
            } else if (result[result.length - 3] === 'seq:') {
              ElMessage.error('设备序列号已存在，请勿重复操作')
            } else {
              ElMessage.error(err.errMsg)
            }
          })
        } else {
          const editParams = {
            id: editId.value,
            name: newEquipmentParamsObject.value.name,
            seq: newEquipmentParamsObject.value.seq,
            events: eventsName.value ? eventsId.value : '',
            user: accountName.value,
            bizType: bizTypeId.value === '闸机设备' ? 1 : 2
          }
          api.device.editDevice(editParams).then((res:any) => {
            if (res) {
              ElMessage.success('编辑成功')
              newEquipmentDialog.value = false
              getDeviceList()
            }
          }).catch((err) => {
            const result = err.errMsg.split(' ')
            if (result[result.length - 3] === 'name:') {
              ElMessage.error('设备名称已存在，请勿重复操作')
            } else if (result[result.length - 3] === 'seq:') {
              ElMessage.error('设备序列号已存在，请勿重复操作')
            } else {
              ElMessage.error(err.errMsg)
            }
          })
        }
      } catch (error:any) {
        ElMessage.error(error.errMsg)
      }
    }
    // 取消清空所有表单值
    const resetForm = () => {
      const form: any = unref(ruleRef)
      form.resetFields()
      newEquipmentDialog.value = false
    }
    // 分页总条数
    const handleCurrentChange = (val:any) => {
      currentPage.value = val
      getDeviceList()
    }
    // 搜索
    const handleGetInputSearchValue = () => {
      getDeviceList()
    }
    // 监听数据是否为空
    watch(eventName, (nv) => {
      if (nv === '') {
        eventName.value = ' '
        getDeviceList()
      }
    }, { deep: true, immediate: true })
    // 获取赛事列表
    const getDeviceList = () => {
      api.device.getDeviceList({
        name: encodeURIComponent(eventName.value === undefined ? ' ' : eventName.value),
        page: currentPage.value,
        size: currentPageSize.value
      }).then((res:any) => {
        tableData.value = res.devices
        pageTotal.value = res.pages.total
      }).catch((err) => {
        ElMessage.error(err.errMsg)
      })
    }
    const isShowTooltip = ref(false)
    const onMouseOver = (val:any) => {
      const parentWidth = val.value.parentNode.offsetWidth
      const contentWidth = val.value.offsetWidth
      if (contentWidth > parentWidth) {
        isShowTooltip.value = false
      } else {
        isShowTooltip.value = true
      }
    }
    // 序列号自增
    const tableIndex = (index) => {
      return (currentPage.value - 1) * currentPageSize.value + index + 1
    }
    return {
      eventName,
      tableData,

      addAccountNumber,
      // 新增
      newEquipmentDialog,
      newEquipmentParamsObject,
      handleAddConfirm,
      ruleRef,
      resetForm,
      rules,
      handleEditDevice,
      dialogStatus,
      titleMap,
      handleDeleteEvent,

      pageTotal,
      currentPage,
      currentPageSize,
      handleCurrentChange,
      handleGetInputSearchValue,
      eventsId,
      handleAccountRelieve,
      handleUnbound,
      eventsName,
      accountName,
      refName,
      onMouseOver,
      isShowTooltip,
      tableIndex
    }
  }
})
</script>
<style lang="scss" scoped>
.playerManagement{
  width:100%;
  .device-title{
    font-size: 20px;
    font-weight: 600;
    color: #222222;
  }
  .row-bg{
    width:100%;
    height:90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    padding: 0 20px;
    margin-top: 15px;
    box-shadow: 0px 0.5px 10px #ddd;
    .playerManagement-search{
      display: flex;
      height: 20px;
    }
    .el-button{
      margin-left: 10px;
    }
  }
}
.matchStyle{
  .el-input{
    width:120px;
  }
  .el-button{
    margin: 10px 0px 0 10px;
  }
}
.dialog-footer{
  .el-button{
    margin-left: 40px;
  }
}
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-card {
  min-height:750px;
  position: relative;
  .el-table {
    color:#909399;
    font-size:14px;
  }
  .fixedRightBottom{
      position: absolute;
      bottom: 15px;
      right: 15px;
    }
}
.unboundStyle {
  color:#344C8A;
  margin-right:20px;
  cursor: pointer;
}
.editStyle{
  color:#2C88FE;
  margin-right:20px;
  cursor: pointer;
}
.deleteStyle{
  color: #F24B53;
  cursor: pointer;
}
:deep(.el-dialog__header){padding:20px 20px 15px;background-color: #EFF7FF}
.binding {
 position: relative;
 .emptyColor {
   color:#ccc;
 }
.el-button{
   position: absolute;
   right: 140px;
   top: 0;
 }
.ells {
  display: inline-block;
  width:180px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
 }
}
</style>
