<template>
  <div class="playerManagement-page">
    <el-pagination
    @current-change="handleCurrentChange"
    :page-size="currentPageSize"
    layout="total, prev, pager, next, jumper"
    :total="pageTotal">
    </el-pagination>
</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  emits: ['handleCurrentChange'],
  props: {
    pageTotal: {
      type: Number,
      required: true
    },
    currentPageSize: {
      type: Number,
      required: true
    }
  },
  setup (props, context) {
    // 当前选中页数
    const handleCurrentChange = (val:any) => {
      context.emit('handleCurrentChange', val)
    }
    return {
      handleCurrentChange
    }
  }
})
</script>
<style lang="scss" scoped>
 .playerManagement-page {
    // margin-top: 120px;
    display: flex;
    justify-content: flex-end;
  }
</style>
