<template>
  <el-container>
    <div class="playerManagement">
      <div class="group-title">
        <span>组别管理</span>
      </div>
      <!-- 搜索 -->
      <el-row class="row-bg">
        <div class="playerManagement-search">
          <el-input placeholder="请输入组别名称" v-model.trim="eventGroupName"></el-input>
          <el-button type="primary" @click="handleGetInputSearchValue">搜索</el-button>
        </div>
        <div>
          <el-button @click="addAccountNumber">新增</el-button>
        </div>
      </el-row>
      <!-- table -->
      <el-card shadow="hover">
        <el-table
          :data="tableData"
          style="width: 100%"
          :header-cell-style="{ textAlign: 'center', background: '#EFF7FF', color: '#222222' }"
          :cell-style="{ textAlign: 'center', color: '#666666' }"
        >
          <el-table-column type="index" :index="tableIndex" label="序号"></el-table-column>
          <el-table-column label="赛事类型" show-overflow-tooltip>
            <template #default="scope">
              <span>{{ eventTypeNames[scope.row.type] || '' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="组别名称" show-overflow-tooltip></el-table-column>
          <el-table-column prop="creator" label="添加人"></el-table-column>
          <el-table-column prop="createdAt" label="添加时间" width="250"></el-table-column>
          <el-table-column label="操作" width="100px" fixed="right">
            <template #default="scope">
              <span @click="handleEdit(scope.row)" class="editStyle">编辑</span>
              <span @click="handleDeleteEventGroup(scope.row)" class="deleteStyle">删除</span>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <paging
          class="fixedRightBottom"
          :pageTotal="pageTotal"
          :currentPageSize="currentPageSize"
          @handle-current-change="handleCurrentChange"
        ></paging>
      </el-card>
      <!-- 新增组别 -->
      <el-dialog title="新增组别" :close-on-click-modal="false" v-model="newGroupDialog" width="30%" center @close="resetForm">
        <el-form ref="ruleRef" :rules="rules" status-icon :model="addEventGroupParams" label-suffix=" :" label-width="100px" class="demo-ruleForm">
          <el-form-item label="赛事类型" prop="type">
            <el-select v-model="addEventGroupParams.type" style="width: 100%">
              <el-option v-for="item, key in eventTypeNames" :key="key" :label="item" :value="key"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="组别名称" prop="groupName">
            <el-input v-model.trim="addEventGroupParams.groupName" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="primary" plain @click="handleAddConfirm">确认</el-button>
            <el-button type="info" plain @click="handleCancelForm">取消</el-button>
          </span>
        </template>
      </el-dialog>
      <!-- 编辑 -->
      <el-dialog title="编辑组别" :close-on-click-modal="false" v-model="eventsEditDialog" width="30%" center @close="resetForm">
        <el-form status-icon ref="ruleRef" :rules="rules" :model="eventEditForm" label-suffix=" :" label-width="100px" class="demo-ruleForm">
          <el-form-item label="赛事类型" prop="type">
            <el-select v-model="eventEditForm.type" style="width: 100%">
              <el-option v-for="item, key in eventTypeNames" :key="key" :label="item" :value="key"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="组别名称" prop="groupName">
            <el-input v-model.trim="eventEditForm.groupName" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="primary" plain @click="handleEditConfirm">确认</el-button>
            <el-button type="info" plain @click="handleEditCancelForm">取消</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </el-container>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, watch, unref } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import paging from '../paging.vue'
import api from '@/api/api'
import { FormValidate } from '@/utils/format'
import { eventTypeNames } from '@/utils/enum'
export default defineComponent({
  components: { paging },
  setup() {
    const eventGroupName = ref()
    const tableData = ref()
    // 新增
    const newGroupDialog = ref(false)
    const addEventGroupParams = ref({
      type: '',
      groupName: '',
      sort: 0
    })
    const ruleRef = ref(null)
    const eventsEditDialog = ref(false)
    const eventEditForm: any = ref({
      type: '',
      groupId: '',
      groupName: '',
      sort: 0
    })
    // 总条数
    const pageTotal = ref(100)
    // 当前页数
    const currentPage = ref(1)
    // 当前页数条数
    const currentPageSize = ref(15)
    // 表单验证
    const rules = ref({
      groupName: [{ trigger: 'blur', required: true, validator: FormValidate.FormOne().NoEmpty }],
      type: [{ trigger: 'blur', required: true, message: '请选择赛事类型' }]
    })
    const addAccountNumber = () => {
      newGroupDialog.value = true
    }
    const handleAddConfirm = async () => {
      const form: any = unref(ruleRef)
      if (!form) return
      try {
        await form.validate()
        api.eventGroup
          .addEventGroup(addEventGroupParams.value)
          .then((res: any) => {
            if (res) {
              ElMessage.success('创建成功')
              newGroupDialog.value = false
              getEventGroupsList()
            }
          })
          .catch(err => {
            ElMessage.error(err.errMsg)
          })
      } catch (error: any) {
        ElMessage.error(error.err.Msg)
      }
    }
    // 取消并且清除数据
    const handleCancelForm = () => {
      const form: any = unref(ruleRef)
      form.resetFields()
      newGroupDialog.value = false
      eventsEditDialog.value = false
    }
    onMounted(() => {
      getEventGroupsList()
    })
    // 删除
    const handleDeleteEventGroup = (row: any) => {
      ElMessageBox.confirm('是否确定删除该组别', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          // 这里请求删除api
          api.eventGroup.deleteEventGroup({ groupId: row.id }).then(res => {
            if (res) {
              ElMessage.success('删除成功')
              getEventGroupsList()
            }
          })
        })
        .catch(() => {
          ElMessage('取消成功')
        })
    }
    const resetForm = () => {
      const form: any = unref(ruleRef)
      form.resetFields()
      newGroupDialog.value = false
    }
    const handleEdit = (row: any) => {
      eventsEditDialog.value = true
      eventEditForm.value.groupId = row.id
      eventEditForm.value.groupName = row.name
      eventEditForm.value.type = row.type
    }
    const handleEditConfirm = async () => {
      const form: any = unref(ruleRef)
      if (!form) return
      try {
        await form.validate()
        api.eventGroup
          .editEventGroup(eventEditForm.value)
          .then((res: any) => {
            if (res) {
              ElMessage.success('修改成功')
              eventsEditDialog.value = false
              getEventGroupsList()
            }
          })
          .catch(err => {
            ElMessage.error(err.errMsg)
          })
      } catch (error:any) {
        ElMessage.error(error.errMsg)
      }
    }
    const handleEditCancelForm = () => {
      eventsEditDialog.value = false
    }

    // 分页
    const handleCurrentChange = (val: any) => {
      currentPage.value = val
      getEventGroupsList()
    }
    // 搜索
    const handleGetInputSearchValue = () => {
      getEventGroupsList()
    }
    // 监听数据是否为空
    watch(
      eventGroupName,
      nv => {
        if (nv === '') {
          eventGroupName.value = ' '
          getEventGroupsList()
        }
      },
      { deep: true, immediate: true }
    )

    // 获取组别列表
    const getEventGroupsList = () => {
      api.eventGroup
        .getEventGroupList({
          name: encodeURIComponent(eventGroupName.value === undefined ? ' ' : eventGroupName.value),
          page: currentPage.value,
          size: currentPageSize.value
        })
        .then((res: any) => {
          tableData.value = res.groups
          pageTotal.value = res.pages.total
        })
        .catch(err => {
          ElMessage.error(err.errMsg)
        })
    }
    // 序列号自增
    const tableIndex = index => {
      return (currentPage.value - 1) * currentPageSize.value + index + 1
    }
    return {
      eventGroupName,
      tableData,
      eventTypeNames,
      addAccountNumber,
      handleEdit,
      handleCancelForm,

      handleDeleteEventGroup,
      handleEditCancelForm,

      pageTotal,
      currentPage,
      currentPageSize,
      handleCurrentChange,
      handleGetInputSearchValue,

      eventsEditDialog,
      eventEditForm,
      handleEditConfirm,

      //  新增
      newGroupDialog,
      addEventGroupParams,
      handleAddConfirm,
      rules,
      ruleRef,
      resetForm,
      tableIndex
    }
  }
})
</script>
<style lang="scss" scoped>
.playerManagement {
  width: 100%;
  .group-title {
    font-size: 20px;
    font-weight: 600;
    color: #222222;
  }
  .row-bg {
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    padding: 0 20px;
    margin-top: 15px;
    box-shadow: 0px 0.5px 10px #ddd;
    .playerManagement-search {
      display: flex;
      height: 20px;
    }
    .el-button {
      margin-left: 10px;
    }
  }
}
.matchStyle {
  .el-input {
    width: 120px;
  }
  .el-button {
    margin: 10px 0px 0 10px;
  }
}
.dialog-footer {
  .el-button {
    margin-left: 40px;
  }
}
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-card {
  min-height: 750px;
  position: relative;
  .el-table {
    color: #909399;
    font-size: 14px;
  }
  .fixedRightBottom {
    position: absolute;
    bottom: 15px;
    right: 15px;
  }
}
.editStyle {
  color: #2c88fe;
  margin-right: 20px;
  cursor: pointer;
}
.deleteStyle {
  color: #f24b53;
  cursor: pointer;
}
:deep(.el-dialog__header) {
  padding: 20px 20px 15px;
  background-color: #eff7ff;
}
</style>
