
import { defineComponent, ref, onMounted, watch, unref, computed } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { eventTypeNames } from '@/utils/enum'
import paging from '../paging.vue'
import { useOptions } from './events-hooks/sel-options'
import api from '@/api/api'
import { FormValidate } from '@/utils/format'
import useGroupList from './common-hooks/all-groups'
import uploadBg from './upload.vue'

export default defineComponent({
  components: { paging, uploadBg },
  setup() {
    const eventName = ref()
    const tableData = ref()
    const ruleRef = ref(null)
    const newEventsDialogVisible = ref(false)
    const gateEquipmentList: any = ref([])
    const gateEquipmentSelectValue = ref([])
    const newGateEquipmentList: any = ref([])
    const newPickingEquipmentList: any = ref([])
    const { eventGroupsList } = useGroupList()
    const pickingEquipmentList: any = ref([])
    const pickingEquipmentSelectValue = ref([])
    const eventsEditDialog = ref(false)
    const eventEditForm: any = ref({})
    const pageTotal = ref(100)
    const currentPage = ref(1)
    const currentPageSize = ref(15)
    const { IdentificationMode, selectOptions } = useOptions()
    const defaultList: any = ref([])
    const rules = ref({
      eventsId: [{ trigger: 'blur', required: true, validator: FormValidate.Form().EventsId }],
      name: [{ trigger: 'blur', required: true, message: '请输入赛事名称' }],
      faceMatchId: [{ trigger: 'blur', required: true, message: '请输入saas赛事id' }],
      time: [{ type: 'date', required: true, message: '请选择日期', trigger: 'change' }],
      type: [{ type: 'string', required: true, message: '请选择赛事类型' }],
      group: [{ type: 'array', required: true, message: '请选择分组' }],
      organizers: [{ trigger: 'blur', required: true, message: '请输入举办方' }],
      venue: [{ trigger: 'blur', required: true, message: '请输入举办地' }],
      recognition: [{ trigger: 'change', required: true, message: '请选择识别方式' }],
      isCheckFace: [{ trigger: 'change', required: true, message: '请选择' }],
      operation: [{ trigger: 'change', required: true, message: '请选择领物方式' }],
      note: [{ trigger: 'change', required: true, message: '请选择闸机类型' }],
      recognitionRate: [{ trigger: 'change', required: true, validator: FormValidate.Form().Accuracy }]
    })
    // 新增赛事
    const ruleForm: any = ref({
      eventsId: '',
      faceMatchId: '',
      name: '',
      organizers: '',
      venue: '',
      time: '',
      type: '',
      group: [],
      gates: [{ group: '', numFlag: '', numPartA: '', numPartB: '' }],
      operation: '',
      note: '',
      background: '',
      recognition: '',
      recognitionRate: 0.6,
      isCheckFace: true,
      afc: [],
      rec: []
    })

    // 上传图片成功
    const onUploadSuccess = info => {
      ruleForm.value.background = info
      eventEditForm.value.background = info
    }

    // 清空上传图片
    const onRemove = () => {
      ruleForm.value.background = ''
      eventEditForm.value.background = ''
      defaultList.value = []
    }

    // 举办时间转换为时间戳
    const handleTimeValue = () => {
      ruleForm.value.time = ruleForm.value.time.getTime()
    }
    // 新增按钮
    const addAccountNumber = () => {
      getDeliverySelect()
      newEventsDialogVisible.value = true
    }
    onMounted(() => {
      getEventList()
    })
    const addGroupsList = computed(() => {
      const groups = eventGroupsList.value.filter(e => e.type === ruleForm.value.type)
      return groups
    })
    const editGroupsList = computed(() => {
      const groups = eventGroupsList.value.filter(e => e.type === eventEditForm.value.type)
      return groups
    })
    // 新增设备绑定
    const getDeliverySelect = () => {
      api.device
        .getDeviceSelect()
        .then(res => {
          newGateEquipmentList.value = res
          newPickingEquipmentList.value = res
        })
        .catch(err => {
          ElMessage.error(err.errMsg)
        })
    }
    const addNumRule = () => {
      const params = ruleForm.value.gates.map(gate => {
        return {
          groupId: gate.group,
          fixNumber: gate.numFlag,
          startNumber: gate.numPartA
        }
      })
      return api.event.addRule(params)
    }
    // 新增提交api
    const handleAddConfirm = async () => {
      if (ruleForm.value.operation === '闸机分配号码布') {
        addNumRule()
          .then(() => {
            creatGate()
            resetForm()
          })
          .catch(err => {
            ElMessage.error(err.errMsg || '新增号码布规则失败')
          })
      } else {
        creatGate()
      }
    }
    // c创建赛事
    const creatGate = async () => {
      const form: any = unref(ruleRef)
      if (!form) return
      try {
        await form.validate()
      } catch (error) {
        if (error) {
          return false
        }
      }
      ruleForm.value.time = new Date(ruleForm.value.time).getTime()
      ruleForm.value.recognitionRate = Number(ruleForm.value.recognitionRate)
      api.event
        .addEvent(ruleForm.value)
        .then(res => {
          if (res) {
            ElMessage.success('新增成功')
            newEventsDialogVisible.value = false
            getEventList()
          }
        })
        .catch(err => {
          ElMessage.error(err.errMsg)
        })
    }
    // 删除
    const handleDeleteEvent = (row: any) => {
      ElMessageBox.confirm('是否确定删除该赛事', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          // 这里请求删除api
          if (row.afc.length === 0 && row.rec.length === 0) {
            api.event
              .deleteEvent({ eventsId: row.id })
              .then((res: any) => {
                if (!res) {
                  ElMessage.success('删除成功')
                  getEventList()
                }
              })
              .catch(err => {
                ElMessage.error(err.errMsg)
              })
          } else {
            ElMessage.error('该赛事绑定有设备,请先解绑设备后再删除赛事')
          }
        })
        .catch(() => {
          ElMessage('取消成功')
        })
    }
    const resetForm = () => {
      const form: any = unref(ruleRef)
      form.resetFields()
      ruleForm.value.operation = ''
      ruleForm.value.note = ''
      ruleForm.value.background = ''
      eventEditForm.value.background = ''
      defaultList.value = []
      eventsEditDialog.value = false
      newEventsDialogVisible.value = false
    }
    // 编辑
    const handleEditValue = async (val: any) => {
      eventEditForm.value.time = val.getTime()
    }
    const handleEdit = (row: any) => {
      api.device
        .getDeviceSelect()
        .then((res: any) => {
          // 编辑设备
          gateEquipmentList.value = res.concat(row.afc)
          pickingEquipmentList.value = res.concat(row.rec)
        })
        .catch(err => {
          ElMessage.error(err.errMsg)
        })
      const getGroupsName: any = ref([])
      row.enventsGroup.forEach((item: any) => {
        getGroupsName.value.push(item.groupId)
      })
      const getGroupsNameGetAfcName: any = ref([])
      row.afc.forEach((item: any) => {
        getGroupsNameGetAfcName.value.push(item.seq)
      })
      const getGroupsNameGetRecName: any = ref([])
      row.rec.forEach((item: any) => {
        getGroupsNameGetRecName.value.push(item.seq)
      })
      eventsEditDialog.value = true
      eventEditForm.value.id = row.id
      eventEditForm.value.faceMatchId = row.faceMatchId
      eventEditForm.value.name = row.name
      eventEditForm.value.organizers = row.organizers
      eventEditForm.value.venue = row.venue
      eventEditForm.value.time = row.time
      eventEditForm.value.operation = row.operation
      eventEditForm.value.note = row.note
      eventEditForm.value.group = getGroupsName.value
      eventEditForm.value.type = row.type
      eventEditForm.value.recognition = row.recognition
      eventEditForm.value.isCheckFace = row.isCheckFace || false
      eventEditForm.value.recognitionRate = row.recognitionRate
      eventEditForm.value.afc = getGroupsNameGetAfcName.value
      eventEditForm.value.rec = getGroupsNameGetRecName.value
      eventEditForm.value.background = row.background
      defaultList.value = row.background ? [{ url: row.background }] : []

      if (row.recognition === '闸机分配号码布') {
        eventEditForm.value.gates = row.gates
      }
    }
    const handleEditConfirm = async () => {
      const form: any = unref(ruleRef)
      if (!form) return
      const resultValue: any = ref(false)
      try {
        await form.validate()
        eventEditForm.value.time = new Date(eventEditForm.value.time).getTime()
        eventEditForm.value.recognitionRate = Number(eventEditForm.value.recognitionRate)
        eventEditForm.value.afc.forEach((item: any) => {
          resultValue.value = eventEditForm.value.rec.some((item1: any) => {
            return item === item1
          })
        })
        if (resultValue.value) {
          ElMessage.error('不能同时绑定两种设备')
        } else {
          api.event
            .eventEdit(eventEditForm.value)
            .then((res: any) => {
              if (res) {
                ElMessage.success('编辑成功')
                eventsEditDialog.value = false
                getEventList()
              }
            })
            .catch(err => {
              ElMessage.error(err.errMsg)
            })
        }
      } catch (error) {
        console.log(error)
      }
    }
    const handleCurrentChange = (val: any) => {
      currentPage.value = val
      getEventList()
    }
    // 搜索
    const handleGetInputSearchValue = () => {
      getEventList()
    }
    // 监听数据是否为空
    watch(
      eventName,
      nv => {
        if (nv === '') {
          eventName.value = ' '
          getEventList()
        }
      },
      { deep: true, immediate: true }
    )
    // 获取赛事列表
    const getEventList = () => {
      api.event
        .eventList({
          name: encodeURIComponent(eventName.value === undefined ? ' ' : eventName.value),
          page: currentPage.value,
          size: currentPageSize.value
        })
        .then((res: any) => {
          tableData.value = res.events
          pageTotal.value = res.pages.total
        })
        .catch(err => {
          ElMessage.error(err.errMsg)
        })
    }
    // 新增闸机簿
    const addGate = () => {
      ruleForm.value.gates.push({ group: '', numFlag: '', numPartA: '', numPartB: '' })
    }
    const delGate = key => {
      ruleForm.value.gates.splice(key, 1)
    }
    // 序列号自增
    const tableIndex = index => {
      return (currentPage.value - 1) * currentPageSize.value + index + 1
    }
    return {
      onRemove,
      defaultList,
      onUploadSuccess,

      eventName,
      tableData,
      eventTypeNames,
      addGroupsList,
      editGroupsList,

      addAccountNumber,
      handleAddConfirm,
      handleEdit,
      handleTimeValue,
      IdentificationMode,

      // 闸机
      gateEquipmentList,
      gateEquipmentSelectValue,

      // 领物
      pickingEquipmentList,
      pickingEquipmentSelectValue,

      newEventsDialogVisible,
      selectOptions,
      eventGroupsList,

      newGateEquipmentList,
      newPickingEquipmentList,
      ruleForm,
      handleDeleteEvent,

      pageTotal,
      currentPage,
      currentPageSize,
      handleCurrentChange,
      handleGetInputSearchValue,

      eventsEditDialog,
      eventEditForm,
      handleEditConfirm,
      handleEditValue,
      addGate,
      delGate,
      ruleRef,
      rules,
      resetForm,
      tableIndex
    }
  }
})
