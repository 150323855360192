import OSS from 'ali-oss'
import CryptoJS from 'crypto-js'
import Base64 from 'base-64'

export const baseOSSUrl = 'https://cdn-multi-xm.xempower.cn/'

export const accessKeyId = 'LTAI5tQbVkDAj1Gi4JXkbwpV'
const accessKeySecret = 'YJtFN2DtrXErdfny0aRkWsqkLbWmLf'

export const client = new OSS({
  region: 'oss-cn-hangzhou',
  secure: true,
  accessKeyId: accessKeyId,
  accessKeySecret: accessKeySecret,
  bucket: 'multi-xm'
})

const policyText = {
  expiration: '2099-01-01T12:00:00.000Z',
  conditions: [['content-length-range', 0, 1048576000000]],
}
export const policyBase64 = Base64.encode(JSON.stringify(policyText))
const bytes = CryptoJS.HmacSHA1(policyBase64, accessKeySecret)
export const aliOssSignature = bytes.toString(CryptoJS.enc.Base64)

export const path = 'goods/'

export function uploadFile(
  fileName: string,
  file: any
): Promise<OSS.PutObjectResult> {
  return client.put(path + fileName, file)
}

export function multipartUpload(fileName: string, file: any, progress?: (p: number, k?: any) => void) {
  return client.multipartUpload(path + fileName, file, {
    progress: function (p, checkpoint) {
      const pValue = Math.round(p * 100)
      progress && progress(pValue, checkpoint)
    },
    // 同时上传的分片数
    parallel: 4,
    // 设置分片大小 3M
    partSize: 1024 * 1024 * 3,
    mime: 'text/plain'
  })
}
