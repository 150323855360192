import { App } from '@vue/runtime-core'
import {
  ElButton,
  ElSelect,
  ElOption,
  ElInput,
  ElCheckboxGroup,
  ElCheckbox,
  ElContainer,
  ElMain,
  ElMenu,
  ElMenuItem,
  ElRow,
  ElCard,
  ElTable,
  ElTableColumn,
  ElPagination,
  ElDialog,
  ElForm,
  ElFormItem,
  ElTooltip,
  ElDatePicker,
  ElUpload,
  ElCascader,
  ElInputNumber
} from 'element-plus'
import locale from 'element-plus/lib/locale'
import lang from 'element-plus/lib/locale/lang/zh-cn'

export default (app: App): App => {
  locale.use(lang)
  app.config.globalProperties.$ELEMENT = { size: 'mini' }
  app
    .use(ElButton)
    .use(ElSelect)
    .use(ElOption)
    .use(ElInput)
    .use(ElCheckboxGroup)
    .use(ElCheckbox)
    .use(ElContainer)
    .use(ElMain)
    .use(ElMenu)
    .use(ElMenuItem)
    .use(ElRow)
    .use(ElCard)
    .use(ElTable)
    .use(ElTableColumn)
    .use(ElPagination)
    .use(ElDialog)
    .use(ElForm)
    .use(ElFormItem)
    .use(ElTooltip)
    .use(ElDatePicker)
    .use(ElUpload)
    .use(ElCascader)
    .use(ElInputNumber)
  return app
}
