export enum EventType {
  Marathon = 'marathon',
  Sanda = 'sanda',
  Hiphop = 'hiphop',
  Fight = 'fight',
  Boxing = 'boxing'
}
export const eventTypeNames = {
  marathon: '马拉松',
  sanda: '散打',
  hiphop: '街舞',
  fight: '自由搏击',
  boxing: '拳击'
}
