<template>
  <el-container>
    <div class="playerManagement">
      <div class="account-title">
        <span>账号管理</span>
      </div>
      <!-- 搜索 -->
      <el-row class="row-bg">
        <div class="playerManagement-search">
          <el-input placeholder="请输入账号名称" v-model.trim="accountName"></el-input>
          <el-button type="primary" @click="handleGetInputSearchValue">搜索</el-button>
        </div>
        <el-button @click="addAccountNumber">添加账号</el-button>
      </el-row>
      <!-- table -->
      <el-card shadow="hover">
        <el-table
          stripe
          :data="tableData"
          style="width: 100%"
          :header-cell-style="{ textAlign: 'center', background: '#EFF7FF', color: '#222222' }"
          :cell-style="{ textAlign: 'center', color: '#666666' }"
        >
          <el-table-column type="index" :index="tableIndex" label="序号"></el-table-column>
          <el-table-column prop="username" label="账号名称" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="role" label="角色"></el-table-column>
          <el-table-column prop="creator" label="创建人"></el-table-column>
          <el-table-column prop="createdAt" label="创建时间"></el-table-column>
          <el-table-column label="操作" width="100px" fixed="right">
            <template #default="scope">
              <span @click="handleEdit(scope.row)" class="editStyle">编辑</span>
              <span @click="handleDelete(scope.row)" class="deleteStyle">删除</span>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <paging
          class="fixedRightBottom"
          :pageTotal="pageTotal"
          :currentPageSize="currentPageSize"
          @handle-current-change="handleCurrentChange"
        ></paging>
      </el-card>
      <!-- 添加账号dialog -->
      <el-dialog
        :destroy-on-close="false"
        :close-on-click-modal="false"
        title="添加账号"
        v-model="centerDialogVisible"
        width="25%"
        center
        @close="resetForm"
      >
        <el-form status-icon ref="ruleRef" :rules="rules" :model="ruleForm" label-suffix=" :" label-width="100px" class="demo-ruleForm">
          <el-form-item label="账号名" prop="username">
            <el-input v-model.trim="ruleForm.username" autocomplete="off" placeholder="请输入账号名"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input
              placeholder="请输入密码"
              autocomplete="off"
              @paste.capture.prevent="handlePaste"
              v-model.trim="ruleForm.password"
              type="password"
              clearable
              show-password
            ></el-input>
          </el-form-item>
          <el-form-item label="角色" prop="roleId">
            <el-select v-model="ruleForm.roleId" placeholder="请选择角色">
              <el-option v-for="item in roleOptions" :key="item.roleId" :label="item.role" :value="item.roleId"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="绑定赛事" prop="eventsId">
            <el-select v-model="ruleForm.eventsId" placeholder="请选择绑定赛事" @change="changeEventsValue">
              <el-option v-for="item in matchOptions" :key="item.eventsId" :label="item.events" :value="item.eventsId"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="绑定设备" prop="deviceSeq">
            <el-select v-model="ruleForm.deviceSeq" placeholder="请选择绑定设备" @click="handleDevice(ruleForm.eventsId)">
              <el-option v-for="item in accountDeviceList" :key="item.seq" :label="item.name" :value="item.seq"> </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="primary" @click="handleAddMatch">保存</el-button>
          </span>
        </template>
      </el-dialog>
      <!-- 编辑 -->
      <el-dialog :close-on-click-modal="false" title="账号编辑" v-model="accountDialogVisible" width="25%" center @close="resetForm">
        <el-form status-icon ref="ruleRef" :rules="rules" :model="accountForm" label-suffix=" :" label-width="80px" class="demo-ruleForm">
          <el-form-item label="账号名" prop="username">
            <el-input disabled v-model.trim="accountForm.username" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item prop="password1" label="密码">
            <el-input
              id="password"
              ref="passwordValue"
              autocomplete="off"
              placeholder="请输入密码"
              @paste.capture.prevent="handlePaste"
              show-password
              type="password"
              v-model.trim="passwordParams"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="角色" prop="roleId">
            <el-select v-model="accountForm.roleId" placeholder="请选择">
              <el-option v-for="item in roleOptions" :key="item.roleId" :label="item.role" :value="item.roleId"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="赛事" prop="eventsId">
            <el-select v-model="accountForm.eventsId" placeholder="请选择绑定赛事" @change="changeEditEventsValue">
              <el-option v-for="item in matchOptions" :key="item.eventsId" :label="item.events" :value="item.eventsId"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设备" prop="deviceSeq">
            <el-select
              v-model="accountForm.deviceSeq"
              placeholder="请选择绑定设备"
              @change="handleChangeValue"
              @click="handleDevice(accountForm.eventsId)"
            >
              <el-option v-for="item in accountDeviceList" :key="item.seq" :label="item.name" :value="item.seq"> </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="primary" @click="handleClickEdit">保存</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </el-container>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, watch, unref } from 'vue'
import paging from '../paging.vue'
import api from '@/api/api'
import { ElMessage, ElMessageBox } from 'element-plus'
import { FormValidate } from '@/utils/format'
export default defineComponent({
  components: { paging },
  setup() {
    const accountName = ref()
    const tableData = ref()
    const ruleRef = ref(null)
    const roleOptions = ref()
    const roleValue = ref()
    const matchOptions = ref()
    const matchValue = ref('')
    const accountDeviceList = ref()
    const accountDialogVisible = ref(false)
    const passwordParams = ref('')
    const centerDialogVisible = ref(false)
    // 总条数  分页
    const pageTotal = ref(100)
    // 当前页数
    const currentPage = ref(1)
    // 当前页数条数
    const currentPageSize = ref(15)
    const ruleForm = ref({
      username: '',
      password: '',
      roleId: '',
      eventsId: '',
      deviceSeq: ''
    })
    const accountForm: any = ref({
      userId: '',
      username: '',
      password: '',
      password1: '',
      roleId: '',
      eventsId: '',
      deviceSeq: ''
    })
    const passwordValue = ref()
    // 判断是证件号码 还是身份证号码  进行验证规则
    const cardNumReg = (rule: any, value: any, callback: any) => {
      const regId = /^$|^[^\u4e00-\u9fa5 ]{6,15}$/
      if (!regId.test(passwordValue.value.modelValue)) {
        callback(new Error('请输入6~15位字符（数字，字母）'))
      } else if (!value) {
        return callback()
      }
    }
    // 表单验证
    const rules = ref({
      username: [{ validator: FormValidate.Form().UserName, trigger: 'blur', required: true }],
      password: [{ validator: FormValidate.Form().Password, trigger: 'blur', required: true }],
      roleId: [{ required: true, message: '请选择角色', trigger: 'change' }],
      password1: [{ trigger: 'change', validator: cardNumReg }]
    })
    // 监听搜索数据是否为空 为空则全部列表
    watch(
      accountName,
      nv => {
        if (nv === '') {
          accountName.value = ' '
          accountList()
        }
      },
      { deep: true, immediate: true }
    )

    onMounted(() => {
      accountList()
    })
    // 添加账号
    const addAccountNumber = () => {
      accountDeviceList.value = ''
      getRoleList()
      getMatchList()
      centerDialogVisible.value = true
    }
    const handleAddMatch = async () => {
      // 在这里可以发送数据添加账号api
      const form: any = unref(ruleRef)
      if (!form) return
      try {
        await form.validate()
        if (ruleForm.value.deviceSeq && !ruleForm.value.eventsId) {
          ElMessage.error('请先绑定赛事')
          return false
        }
        api.session
          .register(ruleForm.value)
          .then((res: any) => {
            if (res.result) {
              ElMessage.success('添加账号成功')
              centerDialogVisible.value = false
              accountList()
            } else {
              ElMessage.error('用户名已注册')
            }
          })
          .catch(err => {
            ElMessage.error(err.errMsg)
          })
      } catch (error:any) {
        ElMessage.error(error.errMsg)
      }
    }
    // 获取角色
    const getRoleList = () => {
      api.account
        .getAccountRoleList()
        .then((res: any) => {
          roleOptions.value = res
        })
        .catch(() => {
          ElMessage.error('未获取角到色列表')
        })
    }
    // 获取赛事进行绑定
    const getMatchList = () => {
      api.account
        .getAccountMatchList()
        .then((res: any) => {
          matchOptions.value = res
        })
        .catch(() => {
          ElMessage.error('未获取到赛事列表')
        })
    }
    // 点击X关闭清空数据
    const resetForm = () => {
      const form: any = unref(ruleRef)
      form.resetFields()
      accountDialogVisible.value = false
    }
    //  赛事
    const changeEventsValue = (val: any) => {
      ruleForm.value.deviceSeq = ''
      if (val) {
        deviceSeqApi(val)
      } else {
        ElMessage.error('请先选择需要绑定的赛事')
      }
    }
    // 设备api
    const deviceSeqApi = (val: any) => {
      api.device
        .getAccountDeviceList(val)
        .then((res: any) => {
          accountDeviceList.value = res
        })
        .catch(() => {
          ElMessage.error('未获取到设备列表')
        })
    }
    // 设备
    const changeEditEventsValue = (val: any) => {
      // 赛事值改变时 清空设备的值  让用户重新选择当前赛事下面的设备
      accountForm.value.deviceSeq = ''
      deviceSeqApi(val)
    }
    // 编辑保存
    // const deviceId = ref()
    const handleEdit = (row: any) => {
      accountDialogVisible.value = true
      getMatchList()
      getRoleList()
      accountForm.value.userId = row.userId
      accountForm.value.username = row.username
      accountForm.value.roleId = row.roleId
      accountForm.value.eventsId = row.eventsId
      accountForm.value.deviceSeq = row.deviceSeq
      // 设备
      const uni: any = ref([])
      const deviceObject: any = ref({
        name: '',
        seq: ''
      })
      deviceObject.value.name = row.device
      deviceObject.value.seq = row.deviceSeq
      if (deviceObject.value.name && deviceObject.value.seq) {
        uni.value.push(deviceObject.value)
      }
      accountDeviceList.value = uni.value
      if (row.eventsId) {
        api.device
          .getAccountDeviceList(row.eventsId)
          .then(async (res: any) => {
            accountDeviceList.value = (await uni.value) && res.concat(uni.value)
          })
          .catch(err => {
            ElMessage.error(err.errMsg)
          })
      }
      passwordParams.value = ''
    }

    const handleChangeValue = (val: any) => {
      accountForm.value.device = val
    }
    const handleClickEdit = async () => {
      const form: any = unref(ruleRef)
      if (!form) return
      try {
        await form.validate()
        const editParams = {
          userId: accountForm.value.userId,
          password: passwordParams.value ? passwordParams.value : '',
          roleId: accountForm.value.roleId,
          eventsId: accountForm.value.eventsId,
          deviceSeq: accountForm.value.deviceSeq
        }
        api.account
          .accountEdit(editParams)
          .then((res: any) => {
            if (res) {
              ElMessage.success('修改成功')
              accountList()
            }
          })
          .catch(err => {
            ElMessage.error(err.errMsg)
          })
        accountDialogVisible.value = false
      } catch (error:any) {
        ElMessage.error(error.errMsg)
      }
    }
    // 删除
    const handleDelete = (row: any) => {
      ElMessageBox.confirm('是否确定删除该账号', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          ElMessage.success('删除成功')
          api.account.accountDelete({ userId: row.userId }).then((res: any) => {
            if (res) {
              accountList()
            }
          })
        })
        .catch(() => {
          ElMessage('取消成功')
        })
    }
    // 获取子元素发送过来的点击事件以及参数
    const handleCurrentChange = (val: any) => {
      currentPage.value = val
      accountList()
    }
    const handleGetInputSearchValue = () => {
      accountList()
    }
    // 用户列表
    const accountList = () => {
      api.account
        .accountList({
          username: encodeURIComponent(accountName.value === undefined ? ' ' : accountName.value),
          page: currentPage.value,
          size: currentPageSize.value
        })
        .then((res: any) => {
          tableData.value = res.users
          pageTotal.value = res.pages.total
        })
        .catch(err => {
          ElMessage.error(err.errMsg)
        })
    }
    const handleDevice = (eventsIds: any) => {
      if (!eventsIds) {
        ElMessage.error('请先选择需要绑定的赛事')
      }
    }
    // 禁止密码输入框进行粘贴
    const handlePaste = () => {
      return false
    }
    // 序列号自增
    const tableIndex = index => {
      return (currentPage.value - 1) * currentPageSize.value + index + 1
    }
    return {
      accountName,
      tableData,

      handleGetInputSearchValue,

      addAccountNumber,
      matchOptions,
      matchValue,
      roleOptions,
      roleValue,

      handleEdit,
      passwordParams,
      handleClickEdit,
      handleDelete,
      accountForm,
      accountDialogVisible,

      centerDialogVisible,
      handleAddMatch,

      pageTotal,
      currentPageSize,
      handleCurrentChange,

      ruleForm,
      rules,
      ruleRef,
      accountDeviceList,
      handleChangeValue,
      changeEventsValue,
      changeEditEventsValue,
      resetForm,
      handleDevice,
      handlePaste,
      FormValidate,
      passwordValue,
      tableIndex
    }
  }
})
</script>
<style lang="scss" scoped>
.playerManagement {
  width: 100%;
  .account-title {
    font-size: 20px;
    font-weight: 600;
    color: #222222;
  }
  .row-bg {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 90px;
    background: #fff;
    padding: 0 20px;
    margin-top: 15px;
    box-shadow: 0px 0.5px 10px #ddd;
    .playerManagement-search {
      display: flex;
      height: 20px;
    }
    .el-button {
      margin-left: 10px;
    }
  }
}
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-card {
  min-height: 750px;
  position: relative;
  .el-table {
    color: #909399;
    font-size: 14px;
  }
  .fixedRightBottom {
    position: absolute;
    bottom: 15px;
    right: 15px;
  }
}
.editStyle {
  color: #2c88fe;
  margin-right: 20px;
  cursor: pointer;
}
.deleteStyle {
  color: #f24b53;
  cursor: pointer;
}
:deep(.el-dialog__header) {
  padding: 20px 20px 15px;
  background-color: #eff7ff;
}
</style>
