import { ref } from 'vue'
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useOptions = () => {
  const IdentificationMode = ref([
    {
      id: 1,
      value: '接口识别'
    },
    {
      id: 2,
      value: '人证识别'
    },
    {
      id: 3,
      value: '人照识别'
    }
  ])
  // 领物下拉选择
  const selectOptions = ref([
    {
      value: '预排号码布',
      label: '预排号码布'
    },
    {
      value: '现场绑定号码布',
      label: '现场绑定号码布'
    },
    {
      value: '闸机分配号码布',
      label: '闸机分配号码布'
    }
  ])
  return { IdentificationMode, selectOptions }
}
