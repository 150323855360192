
import { defineComponent, ref, onMounted, watch, unref } from 'vue'
import paging from '../paging.vue'
import api from '@/api/api'
import { ElMessage, ElMessageBox } from 'element-plus'
import { FormValidate } from '@/utils/format'
export default defineComponent({
  components: { paging },
  setup() {
    const accountName = ref()
    const tableData = ref()
    const ruleRef = ref(null)
    const roleOptions = ref()
    const roleValue = ref()
    const matchOptions = ref()
    const matchValue = ref('')
    const accountDeviceList = ref()
    const accountDialogVisible = ref(false)
    const passwordParams = ref('')
    const centerDialogVisible = ref(false)
    // 总条数  分页
    const pageTotal = ref(100)
    // 当前页数
    const currentPage = ref(1)
    // 当前页数条数
    const currentPageSize = ref(15)
    const ruleForm = ref({
      username: '',
      password: '',
      roleId: '',
      eventsId: '',
      deviceSeq: ''
    })
    const accountForm: any = ref({
      userId: '',
      username: '',
      password: '',
      password1: '',
      roleId: '',
      eventsId: '',
      deviceSeq: ''
    })
    const passwordValue = ref()
    // 判断是证件号码 还是身份证号码  进行验证规则
    const cardNumReg = (rule: any, value: any, callback: any) => {
      const regId = /^$|^[^\u4e00-\u9fa5 ]{6,15}$/
      if (!regId.test(passwordValue.value.modelValue)) {
        callback(new Error('请输入6~15位字符（数字，字母）'))
      } else if (!value) {
        return callback()
      }
    }
    // 表单验证
    const rules = ref({
      username: [{ validator: FormValidate.Form().UserName, trigger: 'blur', required: true }],
      password: [{ validator: FormValidate.Form().Password, trigger: 'blur', required: true }],
      roleId: [{ required: true, message: '请选择角色', trigger: 'change' }],
      password1: [{ trigger: 'change', validator: cardNumReg }]
    })
    // 监听搜索数据是否为空 为空则全部列表
    watch(
      accountName,
      nv => {
        if (nv === '') {
          accountName.value = ' '
          accountList()
        }
      },
      { deep: true, immediate: true }
    )

    onMounted(() => {
      accountList()
    })
    // 添加账号
    const addAccountNumber = () => {
      accountDeviceList.value = ''
      getRoleList()
      getMatchList()
      centerDialogVisible.value = true
    }
    const handleAddMatch = async () => {
      // 在这里可以发送数据添加账号api
      const form: any = unref(ruleRef)
      if (!form) return
      try {
        await form.validate()
        if (ruleForm.value.deviceSeq && !ruleForm.value.eventsId) {
          ElMessage.error('请先绑定赛事')
          return false
        }
        api.session
          .register(ruleForm.value)
          .then((res: any) => {
            if (res.result) {
              ElMessage.success('添加账号成功')
              centerDialogVisible.value = false
              accountList()
            } else {
              ElMessage.error('用户名已注册')
            }
          })
          .catch(err => {
            ElMessage.error(err.errMsg)
          })
      } catch (error:any) {
        ElMessage.error(error.errMsg)
      }
    }
    // 获取角色
    const getRoleList = () => {
      api.account
        .getAccountRoleList()
        .then((res: any) => {
          roleOptions.value = res
        })
        .catch(() => {
          ElMessage.error('未获取角到色列表')
        })
    }
    // 获取赛事进行绑定
    const getMatchList = () => {
      api.account
        .getAccountMatchList()
        .then((res: any) => {
          matchOptions.value = res
        })
        .catch(() => {
          ElMessage.error('未获取到赛事列表')
        })
    }
    // 点击X关闭清空数据
    const resetForm = () => {
      const form: any = unref(ruleRef)
      form.resetFields()
      accountDialogVisible.value = false
    }
    //  赛事
    const changeEventsValue = (val: any) => {
      ruleForm.value.deviceSeq = ''
      if (val) {
        deviceSeqApi(val)
      } else {
        ElMessage.error('请先选择需要绑定的赛事')
      }
    }
    // 设备api
    const deviceSeqApi = (val: any) => {
      api.device
        .getAccountDeviceList(val)
        .then((res: any) => {
          accountDeviceList.value = res
        })
        .catch(() => {
          ElMessage.error('未获取到设备列表')
        })
    }
    // 设备
    const changeEditEventsValue = (val: any) => {
      // 赛事值改变时 清空设备的值  让用户重新选择当前赛事下面的设备
      accountForm.value.deviceSeq = ''
      deviceSeqApi(val)
    }
    // 编辑保存
    // const deviceId = ref()
    const handleEdit = (row: any) => {
      accountDialogVisible.value = true
      getMatchList()
      getRoleList()
      accountForm.value.userId = row.userId
      accountForm.value.username = row.username
      accountForm.value.roleId = row.roleId
      accountForm.value.eventsId = row.eventsId
      accountForm.value.deviceSeq = row.deviceSeq
      // 设备
      const uni: any = ref([])
      const deviceObject: any = ref({
        name: '',
        seq: ''
      })
      deviceObject.value.name = row.device
      deviceObject.value.seq = row.deviceSeq
      if (deviceObject.value.name && deviceObject.value.seq) {
        uni.value.push(deviceObject.value)
      }
      accountDeviceList.value = uni.value
      if (row.eventsId) {
        api.device
          .getAccountDeviceList(row.eventsId)
          .then(async (res: any) => {
            accountDeviceList.value = (await uni.value) && res.concat(uni.value)
          })
          .catch(err => {
            ElMessage.error(err.errMsg)
          })
      }
      passwordParams.value = ''
    }

    const handleChangeValue = (val: any) => {
      accountForm.value.device = val
    }
    const handleClickEdit = async () => {
      const form: any = unref(ruleRef)
      if (!form) return
      try {
        await form.validate()
        const editParams = {
          userId: accountForm.value.userId,
          password: passwordParams.value ? passwordParams.value : '',
          roleId: accountForm.value.roleId,
          eventsId: accountForm.value.eventsId,
          deviceSeq: accountForm.value.deviceSeq
        }
        api.account
          .accountEdit(editParams)
          .then((res: any) => {
            if (res) {
              ElMessage.success('修改成功')
              accountList()
            }
          })
          .catch(err => {
            ElMessage.error(err.errMsg)
          })
        accountDialogVisible.value = false
      } catch (error:any) {
        ElMessage.error(error.errMsg)
      }
    }
    // 删除
    const handleDelete = (row: any) => {
      ElMessageBox.confirm('是否确定删除该账号', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          ElMessage.success('删除成功')
          api.account.accountDelete({ userId: row.userId }).then((res: any) => {
            if (res) {
              accountList()
            }
          })
        })
        .catch(() => {
          ElMessage('取消成功')
        })
    }
    // 获取子元素发送过来的点击事件以及参数
    const handleCurrentChange = (val: any) => {
      currentPage.value = val
      accountList()
    }
    const handleGetInputSearchValue = () => {
      accountList()
    }
    // 用户列表
    const accountList = () => {
      api.account
        .accountList({
          username: encodeURIComponent(accountName.value === undefined ? ' ' : accountName.value),
          page: currentPage.value,
          size: currentPageSize.value
        })
        .then((res: any) => {
          tableData.value = res.users
          pageTotal.value = res.pages.total
        })
        .catch(err => {
          ElMessage.error(err.errMsg)
        })
    }
    const handleDevice = (eventsIds: any) => {
      if (!eventsIds) {
        ElMessage.error('请先选择需要绑定的赛事')
      }
    }
    // 禁止密码输入框进行粘贴
    const handlePaste = () => {
      return false
    }
    // 序列号自增
    const tableIndex = index => {
      return (currentPage.value - 1) * currentPageSize.value + index + 1
    }
    return {
      accountName,
      tableData,

      handleGetInputSearchValue,

      addAccountNumber,
      matchOptions,
      matchValue,
      roleOptions,
      roleValue,

      handleEdit,
      passwordParams,
      handleClickEdit,
      handleDelete,
      accountForm,
      accountDialogVisible,

      centerDialogVisible,
      handleAddMatch,

      pageTotal,
      currentPageSize,
      handleCurrentChange,

      ruleForm,
      rules,
      ruleRef,
      accountDeviceList,
      handleChangeValue,
      changeEventsValue,
      changeEditEventsValue,
      resetForm,
      handleDevice,
      handlePaste,
      FormValidate,
      passwordValue,
      tableIndex
    }
  }
})
