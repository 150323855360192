export const cityData = [
  {
    code: '010',
    name: '北京',
    pinyin: 'Beijing',
    label: 'Beijing010'
  },
  {
    code: '025',
    name: '南京',
    pinyin: 'Nanjing',
    label: 'Nanjing025'
  },
  {
    code: '0515',
    name: '盐城',
    pinyin: 'Yancheng',
    label: 'Yancheng0515'
  },
  {
    code: '027',
    name: '武汉',
    pinyin: 'Wuhan',
    label: 'Wuhan027'
  },
  {
    code: '020',
    name: '广州',
    pinyin: 'Guangzhou',
    label: 'Guangzhou020'
  },
  {
    code: '029',
    name: '西安',
    pinyin: 'Xian',
    label: 'Xian029'
  },
  {
    code: '021',
    name: '上海',
    pinyin: 'Shanghai',
    label: 'Shanghai021'
  },
  {
    code: '00852',
    name: '香港',
    pinyin: 'HongKong',
    label: 'HongKong00852'
  },
  {
    code: '022',
    name: '天津',
    pinyin: 'Tianjin',
    label: 'Tianjin022'
  },
  {
    code: '0371',
    name: '郑州',
    pinyin: 'Zhengzhou',
    label: 'Zhengzhou0371'
  },
  {
    code: '028',
    name: '成都',
    pinyin: 'Chengdu',
    label: 'Chengdu028'
  },
  {
    code: '0312',
    name: '保定',
    pinyin: 'Baoding',
    label: 'Baoding0312'
  },
  {
    code: '0574',
    name: '宁波',
    pinyin: 'Ningbo',
    label: 'Ningbo0574'
  },
  {
    code: '0512',
    name: '苏州',
    pinyin: 'Suzhou',
    label: 'Suzhou0512'
  },
  {
    code: '0592',
    name: '厦门',
    pinyin: 'Xiamen',
    label: 'Xiamen0592'
  },
  {
    code: '0755',
    name: '深圳',
    pinyin: 'Shenzhen',
    label: 'Shenzhen0755'
  },
  {
    code: '0571',
    name: '杭州',
    pinyin: 'Hangzhou',
    label: 'Hangzhou0571'
  },
  {
    code: '0757',
    name: '佛山',
    pinyin: 'Foshan',
    label: 'Foshan0757'
  },
  {
    code: '00001',
    name: '台北',
    pinyin: 'TaiBei',
    label: 'TaiBei00001'
  },
  {
    code: '00002',
    name: '新北',
    pinyin: 'XinBei',
    label: 'XinBei00002'
  },
  {
    code: '00003',
    name: '台中',
    pinyin: 'TaiZhong',
    label: 'TaiZhong00003'
  },
  {
    code: '00004',
    name: '台南',
    pinyin: 'TaiNan',
    label: 'TaiNan00004'
  },
  {
    code: '00006',
    name: '桃园',
    pinyin: 'TaoYuan',
    label: 'TaoYuan00006'
  },
  {
    code: '00005',
    name: '高雄',
    pinyin: 'GaoXiong',
    label: 'GaoXiong00005'
  },
  {
    code: '00008',
    name: '澳门',
    pinyin: 'AoMen',
    label: 'AoMen00008'
  },
  {
    code: '0997',
    name: '阿克苏',
    pinyin: 'Akesu',
    label: 'Akesu0997'
  },
  {
    code: '0837',
    name: '阿坝',
    pinyin: 'Aba',
    label: 'Aba0837'
  },
  {
    code: '0483',
    name: '阿拉善盟',
    pinyin: 'Alashanmeng',
    label: 'Alashanmeng0483'
  },
  {
    code: '0906',
    name: '阿勒泰',
    pinyin: 'Aletai',
    label: 'Aletai0906'
  },
  {
    code: '0897',
    name: '阿里',
    pinyin: 'Ali',
    label: 'Ali0897'
  },
  {
    code: '0915',
    name: '安康',
    pinyin: 'Ankang',
    label: 'Ankang0915'
  },
  {
    code: '0556',
    name: '安庆',
    pinyin: 'Anqing',
    label: 'Anqing0556'
  },
  {
    code: '0412',
    name: '鞍山',
    pinyin: 'Anshan',
    label: 'Anshan0412'
  },
  {
    code: '0853',
    name: '安顺',
    pinyin: 'Anshun',
    label: 'Anshun0853'
  },
  {
    code: '0372',
    name: '安阳',
    pinyin: 'Anyang',
    label: 'Anyang0372'
  },
  {
    code: '0451',
    name: '阿城',
    pinyin: 'Acheng',
    label: 'Acheng0451'
  },
  {
    code: '0796',
    name: '安福',
    pinyin: 'Anfu',
    label: 'Anfu0796'
  },
  {
    code: '0572',
    name: '安吉',
    pinyin: 'Anji',
    label: 'Anji0572'
  },
  {
    code: '0871',
    name: '安宁',
    pinyin: 'Anning',
    label: 'Anning0871'
  },
  {
    code: '0536',
    name: '安丘',
    pinyin: 'Anqiu',
    label: 'Anqiu0536'
  },
  {
    code: '0595',
    name: '安溪',
    pinyin: 'Anxi',
    label: 'Anxi0595'
  },
  {
    code: '0791',
    name: '安义',
    pinyin: 'Anyi',
    label: 'Anyi0791'
  },
  {
    code: '0797',
    name: '安远',
    pinyin: 'Anyuan',
    label: 'Anyuan0797'
  },
  {
    code: '0436',
    name: '白城',
    pinyin: 'Baicheng',
    label: 'Baicheng0436'
  },
  {
    code: '0776',
    name: '百色',
    pinyin: 'Baise',
    label: 'Baise0776'
  },
  {
    code: '0439',
    name: '白山',
    pinyin: 'Baishan',
    label: 'Baishan0439'
  },
  {
    code: '0943',
    name: '白银',
    pinyin: 'Baiyin',
    label: 'Baiyin0943'
  },
  {
    code: '0552',
    name: '蚌埠',
    pinyin: 'Bangbu',
    label: 'Bangbu0552'
  },
  {
    code: '0917',
    name: '宝鸡',
    pinyin: 'Baoji',
    label: 'Baoji0917'
  },
  {
    code: '0875',
    name: '保山',
    pinyin: 'Baoshan',
    label: 'Baoshan0875'
  },
  {
    code: '0472',
    name: '包头',
    pinyin: 'Baotou',
    label: 'Baotou0472'
  },
  {
    code: '0478',
    name: '巴彦淖尔',
    pinyin: 'Bayannaoer',
    label: 'Bayannaoer0478'
  },
  {
    code: '0996',
    name: '巴音郭楞',
    pinyin: 'Bayinguoleng',
    label: 'Bayinguoleng0996'
  },
  {
    code: '0827',
    name: '巴中',
    pinyin: 'Bazhong',
    label: 'Bazhong0827'
  },
  {
    code: '0779',
    name: '北海',
    pinyin: 'Beihai',
    label: 'Beihai0779'
  },
  {
    code: '0414',
    name: '本溪',
    pinyin: 'Benxi',
    label: 'Benxi0414'
  },
  {
    code: '0857',
    name: '毕节',
    pinyin: 'Bijie',
    label: 'Bijie0857'
  },
  {
    code: '0543',
    name: '滨州',
    pinyin: 'Binzhou',
    label: 'Binzhou0543'
  },
  {
    code: '0909',
    name: '博尔塔拉',
    pinyin: 'Boertala',
    label: 'Boertala0909'
  },
  {
    code: '0558',
    name: '亳州',
    pinyin: 'Bozhou',
    label: 'Bozhou0558'
  },
  {
    code: '0514',
    name: '宝应',
    pinyin: 'Baoying',
    label: 'Baoying0514'
  },
  {
    code: '0451',
    name: '巴彦',
    pinyin: 'Bayan',
    label: 'Bayan0451'
  },
  {
    code: '0515',
    name: '滨海',
    pinyin: 'Binhai',
    label: 'Binhai0515'
  },
  {
    code: '0451',
    name: '宾县',
    pinyin: 'Binxian',
    label: 'Binxian0451'
  },
  {
    code: '0771',
    name: '宾阳',
    pinyin: 'Binyang',
    label: 'Binyang0771'
  },
  {
    code: '023',
    name: '璧山',
    pinyin: 'Bishan',
    label: 'Bishan023'
  },
  {
    code: '0391',
    name: '博爱',
    pinyin: 'Boai',
    label: 'Boai0391'
  },
  {
    code: '0752',
    name: '博罗',
    pinyin: 'Boluo',
    label: 'Boluo0752'
  },
  {
    code: '0543',
    name: '博兴',
    pinyin: 'Boxing',
    label: 'Boxing0543'
  },
  {
    code: '023',
    name: '重庆',
    pinyin: 'Chongqing',
    label: 'Chongqing023'
  },
  {
    code: '0431',
    name: '长春',
    pinyin: 'Changchun',
    label: 'Changchun0431'
  },
  {
    code: '0731',
    name: '长沙',
    pinyin: 'Changsha',
    label: 'Changsha0731'
  },
  {
    code: '0519',
    name: '常州',
    pinyin: 'Changzhou',
    label: 'Changzhou0519'
  },
  {
    code: '0317',
    name: '沧州',
    pinyin: 'Cangzhou',
    label: 'Cangzhou0317'
  },
  {
    code: '0736',
    name: '常德',
    pinyin: 'Changde',
    label: 'Changde0736'
  },
  {
    code: '0895',
    name: '昌都',
    pinyin: 'Changdu',
    label: 'Changdu0895'
  },
  {
    code: '0997',
    name: '昌吉',
    pinyin: 'Changji',
    label: 'Changji0997'
  },
  {
    code: '0355',
    name: '长治',
    pinyin: 'Changzhi',
    label: 'Changzhi0355'
  },
  {
    code: '0565',
    name: '巢湖',
    pinyin: 'Chaohu',
    label: 'Chaohu0565'
  },
  {
    code: '0421',
    name: '朝阳',
    pinyin: 'Chaoyang',
    label: 'Chaoyang0421'
  },
  {
    code: '0768',
    name: '潮州',
    pinyin: 'Chaozhou',
    label: 'Chaozhou0768'
  },
  {
    code: '0314',
    name: '承德',
    pinyin: 'Chengde',
    label: 'Chengde0314'
  },
  {
    code: '0735',
    name: '郴州',
    pinyin: 'Chenzhou',
    label: 'Chenzhou0735'
  },
  {
    code: '0476',
    name: '赤峰',
    pinyin: 'Chifeng',
    label: 'Chifeng0476'
  },
  {
    code: '0566',
    name: '池州',
    pinyin: 'Chizhou',
    label: 'Chizhou0566'
  },
  {
    code: '0771',
    name: '崇左',
    pinyin: 'Chongzuo',
    label: 'Chongzuo0771'
  },
  {
    code: '0875',
    name: '楚雄',
    pinyin: 'Chuxiong',
    label: 'Chuxiong0875'
  },
  {
    code: '0550',
    name: '滁州',
    pinyin: 'Chuzhou',
    label: 'Chuzhou0550'
  },
  {
    code: '0577',
    name: '苍南',
    pinyin: 'Cangnan',
    label: 'Cangnan0577'
  },
  {
    code: '0539',
    name: '苍山',
    pinyin: 'Cangshan',
    label: 'Cangshan0539'
  },
  {
    code: '0530',
    name: '曹县',
    pinyin: 'Caoxian',
    label: 'Caoxian0530'
  },
  {
    code: '0535',
    name: '长岛',
    pinyin: 'Changdao',
    label: 'Changdao0535'
  },
  {
    code: '0551',
    name: '长丰',
    pinyin: 'Changfeng',
    label: 'Changfeng0551'
  },
  {
    code: '0411',
    name: '长海',
    pinyin: 'Changhai',
    label: 'Changhai0411'
  },
  {
    code: '0591',
    name: '长乐',
    pinyin: 'Changle',
    label: 'Changle0591'
  },
  {
    code: '0536',
    name: '昌乐',
    pinyin: 'Changle',
    label: 'Changle0536'
  },
  {
    code: '0570',
    name: '常山',
    pinyin: 'Changshan',
    label: 'Changshan0570'
  },
  {
    code: '0512',
    name: '常熟',
    pinyin: 'Changshu',
    label: 'Changshu0512'
  },
  {
    code: '0596',
    name: '长泰',
    pinyin: 'Changtai',
    label: 'Changtai0596'
  },
  {
    code: '0597',
    name: '长汀',
    pinyin: 'Changting',
    label: 'Changting0597'
  },
  {
    code: '0572',
    name: '长兴',
    pinyin: 'Changxing',
    label: 'Changxing0572'
  },
  {
    code: '0536',
    name: '昌邑',
    pinyin: 'Changyi',
    label: 'Changyi0536'
  },
  {
    code: '0768',
    name: '潮安',
    pinyin: 'Chaoan',
    label: 'Chaoan0768'
  },
  {
    code: '0871',
    name: '呈贡',
    pinyin: 'Chenggong',
    label: 'Chenggong0871'
  },
  {
    code: '023',
    name: '城口',
    pinyin: 'Chengkou',
    label: 'Chengkou023'
  },
  {
    code: '0530',
    name: '成武',
    pinyin: 'Chengwu',
    label: 'Chengwu0530'
  },
  {
    code: '0635',
    name: '茌平',
    pinyin: 'Chiping',
    label: 'Chiping0635'
  },
  {
    code: '0794',
    name: '崇仁',
    pinyin: 'Chongren',
    label: 'Chongren0794'
  },
  {
    code: '0797',
    name: '崇义',
    pinyin: 'Chongyi',
    label: 'Chongyi0797'
  },
  {
    code: '028',
    name: '崇州',
    pinyin: 'Chongzhou',
    label: 'Chongzhou028'
  },
  {
    code: '0571',
    name: '淳安',
    pinyin: 'Chunan',
    label: 'Chunan0571'
  },
  {
    code: '0574',
    name: '慈溪',
    pinyin: 'Cixi',
    label: 'Cixi0574'
  },
  {
    code: '020',
    name: '从化',
    pinyin: 'Conghua',
    label: 'Conghua020'
  },
  {
    code: '0556',
    name: '枞阳',
    pinyin: 'Congyang',
    label: 'Congyang0556'
  },
  {
    code: '0411',
    name: '大连',
    pinyin: 'Dalian',
    label: 'Dalian0411'
  },
  {
    code: '0769',
    name: '东莞',
    pinyin: 'Dongguan',
    label: 'Dongguan0769'
  },
  {
    code: '0872',
    name: '大理',
    pinyin: 'Dali',
    label: 'Dali0872'
  },
  {
    code: '0415',
    name: '丹东',
    pinyin: 'Dandong',
    label: 'Dandong0415'
  },
  {
    code: '0459',
    name: '大庆',
    pinyin: 'Daqing',
    label: 'Daqing0459'
  },
  {
    code: '0352',
    name: '大同',
    pinyin: 'Datong',
    label: 'Datong0352'
  },
  {
    code: '0457',
    name: '大兴安岭',
    pinyin: 'Daxinganling',
    label: 'Daxinganling0457'
  },
  {
    code: '0818',
    name: '达州',
    pinyin: 'Dazhou',
    label: 'Dazhou0818'
  },
  {
    code: '0692',
    name: '德宏',
    pinyin: 'Dehong',
    label: 'Dehong0692'
  },
  {
    code: '0838',
    name: '德阳',
    pinyin: 'Deyang',
    label: 'Deyang0838'
  },
  {
    code: '0534',
    name: '德州',
    pinyin: 'Dezhou',
    label: 'Dezhou0534'
  },
  {
    code: '0932',
    name: '定西',
    pinyin: 'Dingxi',
    label: 'Dingxi0932'
  },
  {
    code: '0887',
    name: '迪庆',
    pinyin: 'Diqing',
    label: 'Diqing0887'
  },
  {
    code: '0546',
    name: '东营',
    pinyin: 'Dongying',
    label: 'Dongying0546'
  },
  {
    code: '0515',
    name: '大丰',
    pinyin: 'Dafeng',
    label: 'Dafeng0515'
  },
  {
    code: '0580',
    name: '岱山',
    pinyin: 'Daishan',
    label: 'Daishan0580'
  },
  {
    code: '0557',
    name: '砀山',
    pinyin: 'Dangshan',
    label: 'Dangshan0557'
  },
  {
    code: '0555',
    name: '当涂',
    pinyin: 'Dangtu',
    label: 'Dangtu0555'
  },
  {
    code: '0530',
    name: '单县',
    pinyin: 'Danxian',
    label: 'Danxian0530'
  },
  {
    code: '0511',
    name: '丹阳',
    pinyin: 'Danyang',
    label: 'Danyang0511'
  },
  {
    code: '0753',
    name: '大埔',
    pinyin: 'Dapu',
    label: 'Dapu0753'
  },
  {
    code: '0598',
    name: '大田',
    pinyin: 'Datian',
    label: 'Datian0598'
  },
  {
    code: '028',
    name: '大邑',
    pinyin: 'Dayi',
    label: 'Dayi028'
  },
  {
    code: '0797',
    name: '大余',
    pinyin: 'Dayu',
    label: 'Dayu0797'
  },
  {
    code: '023',
    name: '大足',
    pinyin: 'Dazu',
    label: 'Dazu023'
  },
  {
    code: '0792',
    name: '德安',
    pinyin: 'Dean',
    label: 'Dean0792'
  },
  {
    code: '0595',
    name: '德化',
    pinyin: 'Dehua',
    label: 'Dehua0595'
  },
  {
    code: '0431',
    name: '德惠',
    pinyin: 'Dehui',
    label: 'Dehui0431'
  },
  {
    code: '0371',
    name: '登封',
    pinyin: 'Dengfeng',
    label: 'Dengfeng0371'
  },
  {
    code: '0572',
    name: '德清',
    pinyin: 'Deqing',
    label: 'Deqing0572'
  },
  {
    code: '0758',
    name: '德庆',
    pinyin: 'Deqing',
    label: 'Deqing0758'
  },
  {
    code: '0793',
    name: '德兴',
    pinyin: 'Dexing',
    label: 'Dexing0793'
  },
  {
    code: '0668',
    name: '电白',
    pinyin: 'Dianbai',
    label: 'Dianbai0668'
  },
  {
    code: '023',
    name: '垫江',
    pinyin: 'Dianjiang',
    label: 'Dianjiang023'
  },
  {
    code: '0797',
    name: '定南',
    pinyin: 'Dingnan',
    label: 'Dingnan0797'
  },
  {
    code: '0530',
    name: '定陶',
    pinyin: 'Dingtao',
    label: 'Dingtao0530'
  },
  {
    code: '0550',
    name: '定远',
    pinyin: 'Dingyuan',
    label: 'Dingyuan0550'
  },
  {
    code: '0635',
    name: '东阿',
    pinyin: 'Donga',
    label: 'Donga0635'
  },
  {
    code: '0518',
    name: '东海',
    pinyin: 'Donghai',
    label: 'Donghai0518'
  },
  {
    code: '0530',
    name: '东明',
    pinyin: 'Dongming',
    label: 'Dongming0530'
  },
  {
    code: '0538',
    name: '东平',
    pinyin: 'Dongping',
    label: 'Dongping0538'
  },
  {
    code: '0596',
    name: '东山',
    pinyin: 'Dongshan',
    label: 'Dongshan0596'
  },
  {
    code: '0515',
    name: '东台',
    pinyin: 'Dongtai',
    label: 'Dongtai0515'
  },
  {
    code: '0577',
    name: '洞头',
    pinyin: 'Dongtou',
    label: 'Dongtou0577'
  },
  {
    code: '0794',
    name: '东乡',
    pinyin: 'Dongxiang',
    label: 'Dongxiang0794'
  },
  {
    code: '0579',
    name: '东阳',
    pinyin: 'Dongyang',
    label: 'Dongyang0579'
  },
  {
    code: '0762',
    name: '东源',
    pinyin: 'Dongyuan',
    label: 'Dongyuan0762'
  },
  {
    code: '0566',
    name: '东至',
    pinyin: 'Dongzhi',
    label: 'Dongzhi0566'
  },
  {
    code: '0792',
    name: '都昌',
    pinyin: 'Duchang',
    label: 'Duchang0792'
  },
  {
    code: '028',
    name: '都江堰',
    pinyin: 'Dujiangyan',
    label: 'Dujiangyan028'
  },
  {
    code: '0718',
    name: '恩施',
    pinyin: 'Enshi',
    label: 'Enshi0718'
  },
  {
    code: '0477',
    name: '鄂尔多斯',
    pinyin: 'Eerduosi',
    label: 'Eerduosi0477'
  },
  {
    code: '0711',
    name: '鄂州',
    pinyin: 'Ezhou',
    label: 'Ezhou0711'
  },
  {
    code: '0750',
    name: '恩平',
    pinyin: 'Enping',
    label: 'Enping0750'
  },
  {
    code: '0413',
    name: '抚顺',
    pinyin: 'Fushun',
    label: 'Fushun0413'
  },
  {
    code: '0591',
    name: '福州',
    pinyin: 'Fuzhou',
    label: 'Fuzhou0591'
  },
  {
    code: '0770',
    name: '防城港',
    pinyin: 'Fangchenggang',
    label: 'Fangchenggang0770'
  },
  {
    code: '0418',
    name: '阜新',
    pinyin: 'Fuxin',
    label: 'Fuxin0418'
  },
  {
    code: '0558',
    name: '阜阳',
    pinyin: 'Fuyang',
    label: 'Fuyang0558'
  },
  {
    code: '0794',
    name: '抚州',
    pinyin: 'Fuzhou',
    label: 'Fuzhou0794'
  },
  {
    code: '024',
    name: '法库',
    pinyin: 'Faku',
    label: 'Faku024'
  },
  {
    code: '0553',
    name: '繁昌',
    pinyin: 'Fanchang',
    label: 'Fanchang0553'
  },
  {
    code: '0451',
    name: '方正',
    pinyin: 'Fangzheng',
    label: 'Fangzheng0451'
  },
  {
    code: '0538',
    name: '肥城',
    pinyin: 'Feicheng',
    label: 'Feicheng0538'
  },
  {
    code: '0551',
    name: '肥东',
    pinyin: 'Feidong',
    label: 'Feidong0551'
  },
  {
    code: '0551',
    name: '肥西',
    pinyin: 'Feixi',
    label: 'Feixi0551'
  },
  {
    code: '0539',
    name: '费县',
    pinyin: 'Feixian',
    label: 'Feixian0539'
  },
  {
    code: '0795',
    name: '丰城',
    pinyin: 'Fengcheng',
    label: 'Fengcheng0795'
  },
  {
    code: '023',
    name: '丰都',
    pinyin: 'Fengdu',
    label: 'Fengdu023'
  },
  {
    code: '0574',
    name: '奉化',
    pinyin: 'Fenghua',
    label: 'Fenghua0574'
  },
  {
    code: '023',
    name: '奉节',
    pinyin: 'Fengjie',
    label: 'Fengjie023'
  },
  {
    code: '0758',
    name: '封开',
    pinyin: 'Fengkai',
    label: 'Fengkai0758'
  },
  {
    code: '0753',
    name: '丰顺',
    pinyin: 'Fengshun',
    label: 'Fengshun0753'
  },
  {
    code: '0554',
    name: '凤台',
    pinyin: 'Fengtai',
    label: 'Fengtai0554'
  },
  {
    code: '0516',
    name: '丰县',
    pinyin: 'Fengxian',
    label: 'Fengxian0516'
  },
  {
    code: '0795',
    name: '奉新',
    pinyin: 'Fengxin',
    label: 'Fengxin0795'
  },
  {
    code: '0550',
    name: '凤阳',
    pinyin: 'Fengyang',
    label: 'Fengyang0550'
  },
  {
    code: '0790',
    name: '分宜',
    pinyin: 'Fenyi',
    label: 'Fenyi0790'
  },
  {
    code: '0763',
    name: '佛冈',
    pinyin: 'Fogang',
    label: 'Fogang0763'
  },
  {
    code: '0593',
    name: '福安',
    pinyin: 'Fuan',
    label: 'Fuan0593'
  },
  {
    code: '0593',
    name: '福鼎',
    pinyin: 'Fuding',
    label: 'Fuding0593'
  },
  {
    code: '0798',
    name: '浮梁',
    pinyin: 'Fuliang',
    label: 'Fuliang0798'
  },
  {
    code: '0871',
    name: '富民',
    pinyin: 'Fumin',
    label: 'Fumin0871'
  },
  {
    code: '0558',
    name: '阜南',
    pinyin: 'Funan',
    label: 'Funan0558'
  },
  {
    code: '0515',
    name: '阜宁',
    pinyin: 'Funing',
    label: 'Funing0515'
  },
  {
    code: '0591',
    name: '福清',
    pinyin: 'Fuqing',
    label: 'Fuqing0591'
  },
  {
    code: '0571',
    name: '富阳',
    pinyin: 'Fuyang',
    label: 'Fuyang0571'
  },
  {
    code: '0941',
    name: '甘南',
    pinyin: 'Gannan',
    label: 'Gannan0941'
  },
  {
    code: '0851',
    name: '贵阳',
    pinyin: 'Guiyang',
    label: 'Guiyang0851'
  },
  {
    code: '0797',
    name: '赣州',
    pinyin: 'Ganzhou',
    label: 'Ganzhou0797'
  },
  {
    code: '0836',
    name: '甘孜',
    pinyin: 'Ganzi',
    label: 'Ganzi0836'
  },
  {
    code: '0826',
    name: '广安',
    pinyin: 'Guangan',
    label: 'Guangan0826'
  },
  {
    code: '0839',
    name: '广元',
    pinyin: 'Guangyuan',
    label: 'Guangyuan0839'
  },
  {
    code: '0775',
    name: '贵港',
    pinyin: 'Guigang',
    label: 'Guigang0775'
  },
  {
    code: '0773',
    name: '桂林',
    pinyin: 'Guilin',
    label: 'Guilin0773'
  },
  {
    code: '0975',
    name: '果洛',
    pinyin: 'Guoluo',
    label: 'Guoluo0975'
  },
  {
    code: '0954',
    name: '固原',
    pinyin: 'Guyuan',
    label: 'Guyuan0954'
  },
  {
    code: '0797',
    name: '赣县',
    pinyin: 'Ganxian',
    label: 'Ganxian0797'
  },
  {
    code: '0518',
    name: '赣榆',
    pinyin: 'Ganyu',
    label: 'Ganyu0518'
  },
  {
    code: '0795',
    name: '高安',
    pinyin: 'Gaoan',
    label: 'Gaoan0795'
  },
  {
    code: '0311',
    name: '藁城',
    pinyin: 'Gaocheng',
    label: 'Gaocheng0311'
  },
  {
    code: '025',
    name: '高淳',
    pinyin: 'Gaochun',
    label: 'Gaochun025'
  },
  {
    code: '0931',
    name: '皋兰',
    pinyin: 'Gaolan',
    label: 'Gaolan0931'
  },
  {
    code: '029',
    name: '高陵',
    pinyin: 'Gaoling',
    label: 'Gaoling029'
  },
  {
    code: '0536',
    name: '高密',
    pinyin: 'Gaomi',
    label: 'Gaomi0536'
  },
  {
    code: '0533',
    name: '高青',
    pinyin: 'Gaoqing',
    label: 'Gaoqing0533'
  },
  {
    code: '0635',
    name: '高唐',
    pinyin: 'Gaotang',
    label: 'Gaotang0635'
  },
  {
    code: '0758',
    name: '高要',
    pinyin: 'Gaoyao',
    label: 'Gaoyao0758'
  },
  {
    code: '0311',
    name: '高邑',
    pinyin: 'Gaoyi',
    label: 'Gaoyi0311'
  },
  {
    code: '0514',
    name: '高邮',
    pinyin: 'Gaoyou',
    label: 'Gaoyou0514'
  },
  {
    code: '0668',
    name: '高州',
    pinyin: 'Gaozhou',
    label: 'Gaozhou0668'
  },
  {
    code: '0371',
    name: '巩义',
    pinyin: 'Gongyi',
    label: 'Gongyi0371'
  },
  {
    code: '0794',
    name: '广昌',
    pinyin: 'Guangchang',
    label: 'Guangchang0794'
  },
  {
    code: '0563',
    name: '广德',
    pinyin: 'Guangde',
    label: 'Guangde0563'
  },
  {
    code: '0793',
    name: '广丰',
    pinyin: 'Guangfeng',
    label: 'Guangfeng0793'
  },
  {
    code: '0758',
    name: '广宁',
    pinyin: 'Guangning',
    label: 'Guangning0758'
  },
  {
    code: '0546',
    name: '广饶',
    pinyin: 'Guangrao',
    label: 'Guangrao0546'
  },
  {
    code: '0599',
    name: '光泽',
    pinyin: 'Guangze',
    label: 'Guangze0599'
  },
  {
    code: '0518',
    name: '灌南',
    pinyin: 'Guannan',
    label: 'Guannan0518'
  },
  {
    code: '0635',
    name: '冠县',
    pinyin: 'Guanxian',
    label: 'Guanxian0635'
  },
  {
    code: '0518',
    name: '灌云',
    pinyin: 'Guanyun',
    label: 'Guanyun0518'
  },
  {
    code: '0701',
    name: '贵溪',
    pinyin: 'Guixi',
    label: 'Guixi0701'
  },
  {
    code: '0593',
    name: '古田',
    pinyin: 'Gutian',
    label: 'Gutian0593'
  },
  {
    code: '0552',
    name: '固镇',
    pinyin: 'Guzhen',
    label: 'Guzhen0552'
  },
  {
    code: '0970',
    name: '海北',
    pinyin: 'Haibei',
    label: 'Haibei0970'
  },
  {
    code: '0451',
    name: '哈尔滨',
    pinyin: 'Haerbin',
    label: 'Haerbin0451'
  },
  {
    code: '0898',
    name: '海口',
    pinyin: 'Haikou',
    label: 'Haikou0898'
  },
  {
    code: '0310',
    name: '邯郸',
    pinyin: 'Handan',
    label: 'Handan0310'
  },
  {
    code: '0551',
    name: '合肥',
    pinyin: 'Hefei',
    label: 'Hefei0551'
  },
  {
    code: '0752',
    name: '惠州',
    pinyin: 'Huizhou',
    label: 'Huizhou0752'
  },
  {
    code: '0972',
    name: '海东',
    pinyin: 'Haidong',
    label: 'Haidong0972'
  },
  {
    code: '0974',
    name: '海南',
    pinyin: 'Hainan',
    label: 'Hainan0974'
  },
  {
    code: '0977',
    name: '海西',
    pinyin: 'Haixi',
    label: 'Haixi0977'
  },
  {
    code: '0902',
    name: '哈密',
    pinyin: 'Hami',
    label: 'Hami0902'
  },
  {
    code: '0916',
    name: '汉中',
    pinyin: 'Hanzhong',
    label: 'Hanzhong0916'
  },
  {
    code: '0392',
    name: '鹤壁',
    pinyin: 'Hebi',
    label: 'Hebi0392'
  },
  {
    code: '0778',
    name: '河池',
    pinyin: 'Hechi',
    label: 'Hechi0778'
  },
  {
    code: '0468',
    name: '鹤岗',
    pinyin: 'Hegang',
    label: 'Hegang0468'
  },
  {
    code: '0456',
    name: '黑河',
    pinyin: 'Heihe',
    label: 'Heihe0456'
  },
  {
    code: '0318',
    name: '衡水',
    pinyin: 'Hengshui',
    label: 'Hengshui0318'
  },
  {
    code: '0734',
    name: '衡阳',
    pinyin: 'Hengyang',
    label: 'Hengyang0734'
  },
  {
    code: '0903',
    name: '和田地',
    pinyin: 'Hetiandi',
    label: 'Hetiandi0903'
  },
  {
    code: '0762',
    name: '河源',
    pinyin: 'Heyuan',
    label: 'Heyuan0762'
  },
  {
    code: '0530',
    name: '菏泽',
    pinyin: 'Heze',
    label: 'Heze0530'
  },
  {
    code: '0774',
    name: '贺州',
    pinyin: 'Hezhou',
    label: 'Hezhou0774'
  },
  {
    code: '0873',
    name: '红河',
    pinyin: 'Honghe',
    label: 'Honghe0873'
  },
  {
    code: '0517',
    name: '淮安',
    pinyin: 'Huaian',
    label: 'Huaian0517'
  },
  {
    code: '0561',
    name: '淮北',
    pinyin: 'Huaibei',
    label: 'Huaibei0561'
  },
  {
    code: '0745',
    name: '怀化',
    pinyin: 'Huaihua',
    label: 'Huaihua0745'
  },
  {
    code: '0554',
    name: '淮南',
    pinyin: 'Huainan',
    label: 'Huainan0554'
  },
  {
    code: '0713',
    name: '黄冈',
    pinyin: 'Huanggang',
    label: 'Huanggang0713'
  },
  {
    code: '0973',
    name: '黄南',
    pinyin: 'Huangnan',
    label: 'Huangnan0973'
  },
  {
    code: '0559',
    name: '黄山',
    pinyin: 'Huangshan',
    label: 'Huangshan0559'
  },
  {
    code: '0714',
    name: '黄石',
    pinyin: 'Huangshi',
    label: 'Huangshi0714'
  },
  {
    code: '0471',
    name: '呼和浩特',
    pinyin: 'Huhehaote',
    label: 'Huhehaote0471'
  },
  {
    code: '0429',
    name: '葫芦岛',
    pinyin: 'Huludao',
    label: 'Huludao0429'
  },
  {
    code: '0470',
    name: '呼伦贝尔',
    pinyin: 'Hulunbeier',
    label: 'Hulunbeier0470'
  },
  {
    code: '0572',
    name: '湖州',
    pinyin: 'Huzhou',
    label: 'Huzhou0572'
  },
  {
    code: '0513',
    name: '海安',
    pinyin: 'Haian',
    label: 'Haian0513'
  },
  {
    code: '0660',
    name: '海丰',
    pinyin: 'Haifeng',
    label: 'Haifeng0660'
  },
  {
    code: '0513',
    name: '海门',
    pinyin: 'Haimen',
    label: 'Haimen0513'
  },
  {
    code: '0573',
    name: '海宁',
    pinyin: 'Haining',
    label: 'Haining0573'
  },
  {
    code: '0573',
    name: '海盐',
    pinyin: 'Haiyan',
    label: 'Haiyan0573'
  },
  {
    code: '0535',
    name: '海阳',
    pinyin: 'Haiyang',
    label: 'Haiyang0535'
  },
  {
    code: '0565',
    name: '含山',
    pinyin: 'Hanshan',
    label: 'Hanshan0565'
  },
  {
    code: '023',
    name: '合川',
    pinyin: 'Hechuan',
    label: 'Hechuan023'
  },
  {
    code: '0793',
    name: '横峰',
    pinyin: 'Hengfeng',
    label: 'Hengfeng0793'
  },
  {
    code: '0771',
    name: '横县',
    pinyin: 'Hengxian',
    label: 'Hengxian0771'
  },
  {
    code: '0762',
    name: '和平',
    pinyin: 'Heping',
    label: 'Heping0762'
  },
  {
    code: '0750',
    name: '鹤山',
    pinyin: 'Heshan',
    label: 'Heshan0750'
  },
  {
    code: '0565',
    name: '和县',
    pinyin: 'Hexian',
    label: 'Hexian0565'
  },
  {
    code: '0517',
    name: '洪泽',
    pinyin: 'Hongze',
    label: 'Hongze0517'
  },
  {
    code: '0596',
    name: '华安',
    pinyin: 'Huaan',
    label: 'Huaan0596'
  },
  {
    code: '0423',
    name: '桦甸',
    pinyin: 'Huadian',
    label: 'Huadian0423'
  },
  {
    code: '0758',
    name: '怀集',
    pinyin: 'Huaiji',
    label: 'Huaiji0758'
  },
  {
    code: '0556',
    name: '怀宁',
    pinyin: 'Huaining',
    label: 'Huaining0556'
  },
  {
    code: '0552',
    name: '怀远',
    pinyin: 'Huaiyuan',
    label: 'Huaiyuan0552'
  },
  {
    code: '0533',
    name: '桓台',
    pinyin: 'Huantai',
    label: 'Huantai0533'
  },
  {
    code: '0668',
    name: '化州',
    pinyin: 'Huazhou',
    label: 'Huazhou0668'
  },
  {
    code: '0595',
    name: '惠安',
    pinyin: 'Huian',
    label: 'Huian0595'
  },
  {
    code: '0797',
    name: '会昌',
    pinyin: 'Huichang',
    label: 'Huichang0797'
  },
  {
    code: '0752',
    name: '惠东',
    pinyin: 'Huidong',
    label: 'Huidong0752'
  },
  {
    code: '0663',
    name: '惠来',
    pinyin: 'Huilai',
    label: 'Huilai0663'
  },
  {
    code: '0543',
    name: '惠民',
    pinyin: 'Huimin',
    label: 'Huimin0543'
  },
  {
    code: '0792',
    name: '湖口',
    pinyin: 'Hukou',
    label: 'Hukou0792'
  },
  {
    code: '0451',
    name: '呼兰',
    pinyin: 'Hulan',
    label: 'Hulan0451'
  },
  {
    code: '0564',
    name: '霍邱',
    pinyin: 'Huoqiu',
    label: 'Huoqiu0564'
  },
  {
    code: '0564',
    name: '霍山',
    pinyin: 'Huoshan',
    label: 'Huoshan0564'
  },
  {
    code: '029',
    name: '户县',
    pinyin: 'Huxian',
    label: 'Huxian029'
  },
  {
    code: '0454',
    name: '佳木斯',
    pinyin: 'Jiamusi',
    label: 'Jiamusi0454'
  },
  {
    code: '0391',
    name: '焦作',
    pinyin: 'Jiaozuo',
    label: 'Jiaozuo0391'
  },
  {
    code: '0573',
    name: '嘉兴',
    pinyin: 'Jiaxing',
    label: 'Jiaxing0573'
  },
  {
    code: '0423',
    name: '吉林',
    pinyin: 'Jilin',
    label: 'Jilin0423'
  },
  {
    code: '0531',
    name: '济南',
    pinyin: 'Jinan',
    label: 'Jinan0531'
  },
  {
    code: '0750',
    name: '江门',
    pinyin: 'Jiangmen',
    label: 'Jiangmen0750'
  },
  {
    code: '0796',
    name: '吉安',
    pinyin: 'Jian',
    label: 'Jian0796'
  },
  {
    code: '0937',
    name: '嘉峪关',
    pinyin: 'Jiayuguan',
    label: 'Jiayuguan0937'
  },
  {
    code: '0663',
    name: '揭阳',
    pinyin: 'Jieyang',
    label: 'Jieyang0663'
  },
  {
    code: '0935',
    name: '金昌',
    pinyin: 'Jinchang',
    label: 'Jinchang0935'
  },
  {
    code: '0356',
    name: '晋城',
    pinyin: 'Jincheng',
    label: 'Jincheng0356'
  },
  {
    code: '0798',
    name: '景德镇',
    pinyin: 'Jingdezhen',
    label: 'Jingdezhen0798'
  },
  {
    code: '0724',
    name: '荆门',
    pinyin: 'Jingmen',
    label: 'Jingmen0724'
  },
  {
    code: '0716',
    name: '荆州',
    pinyin: 'Jingzhou',
    label: 'Jingzhou0716'
  },
  {
    code: '0579',
    name: '金华',
    pinyin: 'Jinhua',
    label: 'Jinhua0579'
  },
  {
    code: '0537',
    name: '济宁',
    pinyin: 'Jining',
    label: 'Jining0537'
  },
  {
    code: '0354',
    name: '晋中',
    pinyin: 'Jinzhong',
    label: 'Jinzhong0354'
  },
  {
    code: '0416',
    name: '锦州',
    pinyin: 'Jinzhou',
    label: 'Jinzhou0416'
  },
  {
    code: '0792',
    name: '九江',
    pinyin: 'Jiujiang',
    label: 'Jiujiang0792'
  },
  {
    code: '0937',
    name: '酒泉',
    pinyin: 'Jiuquan',
    label: 'Jiuquan0937'
  },
  {
    code: '0467',
    name: '鸡西',
    pinyin: 'Jixi',
    label: 'Jixi0467'
  },
  {
    code: '0571',
    name: '建德',
    pinyin: 'Jiande',
    label: 'Jiande0571'
  },
  {
    code: '0514',
    name: '江都',
    pinyin: 'Jiangdu',
    label: 'Jiangdu0514'
  },
  {
    code: '023',
    name: '江津',
    pinyin: 'Jiangjin',
    label: 'Jiangjin023'
  },
  {
    code: '0598',
    name: '将乐',
    pinyin: 'Jiangle',
    label: 'Jiangle0598'
  },
  {
    code: '0570',
    name: '江山',
    pinyin: 'Jiangshan',
    label: 'Jiangshan0570'
  },
  {
    code: '0523',
    name: '姜堰',
    pinyin: 'Jiangyan',
    label: 'Jiangyan0523'
  },
  {
    code: '0510',
    name: '江阴',
    pinyin: 'Jiangyin',
    label: 'Jiangyin0510'
  },
  {
    code: '0515',
    name: '建湖',
    pinyin: 'Jianhu',
    label: 'Jianhu0515'
  },
  {
    code: '0598',
    name: '建宁',
    pinyin: 'Jianning',
    label: 'Jianning0598'
  },
  {
    code: '0599',
    name: '建瓯',
    pinyin: 'Jianou',
    label: 'Jianou0599'
  },
  {
    code: '0599',
    name: '建阳',
    pinyin: 'Jianyang',
    label: 'Jianyang0599'
  },
  {
    code: '0796',
    name: '吉安',
    pinyin: 'Jian',
    label: 'Jian0796'
  },
  {
    code: '0423',
    name: '蛟河',
    pinyin: 'Jiaohe',
    label: 'Jiaohe0423'
  },
  {
    code: '0753',
    name: '蕉岭',
    pinyin: 'Jiaoling',
    label: 'Jiaoling0753'
  },
  {
    code: '0532',
    name: '胶南',
    pinyin: 'Jiaonan',
    label: 'Jiaonan0532'
  },
  {
    code: '0532',
    name: '胶州',
    pinyin: 'Jiaozhou',
    label: 'Jiaozhou0532'
  },
  {
    code: '0573',
    name: '嘉善',
    pinyin: 'Jiashan',
    label: 'Jiashan0573'
  },
  {
    code: '0537',
    name: '嘉祥',
    pinyin: 'Jiaxiang',
    label: 'Jiaxiang0537'
  },
  {
    code: '0663',
    name: '揭东',
    pinyin: 'Jiedong',
    label: 'Jiedong0663'
  },
  {
    code: '0558',
    name: '界首',
    pinyin: 'Jieshou',
    label: 'Jieshou0558'
  },
  {
    code: '0663',
    name: '揭西',
    pinyin: 'Jiexi',
    label: 'Jiexi0663'
  },
  {
    code: '0532',
    name: '即墨',
    pinyin: 'Jimo',
    label: 'Jimo0532'
  },
  {
    code: '0795',
    name: '靖安',
    pinyin: 'Jingan',
    label: 'Jingan0795'
  },
  {
    code: '0563',
    name: '旌德',
    pinyin: 'Jingde',
    label: 'Jingde0563'
  },
  {
    code: '0796',
    name: '井冈山',
    pinyin: 'Jinggangshan',
    label: 'Jinggangshan0796'
  },
  {
    code: '0523',
    name: '靖江',
    pinyin: 'Jingjiang',
    label: 'Jingjiang0523'
  },
  {
    code: '0578',
    name: '景宁',
    pinyin: 'Jingning',
    label: 'Jingning0578'
  },
  {
    code: '0563',
    name: '泾县',
    pinyin: 'Jingxian',
    label: 'Jingxian0563'
  },
  {
    code: '0311',
    name: '井陉',
    pinyin: 'Jingxing',
    label: 'Jingxing0311'
  },
  {
    code: '0517',
    name: '金湖',
    pinyin: 'Jinhu',
    label: 'Jinhu0517'
  },
  {
    code: '0595',
    name: '晋江',
    pinyin: 'Jinjiang',
    label: 'Jinjiang0595'
  },
  {
    code: '0595',
    name: '金门',
    pinyin: 'Jinmen',
    label: 'Jinmen0595'
  },
  {
    code: '0871',
    name: '晋宁',
    pinyin: 'Jinning',
    label: 'Jinning0871'
  },
  {
    code: '0519',
    name: '金坛',
    pinyin: 'Jintan',
    label: 'Jintan0519'
  },
  {
    code: '028',
    name: '金堂',
    pinyin: 'Jintang',
    label: 'Jintang028'
  },
  {
    code: '0791',
    name: '进贤',
    pinyin: 'Jinxian',
    label: 'Jinxian0791'
  },
  {
    code: '0794',
    name: '金溪',
    pinyin: 'Jinxi',
    label: 'Jinxi0794'
  },
  {
    code: '0537',
    name: '金乡',
    pinyin: 'Jinxiang',
    label: 'Jinxiang0537'
  },
  {
    code: '0578',
    name: '缙云',
    pinyin: 'Jinyun',
    label: 'Jinyun0578'
  },
  {
    code: '0564',
    name: '金寨',
    pinyin: 'Jinzhai',
    label: 'Jinzhai0564'
  },
  {
    code: '0311',
    name: '晋州',
    pinyin: 'Jinzhou',
    label: 'Jinzhou0311'
  },
  {
    code: '0796',
    name: '吉水',
    pinyin: 'Jishui',
    label: 'Jishui0796'
  },
  {
    code: '0792',
    name: '九江',
    pinyin: 'Jiujiang',
    label: 'Jiujiang0792'
  },
  {
    code: '0431',
    name: '九台',
    pinyin: 'Jiutai',
    label: 'Jiutai0431'
  },
  {
    code: '0563',
    name: '绩溪',
    pinyin: 'Jixi',
    label: 'Jixi0563'
  },
  {
    code: '0531',
    name: '济阳',
    pinyin: 'Jiyang',
    label: 'Jiyang0531'
  },
  {
    code: '0391',
    name: '济源',
    pinyin: 'Jiyuan',
    label: 'Jiyuan0391'
  },
  {
    code: '0530',
    name: '鄄城',
    pinyin: 'Juancheng',
    label: 'Juancheng0530'
  },
  {
    code: '0539',
    name: '莒南',
    pinyin: 'Junan',
    label: 'Junan0539'
  },
  {
    code: '0511',
    name: '句容',
    pinyin: 'Jurong',
    label: 'Jurong0511'
  },
  {
    code: '0633',
    name: '莒县',
    pinyin: 'Juxian',
    label: 'Juxian0633'
  },
  {
    code: '0530',
    name: '巨野',
    pinyin: 'Juye',
    label: 'Juye0530'
  },
  {
    code: '0378',
    name: '开封',
    pinyin: 'Kaifeng',
    label: 'Kaifeng0378'
  },
  {
    code: '0871',
    name: '昆明',
    pinyin: 'Kunming',
    label: 'Kunming0871'
  },
  {
    code: '0998',
    name: '喀什地',
    pinyin: 'Kashidi',
    label: 'Kashidi0998'
  },
  {
    code: '0990',
    name: '克拉玛依',
    pinyin: 'Kelamayi',
    label: 'Kelamayi0990'
  },
  {
    code: '0908',
    name: '克孜勒',
    pinyin: 'Kezile',
    label: 'Kezile0908'
  },
  {
    code: '0570',
    name: '开化',
    pinyin: 'Kaihua',
    label: 'Kaihua0570'
  },
  {
    code: '0750',
    name: '开平',
    pinyin: 'Kaiping',
    label: 'Kaiping0750'
  },
  {
    code: '023',
    name: '开县',
    pinyin: 'Kaixian',
    label: 'Kaixian023'
  },
  {
    code: '0851',
    name: '开阳',
    pinyin: 'Kaiyang',
    label: 'Kaiyang0851'
  },
  {
    code: '024',
    name: '康平',
    pinyin: 'Kangping',
    label: 'Kangping024'
  },
  {
    code: '0546',
    name: '垦利',
    pinyin: 'Kenli',
    label: 'Kenli0546'
  },
  {
    code: '0512',
    name: '昆山',
    pinyin: 'Kunshan',
    label: 'Kunshan0512'
  },
  {
    code: '0772',
    name: '来宾',
    pinyin: 'Laibin',
    label: 'Laibin0772'
  },
  {
    code: '0931',
    name: '兰州',
    pinyin: 'Lanzhou',
    label: 'Lanzhou0931'
  },
  {
    code: '0772',
    name: '柳州',
    pinyin: 'Liuzhou',
    label: 'Liuzhou0772'
  },
  {
    code: '0379',
    name: '洛阳',
    pinyin: 'Luoyang',
    label: 'Luoyang0379'
  },
  {
    code: '0634',
    name: '莱芜',
    pinyin: 'Laiwu',
    label: 'Laiwu0634'
  },
  {
    code: '0316',
    name: '廊坊',
    pinyin: 'Langfang',
    label: 'Langfang0316'
  },
  {
    code: '0891',
    name: '拉萨',
    pinyin: 'Lasa',
    label: 'Lasa0891'
  },
  {
    code: '0833',
    name: '乐山',
    pinyin: 'Leshan',
    label: 'Leshan0833'
  },
  {
    code: '0834',
    name: '凉山',
    pinyin: 'Liangshan',
    label: 'Liangshan0834'
  },
  {
    code: '0518',
    name: '连云港',
    pinyin: 'Lianyungang',
    label: 'Lianyungang0518'
  },
  {
    code: '0635',
    name: '聊城',
    pinyin: 'Liaocheng',
    label: 'Liaocheng0635'
  },
  {
    code: '0419',
    name: '辽阳',
    pinyin: 'Liaoyang',
    label: 'Liaoyang0419'
  },
  {
    code: '0437',
    name: '辽源',
    pinyin: 'Liaoyuan',
    label: 'Liaoyuan0437'
  },
  {
    code: '0888',
    name: '丽江',
    pinyin: 'Lijiang',
    label: 'Lijiang0888'
  },
  {
    code: '0883',
    name: '临沧',
    pinyin: 'Lincang',
    label: 'Lincang0883'
  },
  {
    code: '0357',
    name: '临汾',
    pinyin: 'Linfen',
    label: 'Linfen0357'
  },
  {
    code: '0930',
    name: '临夏',
    pinyin: 'Linxia',
    label: 'Linxia0930'
  },
  {
    code: '0539',
    name: '临沂',
    pinyin: 'Linyi',
    label: 'Linyi0539'
  },
  {
    code: '0894',
    name: '林芝',
    pinyin: 'Linzhi',
    label: 'Linzhi0894'
  },
  {
    code: '0578',
    name: '丽水',
    pinyin: 'Lishui',
    label: 'Lishui0578'
  },
  {
    code: '0564',
    name: '六安',
    pinyin: 'Liuan',
    label: 'Liuan0564'
  },
  {
    code: '0858',
    name: '六盘水',
    pinyin: 'Liupanshui',
    label: 'Liupanshui0858'
  },
  {
    code: '0939',
    name: '陇南',
    pinyin: 'Longnan',
    label: 'Longnan0939'
  },
  {
    code: '0597',
    name: '龙岩',
    pinyin: 'Longyan',
    label: 'Longyan0597'
  },
  {
    code: '0738',
    name: '娄底',
    pinyin: 'Loudi',
    label: 'Loudi0738'
  },
  {
    code: '0395',
    name: '漯河',
    pinyin: 'Luohe',
    label: 'Luohe0395'
  },
  {
    code: '0830',
    name: '泸州',
    pinyin: 'Luzhou',
    label: 'Luzhou0830'
  },
  {
    code: '0358',
    name: '吕梁',
    pinyin: 'Lvliang',
    label: 'Lvliang0358'
  },
  {
    code: '0550',
    name: '来安',
    pinyin: 'Laian',
    label: 'Laian0550'
  },
  {
    code: '0532',
    name: '莱西',
    pinyin: 'Laixi',
    label: 'Laixi0532'
  },
  {
    code: '0535',
    name: '莱阳',
    pinyin: 'Laiyang',
    label: 'Laiyang0535'
  },
  {
    code: '0535',
    name: '莱州',
    pinyin: 'Laizhou',
    label: 'Laizhou0535'
  },
  {
    code: '0563',
    name: '郎溪',
    pinyin: 'Langxi',
    label: 'Langxi0563'
  },
  {
    code: '029',
    name: '蓝田',
    pinyin: 'Lantian',
    label: 'Lantian029'
  },
  {
    code: '0579',
    name: '兰溪',
    pinyin: 'Lanxi',
    label: 'Lanxi0579'
  },
  {
    code: '0794',
    name: '乐安',
    pinyin: 'Lean',
    label: 'Lean0794'
  },
  {
    code: '0751',
    name: '乐昌',
    pinyin: 'Lechang',
    label: 'Lechang0751'
  },
  {
    code: '0759',
    name: '雷州',
    pinyin: 'Leizhou',
    label: 'Leizhou0759'
  },
  {
    code: '0534',
    name: '乐陵',
    pinyin: 'Leling',
    label: 'Leling0534'
  },
  {
    code: '0798',
    name: '乐平',
    pinyin: 'Leping',
    label: 'Leping0798'
  },
  {
    code: '0577',
    name: '乐清',
    pinyin: 'Leqing',
    label: 'Leqing0577'
  },
  {
    code: '0315',
    name: '乐亭',
    pinyin: 'Leting',
    label: 'Leting0315'
  },
  {
    code: '0597',
    name: '连城',
    pinyin: 'Liancheng',
    label: 'Liancheng0597'
  },
  {
    code: '023',
    name: '梁平',
    pinyin: 'Liangping',
    label: 'Liangping023'
  },
  {
    code: '0537',
    name: '梁山',
    pinyin: 'Liangshan',
    label: 'Liangshan0537'
  },
  {
    code: '0799',
    name: '莲花',
    pinyin: 'Lianhua',
    label: 'Lianhua0799'
  },
  {
    code: '0591',
    name: '连江',
    pinyin: 'Lianjiang',
    label: 'Lianjiang0591'
  },
  {
    code: '0759',
    name: '廉江',
    pinyin: 'Lianjiang',
    label: 'Lianjiang0759'
  },
  {
    code: '0763',
    name: '连南',
    pinyin: 'Liannan',
    label: 'Liannan0763'
  },
  {
    code: '0762',
    name: '连平',
    pinyin: 'Lianping',
    label: 'Lianping0762'
  },
  {
    code: '0763',
    name: '连山',
    pinyin: 'Lianshan',
    label: 'Lianshan0763'
  },
  {
    code: '0517',
    name: '涟水',
    pinyin: 'Lianshui',
    label: 'Lianshui0517'
  },
  {
    code: '0763',
    name: '连州',
    pinyin: 'Lianzhou',
    label: 'Lianzhou0763'
  },
  {
    code: '024',
    name: '辽中',
    pinyin: 'Liaozhong',
    label: 'Liaozhong024'
  },
  {
    code: '0794',
    name: '黎川',
    pinyin: 'Lichuan',
    label: 'Lichuan0794'
  },
  {
    code: '0546',
    name: '利津',
    pinyin: 'Lijin',
    label: 'Lijin0546'
  },
  {
    code: '0571',
    name: '临安',
    pinyin: 'Linan',
    label: 'Linan0571'
  },
  {
    code: '0557',
    name: '灵璧',
    pinyin: 'Lingbi',
    label: 'Lingbi0557'
  },
  {
    code: '0311',
    name: '灵寿',
    pinyin: 'Lingshou',
    label: 'Lingshou0311'
  },
  {
    code: '0534',
    name: '陵县',
    pinyin: 'Lingxian',
    label: 'Lingxian0534'
  },
  {
    code: '0576',
    name: '临海',
    pinyin: 'Linhai',
    label: 'Linhai0576'
  },
  {
    code: '0635',
    name: '临清',
    pinyin: 'Linqing',
    label: 'Linqing0635'
  },
  {
    code: '0558',
    name: '临泉',
    pinyin: 'Linquan',
    label: 'Linquan0558'
  },
  {
    code: '0536',
    name: '临朐',
    pinyin: 'Linqu',
    label: 'Linqu0536'
  },
  {
    code: '0539',
    name: '临沭',
    pinyin: 'Linshu',
    label: 'Linshu0539'
  },
  {
    code: '0534',
    name: '临邑',
    pinyin: 'Linyi',
    label: 'Linyi0534'
  },
  {
    code: '025',
    name: '溧水',
    pinyin: 'Lishui',
    label: 'Lishui025'
  },
  {
    code: '0772',
    name: '柳城',
    pinyin: 'Liucheng',
    label: 'Liucheng0772'
  },
  {
    code: '0772',
    name: '柳江',
    pinyin: 'Liujiang',
    label: 'Liujiang0772'
  },
  {
    code: '0731',
    name: '浏阳',
    pinyin: 'Liuyang',
    label: 'Liuyang0731'
  },
  {
    code: '0558',
    name: '利辛',
    pinyin: 'Lixin',
    label: 'Lixin0558'
  },
  {
    code: '0519',
    name: '溧阳',
    pinyin: 'Liyang',
    label: 'Liyang0519'
  },
  {
    code: '0771',
    name: '隆安',
    pinyin: 'Longan',
    label: 'Longan0771'
  },
  {
    code: '0762',
    name: '龙川',
    pinyin: 'Longchuan',
    label: 'Longchuan0762'
  },
  {
    code: '0596',
    name: '龙海',
    pinyin: 'Longhai',
    label: 'Longhai0596'
  },
  {
    code: '0535',
    name: '龙口',
    pinyin: 'Longkou',
    label: 'Longkou0535'
  },
  {
    code: '0752',
    name: '龙门',
    pinyin: 'Longmen',
    label: 'Longmen0752'
  },
  {
    code: '0797',
    name: '龙南',
    pinyin: 'Longnan',
    label: 'Longnan0797'
  },
  {
    code: '0578',
    name: '龙泉',
    pinyin: 'Longquan',
    label: 'Longquan0578'
  },
  {
    code: '0570',
    name: '龙游',
    pinyin: 'Longyou',
    label: 'Longyou0570'
  },
  {
    code: '0311',
    name: '栾城',
    pinyin: 'Luancheng',
    label: 'Luancheng0311'
  },
  {
    code: '0379',
    name: '栾川',
    pinyin: 'Luanchuan',
    label: 'Luanchuan0379'
  },
  {
    code: '0315',
    name: '滦南',
    pinyin: 'Luannan',
    label: 'Luannan0315'
  },
  {
    code: '0315',
    name: '滦县',
    pinyin: 'Luanxian',
    label: 'Luanxian0315'
  },
  {
    code: '0660',
    name: '陆丰',
    pinyin: 'Lufeng',
    label: 'Lufeng0660'
  },
  {
    code: '0660',
    name: '陆河',
    pinyin: 'Luhe',
    label: 'Luhe0660'
  },
  {
    code: '0565',
    name: '庐江',
    pinyin: 'Lujiang',
    label: 'Lujiang0565'
  },
  {
    code: '0766',
    name: '罗定',
    pinyin: 'Luoding',
    label: 'Luoding0766'
  },
  {
    code: '0379',
    name: '洛宁',
    pinyin: 'Luoning',
    label: 'Luoning0379'
  },
  {
    code: '0591',
    name: '罗源',
    pinyin: 'Luoyuan',
    label: 'Luoyuan0591'
  },
  {
    code: '0311',
    name: '鹿泉',
    pinyin: 'Luquan',
    label: 'Luquan0311'
  },
  {
    code: '0871',
    name: '禄劝',
    pinyin: 'Luquan',
    label: 'Luquan0871'
  },
  {
    code: '0799',
    name: '芦溪',
    pinyin: 'Luxi',
    label: 'Luxi0799'
  },
  {
    code: '0772',
    name: '鹿寨',
    pinyin: 'Luzhai',
    label: 'Luzhai0772'
  },
  {
    code: '0555',
    name: '马鞍山',
    pinyin: 'Maanshan',
    label: 'Maanshan0555'
  },
  {
    code: '0668',
    name: '茂名',
    pinyin: 'Maoming',
    label: 'Maoming0668'
  },
  {
    code: '028',
    name: '眉山',
    pinyin: 'Meishan',
    label: 'Meishan028'
  },
  {
    code: '0753',
    name: '梅州',
    pinyin: 'Meizhou',
    label: 'Meizhou0753'
  },
  {
    code: '0816',
    name: '绵阳',
    pinyin: 'Mianyang',
    label: 'Mianyang0816'
  },
  {
    code: '0453',
    name: '牡丹江',
    pinyin: 'Mudanjiang',
    label: 'Mudanjiang0453'
  },
  {
    code: '0771',
    name: '马山',
    pinyin: 'Mashan',
    label: 'Mashan0771'
  },
  {
    code: '0753',
    name: '梅县',
    pinyin: 'Meixian',
    label: 'Meixian0753'
  },
  {
    code: '0558',
    name: '蒙城',
    pinyin: 'Mengcheng',
    label: 'Mengcheng0558'
  },
  {
    code: '0379',
    name: '孟津',
    pinyin: 'Mengjin',
    label: 'Mengjin0379'
  },
  {
    code: '0539',
    name: '蒙阴',
    pinyin: 'Mengyin',
    label: 'Mengyin0539'
  },
  {
    code: '0391',
    name: '孟州',
    pinyin: 'Mengzhou',
    label: 'Mengzhou0391'
  },
  {
    code: '0550',
    name: '明光',
    pinyin: 'Mingguang',
    label: 'Mingguang0550'
  },
  {
    code: '0598',
    name: '明溪',
    pinyin: 'Mingxi',
    label: 'Mingxi0598'
  },
  {
    code: '0591',
    name: '闽侯',
    pinyin: 'Minhou',
    label: 'Minhou0591'
  },
  {
    code: '0591',
    name: '闽清',
    pinyin: 'Minqing',
    label: 'Minqing0591'
  },
  {
    code: '0451',
    name: '木兰',
    pinyin: 'Mulan',
    label: 'Mulan0451'
  },
  {
    code: '0817',
    name: '南充',
    pinyin: 'Nanchong',
    label: 'Nanchong0817'
  },
  {
    code: '0791',
    name: '南昌',
    pinyin: 'Nanchang',
    label: 'Nanchang0791'
  },
  {
    code: '0771',
    name: '南宁',
    pinyin: 'Nanning',
    label: 'Nanning0771'
  },
  {
    code: '0513',
    name: '南通',
    pinyin: 'Nantong',
    label: 'Nantong0513'
  },
  {
    code: '0599',
    name: '南平',
    pinyin: 'Nanping',
    label: 'Nanping0599'
  },
  {
    code: '0377',
    name: '南阳',
    pinyin: 'Nanyang',
    label: 'Nanyang0377'
  },
  {
    code: '0896',
    name: '那曲',
    pinyin: 'Naqu',
    label: 'Naqu0896'
  },
  {
    code: '0832',
    name: '内江',
    pinyin: 'Neijiang',
    label: 'Neijiang0832'
  },
  {
    code: '0593',
    name: '宁德',
    pinyin: 'Ningde',
    label: 'Ningde0593'
  },
  {
    code: '0886',
    name: '怒江',
    pinyin: 'Nujiang',
    label: 'Nujiang0886'
  },
  {
    code: '0595',
    name: '南安',
    pinyin: 'Nanan',
    label: 'Nanan0595'
  },
  {
    code: '0754',
    name: '南澳',
    pinyin: 'Nanao',
    label: 'Nanao0754'
  },
  {
    code: '0794',
    name: '南城',
    pinyin: 'Nancheng',
    label: 'Nancheng0794'
  },
  {
    code: '023',
    name: '南川',
    pinyin: 'Nanchuan',
    label: 'Nanchuan023'
  },
  {
    code: '0794',
    name: '南丰',
    pinyin: 'Nanfeng',
    label: 'Nanfeng0794'
  },
  {
    code: '0596',
    name: '南靖',
    pinyin: 'Nanjing',
    label: 'Nanjing0596'
  },
  {
    code: '0797',
    name: '南康',
    pinyin: 'Nankang',
    label: 'Nankang0797'
  },
  {
    code: '0553',
    name: '南陵',
    pinyin: 'Nanling',
    label: 'Nanling0553'
  },
  {
    code: '0751',
    name: '南雄',
    pinyin: 'Nanxiong',
    label: 'Nanxiong0751'
  },
  {
    code: '0797',
    name: '宁都',
    pinyin: 'Ningdu',
    label: 'Ningdu0797'
  },
  {
    code: '0563',
    name: '宁国',
    pinyin: 'Ningguo',
    label: 'Ningguo0563'
  },
  {
    code: '0574',
    name: '宁海',
    pinyin: 'Ninghai',
    label: 'Ninghai0574'
  },
  {
    code: '0598',
    name: '宁化',
    pinyin: 'Ninghua',
    label: 'Ninghua0598'
  },
  {
    code: '0534',
    name: '宁津',
    pinyin: 'Ningjin',
    label: 'Ningjin0534'
  },
  {
    code: '0731',
    name: '宁乡',
    pinyin: 'Ningxiang',
    label: 'Ningxiang0731'
  },
  {
    code: '0538',
    name: '宁阳',
    pinyin: 'Ningyang',
    label: 'Ningyang0538'
  },
  {
    code: '0431',
    name: '农安',
    pinyin: 'Nongan',
    label: 'Nongan0431'
  },
  {
    code: '0427',
    name: '盘锦',
    pinyin: 'Panjin',
    label: 'Panjin0427'
  },
  {
    code: '0812',
    name: '攀枝花',
    pinyin: 'Panzhihua',
    label: 'Panzhihua0812'
  },
  {
    code: '0375',
    name: '平顶山',
    pinyin: 'Pingdingshan',
    label: 'Pingdingshan0375'
  },
  {
    code: '0933',
    name: '平凉',
    pinyin: 'Pingliang',
    label: 'Pingliang0933'
  },
  {
    code: '0799',
    name: '萍乡',
    pinyin: 'Pingxiang',
    label: 'Pingxiang0799'
  },
  {
    code: '0879',
    name: '普洱',
    pinyin: 'Puer',
    label: 'Puer0879'
  },
  {
    code: '0594',
    name: '莆田',
    pinyin: 'Putian',
    label: 'Putian0594'
  },
  {
    code: '0393',
    name: '濮阳',
    pinyin: 'Puyang',
    label: 'Puyang0393'
  },
  {
    code: '0579',
    name: '磐安',
    pinyin: 'Panan',
    label: 'Panan0579'
  },
  {
    code: '0423',
    name: '磐石',
    pinyin: 'Panshi',
    label: 'Panshi0423'
  },
  {
    code: '0516',
    name: '沛县',
    pinyin: 'Peixian',
    label: 'Peixian0516'
  },
  {
    code: '0535',
    name: '蓬莱',
    pinyin: 'Penglai',
    label: 'Penglai0535'
  },
  {
    code: '023',
    name: '彭水',
    pinyin: 'Pengshui',
    label: 'Pengshui023'
  },
  {
    code: '0792',
    name: '彭泽',
    pinyin: 'Pengze',
    label: 'Pengze0792'
  },
  {
    code: '028',
    name: '彭州',
    pinyin: 'Pengzhou',
    label: 'Pengzhou028'
  },
  {
    code: '0532',
    name: '平度',
    pinyin: 'Pingdu',
    label: 'Pingdu0532'
  },
  {
    code: '0596',
    name: '平和',
    pinyin: 'Pinghe',
    label: 'Pinghe0596'
  },
  {
    code: '0573',
    name: '平湖',
    pinyin: 'Pinghu',
    label: 'Pinghu0573'
  },
  {
    code: '0593',
    name: '屏南',
    pinyin: 'Pingnan',
    label: 'Pingnan0593'
  },
  {
    code: '0311',
    name: '平山',
    pinyin: 'Pingshan',
    label: 'Pingshan0311'
  },
  {
    code: '0591',
    name: '平潭',
    pinyin: 'Pingtan',
    label: 'Pingtan0591'
  },
  {
    code: '0577',
    name: '平阳',
    pinyin: 'Pingyang',
    label: 'Pingyang0577'
  },
  {
    code: '0531',
    name: '平阴',
    pinyin: 'Pingyin',
    label: 'Pingyin0531'
  },
  {
    code: '0539',
    name: '平邑',
    pinyin: 'Pingyi',
    label: 'Pingyi0539'
  },
  {
    code: '0534',
    name: '平原',
    pinyin: 'Pingyuan',
    label: 'Pingyuan0534'
  },
  {
    code: '0753',
    name: '平远',
    pinyin: 'Pingyuan',
    label: 'Pingyuan0753'
  },
  {
    code: '028',
    name: '郫县',
    pinyin: 'Pixian',
    label: 'Pixian028'
  },
  {
    code: '0516',
    name: '邳州',
    pinyin: 'Pizhou',
    label: 'Pizhou0516'
  },
  {
    code: '0793',
    name: '鄱阳',
    pinyin: 'Poyang',
    label: 'Poyang0793'
  },
  {
    code: '0599',
    name: '浦城',
    pinyin: 'Pucheng',
    label: 'Pucheng0599'
  },
  {
    code: '0579',
    name: '浦江',
    pinyin: 'Pujiang',
    label: 'Pujiang0579'
  },
  {
    code: '028',
    name: '蒲江',
    pinyin: 'Pujiang',
    label: 'Pujiang028'
  },
  {
    code: '0411',
    name: '普兰店',
    pinyin: 'Pulandian',
    label: 'Pulandian0411'
  },
  {
    code: '0663',
    name: '普宁',
    pinyin: 'Puning',
    label: 'Puning0663'
  },
  {
    code: '0855',
    name: '黔东',
    pinyin: 'Qiandong',
    label: 'Qiandong0855'
  },
  {
    code: '0532',
    name: '青岛',
    pinyin: 'Qingdao',
    label: 'Qingdao0532'
  },
  {
    code: '0595',
    name: '泉州',
    pinyin: 'Quanzhou',
    label: 'Quanzhou0595'
  },
  {
    code: '0854',
    name: '黔南',
    pinyin: 'Qiannan',
    label: 'Qiannan0854'
  },
  {
    code: '0859',
    name: '黔西南',
    pinyin: 'Qianxinan',
    label: 'Qianxinan0859'
  },
  {
    code: '0934',
    name: '庆阳',
    pinyin: 'Qingyang',
    label: 'Qingyang0934'
  },
  {
    code: '0763',
    name: '清远',
    pinyin: 'Qingyuan',
    label: 'Qingyuan0763'
  },
  {
    code: '0335',
    name: '秦皇岛',
    pinyin: 'Qinhuangdao',
    label: 'Qinhuangdao0335'
  },
  {
    code: '0777',
    name: '钦州',
    pinyin: 'Qinzhou',
    label: 'Qinzhou0777'
  },
  {
    code: '0452',
    name: '齐齐哈尔',
    pinyin: 'Qiqihaer',
    label: 'Qiqihaer0452'
  },
  {
    code: '0464',
    name: '七台河',
    pinyin: 'Qitaihe',
    label: 'Qitaihe0464'
  },
  {
    code: '0874',
    name: '曲靖',
    pinyin: 'Qujing',
    label: 'Qujing0874'
  },
  {
    code: '0570',
    name: '衢州',
    pinyin: 'Quzhou',
    label: 'Quzhou0570'
  },
  {
    code: '0315',
    name: '迁安',
    pinyin: 'Qianan',
    label: 'Qianan0315'
  },
  {
    code: '0556',
    name: '潜山',
    pinyin: 'Qianshan',
    label: 'Qianshan0556'
  },
  {
    code: '0793',
    name: '铅山',
    pinyin: 'Qianshan',
    label: 'Qianshan0793'
  },
  {
    code: '0315',
    name: '迁西',
    pinyin: 'Qianxi',
    label: 'Qianxi0315'
  },
  {
    code: '0513',
    name: '启东',
    pinyin: 'Qidong',
    label: 'Qidong0513'
  },
  {
    code: '0534',
    name: '齐河',
    pinyin: 'Qihe',
    label: 'Qihe0534'
  },
  {
    code: '023',
    name: '綦江',
    pinyin: 'Qijiang',
    label: 'Qijiang023'
  },
  {
    code: '0559',
    name: '祁门',
    pinyin: 'Qimen',
    label: 'Qimen0559'
  },
  {
    code: '0598',
    name: '清流',
    pinyin: 'Qingliu',
    label: 'Qingliu0598'
  },
  {
    code: '0578',
    name: '青田',
    pinyin: 'Qingtian',
    label: 'Qingtian0578'
  },
  {
    code: '0763',
    name: '清新',
    pinyin: 'Qingxin',
    label: 'Qingxin0763'
  },
  {
    code: '0566',
    name: '青阳',
    pinyin: 'Qingyang',
    label: 'Qingyang0566'
  },
  {
    code: '0578',
    name: '庆元',
    pinyin: 'Qingyuan',
    label: 'Qingyuan0578'
  },
  {
    code: '0534',
    name: '庆云',
    pinyin: 'Qingyun',
    label: 'Qingyun0534'
  },
  {
    code: '0851',
    name: '清镇',
    pinyin: 'Qingzhen',
    label: 'Qingzhen0851'
  },
  {
    code: '0536',
    name: '青州',
    pinyin: 'Qingzhou',
    label: 'Qingzhou0536'
  },
  {
    code: '0391',
    name: '沁阳',
    pinyin: 'Qinyang',
    label: 'Qinyang0391'
  },
  {
    code: '028',
    name: '邛崃',
    pinyin: 'Qionglai',
    label: 'Qionglai028'
  },
  {
    code: '0535',
    name: '栖霞',
    pinyin: 'Qixia',
    label: 'Qixia0535'
  },
  {
    code: '0550',
    name: '全椒',
    pinyin: 'Quanjiao',
    label: 'Quanjiao0550'
  },
  {
    code: '0797',
    name: '全南',
    pinyin: 'Quannan',
    label: 'Quannan0797'
  },
  {
    code: '0537',
    name: '曲阜',
    pinyin: 'Qufu',
    label: 'Qufu0537'
  },
  {
    code: '0751',
    name: '曲江',
    pinyin: 'Qujiang',
    label: 'Qujiang0751'
  },
  {
    code: '0892',
    name: '日喀则',
    pinyin: 'Rikaze',
    label: 'Rikaze0892'
  },
  {
    code: '0633',
    name: '日照',
    pinyin: 'Rizhao',
    label: 'Rizhao0633'
  },
  {
    code: '0768',
    name: '饶平',
    pinyin: 'Raoping',
    label: 'Raoping0768'
  },
  {
    code: '0751',
    name: '仁化',
    pinyin: 'Renhua',
    label: 'Renhua0751'
  },
  {
    code: '0772',
    name: '融安',
    pinyin: 'Rongan',
    label: 'Rongan0772'
  },
  {
    code: '023',
    name: '荣昌',
    pinyin: 'Rongchang',
    label: 'Rongchang023'
  },
  {
    code: '0631',
    name: '荣成',
    pinyin: 'Rongcheng',
    label: 'Rongcheng0631'
  },
  {
    code: '0772',
    name: '融水',
    pinyin: 'Rongshui',
    label: 'Rongshui0772'
  },
  {
    code: '0513',
    name: '如东',
    pinyin: 'Rudong',
    label: 'Rudong0513'
  },
  {
    code: '0513',
    name: '如皋',
    pinyin: 'Rugao',
    label: 'Rugao0513'
  },
  {
    code: '0577',
    name: '瑞安',
    pinyin: 'Ruian',
    label: 'Ruian0577'
  },
  {
    code: '0792',
    name: '瑞昌',
    pinyin: 'Ruichang',
    label: 'Ruichang0792'
  },
  {
    code: '0797',
    name: '瑞金',
    pinyin: 'Ruijin',
    label: 'Ruijin0797'
  },
  {
    code: '0631',
    name: '乳山',
    pinyin: 'Rushan',
    label: 'Rushan0631'
  },
  {
    code: '0379',
    name: '汝阳',
    pinyin: 'Ruyang',
    label: 'Ruyang0379'
  },
  {
    code: '0751',
    name: '乳源',
    pinyin: 'Ruyuan',
    label: 'Ruyuan0751'
  },
  {
    code: '0398',
    name: '三门峡',
    pinyin: 'Sanmenxia',
    label: 'Sanmenxia0398'
  },
  {
    code: '024',
    name: '沈阳',
    pinyin: 'Shenyang',
    label: 'Shenyang024'
  },
  {
    code: '0311',
    name: '石家庄',
    pinyin: 'Shijiazhuang',
    label: 'Shijiazhuang0311'
  },
  {
    code: '0598',
    name: '三明',
    pinyin: 'Sanming',
    label: 'Sanming0598'
  },
  {
    code: '0899',
    name: '三亚',
    pinyin: 'Sanya',
    label: 'Sanya0899'
  },
  {
    code: '0914',
    name: '商洛',
    pinyin: 'Shangluo',
    label: 'Shangluo0914'
  },
  {
    code: '0370',
    name: '商丘',
    pinyin: 'Shangqiu',
    label: 'Shangqiu0370'
  },
  {
    code: '0793',
    name: '上饶',
    pinyin: 'Shangrao',
    label: 'Shangrao0793'
  },
  {
    code: '0893',
    name: '山南',
    pinyin: 'Shannan',
    label: 'Shannan0893'
  },
  {
    code: '0754',
    name: '汕头',
    pinyin: 'Shantou',
    label: 'Shantou0754'
  },
  {
    code: '0660',
    name: '汕尾',
    pinyin: 'Shanwei',
    label: 'Shanwei0660'
  },
  {
    code: '0751',
    name: '韶关',
    pinyin: 'Shaoguan',
    label: 'Shaoguan0751'
  },
  {
    code: '0575',
    name: '绍兴',
    pinyin: 'Shaoxing',
    label: 'Shaoxing0575'
  },
  {
    code: '0000',
    name: '襄阳',
    pinyin: 'Xiangyang',
    label: 'Shaoyang0000'
  },
  {
    code: '0739',
    name: '邵阳',
    pinyin: 'Shaoyang',
    label: 'Shaoyang0739'
  },
  {
    code: '0719',
    name: '十堰',
    pinyin: 'Shiyan',
    label: 'Shiyan0719'
  },
  {
    code: '0952',
    name: '石嘴山',
    pinyin: 'Shizuishan',
    label: 'Shizuishan0952'
  },
  {
    code: '0469',
    name: '双鸭山',
    pinyin: 'Shuangyashan',
    label: 'Shuangyashan0469'
  },
  {
    code: '0349',
    name: '朔州',
    pinyin: 'Shuozhou',
    label: 'Shuozhou0349'
  },
  {
    code: '0434',
    name: '四平',
    pinyin: 'Siping',
    label: 'Siping0434'
  },
  {
    code: '0438',
    name: '松原',
    pinyin: 'Songyuan',
    label: 'Songyuan0438'
  },
  {
    code: '0455',
    name: '绥化',
    pinyin: 'Suihua',
    label: 'Suihua0455'
  },
  {
    code: '0825',
    name: '遂宁',
    pinyin: 'Suining',
    label: 'Suining0825'
  },
  {
    code: '0722',
    name: '随州',
    pinyin: 'Suizhou',
    label: 'Suizhou0722'
  },
  {
    code: '0527',
    name: '宿迁',
    pinyin: 'Suqian',
    label: 'Suqian0527'
  },
  {
    code: '0557',
    name: '宿州',
    pinyin: 'Suzhou',
    label: 'Suzhou0557'
  },
  {
    code: '0772',
    name: '三江',
    pinyin: 'Sanjiang',
    label: 'Sanjiang0772'
  },
  {
    code: '0576',
    name: '三门',
    pinyin: 'Sanmen',
    label: 'Sanmen0576'
  },
  {
    code: '0596',
    name: '诏安',
    pinyin: 'Saoan',
    label: 'Saoan0596'
  },
  {
    code: '0795',
    name: '上高',
    pinyin: 'Shanggao',
    label: 'Shanggao0795'
  },
  {
    code: '0597',
    name: '上杭',
    pinyin: 'Shanghang',
    label: 'Shanghang0597'
  },
  {
    code: '0531',
    name: '商河',
    pinyin: 'Shanghe',
    label: 'Shanghe0531'
  },
  {
    code: '0799',
    name: '上栗',
    pinyin: 'Shangli',
    label: 'Shangli0799'
  },
  {
    code: '0771',
    name: '上林',
    pinyin: 'Shanglin',
    label: 'Shanglin0771'
  },
  {
    code: '0793',
    name: '上饶',
    pinyin: 'Shangrao',
    label: 'Shangrao0793'
  },
  {
    code: '0797',
    name: '上犹',
    pinyin: 'Shangyou',
    label: 'Shangyou0797'
  },
  {
    code: '0575',
    name: '上虞',
    pinyin: 'Shangyu',
    label: 'Shangyu0575'
  },
  {
    code: '0451',
    name: '尚志',
    pinyin: 'Shangzhi',
    label: 'Shangzhi0451'
  },
  {
    code: '0599',
    name: '邵武',
    pinyin: 'Shaowu',
    label: 'Shaowu0599'
  },
  {
    code: '0575',
    name: '绍兴',
    pinyin: 'Shaoxing',
    label: 'Shaoxing0575'
  },
  {
    code: '0598',
    name: '沙县',
    pinyin: 'Shaxian',
    label: 'Shaxian0598'
  },
  {
    code: '0580',
    name: '嵊泗',
    pinyin: 'Shengsi',
    label: 'Shengsi0580'
  },
  {
    code: '0575',
    name: '嵊州',
    pinyin: 'Shengzhou',
    label: 'Shengzhou0575'
  },
  {
    code: '0635',
    name: '莘县',
    pinyin: 'Shenxian',
    label: 'Shenxian0635'
  },
  {
    code: '0311',
    name: '深泽',
    pinyin: 'Shenze',
    label: 'Shenze0311'
  },
  {
    code: '0559',
    name: '歙县',
    pinyin: 'Shexian',
    label: 'Shexian0559'
  },
  {
    code: '0515',
    name: '射阳',
    pinyin: 'Sheyang',
    label: 'Sheyang0515'
  },
  {
    code: '0797',
    name: '石城',
    pinyin: 'Shicheng',
    label: 'Shicheng0797'
  },
  {
    code: '0871',
    name: '石林',
    pinyin: 'Shilin',
    label: 'Shilin0871'
  },
  {
    code: '0595',
    name: '石狮',
    pinyin: 'Shishi',
    label: 'Shishi0595'
  },
  {
    code: '0566',
    name: '石台',
    pinyin: 'Shitai',
    label: 'Shitai0566'
  },
  {
    code: '0751',
    name: '始兴',
    pinyin: 'Shixing',
    label: 'Shixing0751'
  },
  {
    code: '023',
    name: '石柱',
    pinyin: 'Shizhu',
    label: 'Shizhu023'
  },
  {
    code: '0536',
    name: '寿光',
    pinyin: 'Shouguang',
    label: 'Shouguang0536'
  },
  {
    code: '0593',
    name: '寿宁',
    pinyin: 'Shouning',
    label: 'Shouning0593'
  },
  {
    code: '0564',
    name: '寿县',
    pinyin: 'Shouxian',
    label: 'Shouxian0564'
  },
  {
    code: '0451',
    name: '双城',
    pinyin: 'Shuangcheng',
    label: 'Shuangcheng0451'
  },
  {
    code: '028',
    name: '双流',
    pinyin: 'Shuangliu',
    label: 'Shuangliu028'
  },
  {
    code: '0564',
    name: '舒城',
    pinyin: 'Shucheng',
    label: 'Shucheng0564'
  },
  {
    code: '0423',
    name: '舒兰',
    pinyin: 'Shulan',
    label: 'Shulan0423'
  },
  {
    code: '0599',
    name: '顺昌',
    pinyin: 'Shunchang',
    label: 'Shunchang0599'
  },
  {
    code: '0527',
    name: '沭阳',
    pinyin: 'Shuyang',
    label: 'Shuyang0527'
  },
  {
    code: '0527',
    name: '泗洪',
    pinyin: 'Sihong',
    label: 'Sihong0527'
  },
  {
    code: '0758',
    name: '四会',
    pinyin: 'Sihui',
    label: 'Sihui0758'
  },
  {
    code: '0537',
    name: '泗水',
    pinyin: 'Sishui',
    label: 'Sishui0537'
  },
  {
    code: '0557',
    name: '泗县',
    pinyin: 'Sixian',
    label: 'Sixian0557'
  },
  {
    code: '0527',
    name: '泗阳',
    pinyin: 'Siyang',
    label: 'Siyang0527'
  },
  {
    code: '0871',
    name: '嵩明',
    pinyin: 'Songming',
    label: 'Songming0871'
  },
  {
    code: '0599',
    name: '松溪',
    pinyin: 'Songxi',
    label: 'Songxi0599'
  },
  {
    code: '0379',
    name: '嵩县',
    pinyin: 'Songxian',
    label: 'Songxian0379'
  },
  {
    code: '0578',
    name: '松阳',
    pinyin: 'Songyang',
    label: 'Songyang0578'
  },
  {
    code: '0578',
    name: '遂昌',
    pinyin: 'Suichang',
    label: 'Suichang0578'
  },
  {
    code: '0796',
    name: '遂川',
    pinyin: 'Suichuan',
    label: 'Suichuan0796'
  },
  {
    code: '0516',
    name: '睢宁',
    pinyin: 'Suining',
    label: 'Suining0516'
  },
  {
    code: '0561',
    name: '濉溪',
    pinyin: 'Suixi',
    label: 'Suixi0561'
  },
  {
    code: '0759',
    name: '遂溪',
    pinyin: 'Suixi',
    label: 'Suixi0759'
  },
  {
    code: '0556',
    name: '宿松',
    pinyin: 'Susong',
    label: 'Susong0556'
  },
  {
    code: '0527',
    name: '宿豫',
    pinyin: 'Suyu',
    label: 'Suyu0527'
  },
  {
    code: '0901',
    name: '塔城地',
    pinyin: 'Tachengdi',
    label: 'Tachengdi0901'
  },
  {
    code: '0576',
    name: '台州',
    pinyin: 'Taizhou',
    label: 'Taizhou0576'
  },
  {
    code: '0315',
    name: '唐山',
    pinyin: 'Tangshan',
    label: 'Tangshan0315'
  },
  {
    code: '0538',
    name: '泰安',
    pinyin: 'Taian',
    label: 'Taian0538'
  },
  {
    code: '0351',
    name: '太原',
    pinyin: 'Taiyuan',
    label: 'Taiyuan0351'
  },
  {
    code: '0523',
    name: '泰州',
    pinyin: 'Taizhou',
    label: 'Taizhou0523'
  },
  {
    code: '0938',
    name: '天水',
    pinyin: 'Tianshui',
    label: 'Tianshui0938'
  },
  {
    code: '0410',
    name: '铁岭',
    pinyin: 'Tieling',
    label: 'Tieling0410'
  },
  {
    code: '0919',
    name: '铜川',
    pinyin: 'Tongchuan',
    label: 'Tongchuan0919'
  },
  {
    code: '0435',
    name: '通化',
    pinyin: 'Tonghua',
    label: 'Tonghua0435'
  },
  {
    code: '0475',
    name: '通辽',
    pinyin: 'Tongliao',
    label: 'Tongliao0475'
  },
  {
    code: '0562',
    name: '铜陵',
    pinyin: 'Tongling',
    label: 'Tongling0562'
  },
  {
    code: '0856',
    name: '铜仁',
    pinyin: 'Tongren',
    label: 'Tongren0856'
  },
  {
    code: '0995',
    name: '吐鲁番',
    pinyin: 'Tulufan',
    label: 'Tulufan0995'
  },
  {
    code: '0512',
    name: '太仓',
    pinyin: 'Taicang',
    label: 'Taicang0512'
  },
  {
    code: '0558',
    name: '太和',
    pinyin: 'Taihe',
    label: 'Taihe0558'
  },
  {
    code: '0796',
    name: '泰和',
    pinyin: 'Taihe',
    label: 'Taihe0796'
  },
  {
    code: '0556',
    name: '太湖',
    pinyin: 'Taihu',
    label: 'Taihu0556'
  },
  {
    code: '0598',
    name: '泰宁',
    pinyin: 'Taining',
    label: 'Taining0598'
  },
  {
    code: '0750',
    name: '台山',
    pinyin: 'Taishan',
    label: 'Taishan0750'
  },
  {
    code: '0577',
    name: '泰顺',
    pinyin: 'Taishun',
    label: 'Taishun0577'
  },
  {
    code: '0523',
    name: '泰兴',
    pinyin: 'Taixing',
    label: 'Taixing0523'
  },
  {
    code: '0539',
    name: '郯城',
    pinyin: 'Tancheng',
    label: 'Tancheng0539'
  },
  {
    code: '0315',
    name: '唐海',
    pinyin: 'Tanghai',
    label: 'Tanghai0315'
  },
  {
    code: '0623',
    name: '滕州',
    pinyin: 'Tengzhou',
    label: 'Tengzhou0623'
  },
  {
    code: '0550',
    name: '天长',
    pinyin: 'Tianchang',
    label: 'Tianchang0550'
  },
  {
    code: '0576',
    name: '天台',
    pinyin: 'Tiantai',
    label: 'Tiantai0576'
  },
  {
    code: '0556',
    name: '桐城',
    pinyin: 'Tongcheng',
    label: 'Tongcheng0556'
  },
  {
    code: '0795',
    name: '铜鼓',
    pinyin: 'Tonggu',
    label: 'Tonggu0795'
  },
  {
    code: '0451',
    name: '通河',
    pinyin: 'Tonghe',
    label: 'Tonghe0451'
  },
  {
    code: '023',
    name: '铜梁',
    pinyin: 'Tongliang',
    label: 'Tongliang023'
  },
  {
    code: '0562',
    name: '铜陵',
    pinyin: 'Tongling',
    label: 'Tongling0562'
  },
  {
    code: '0571',
    name: '桐庐',
    pinyin: 'Tonglu',
    label: 'Tonglu0571'
  },
  {
    code: '023',
    name: '潼南',
    pinyin: 'Tongnan',
    label: 'Tongnan023'
  },
  {
    code: '0516',
    name: '铜山',
    pinyin: 'Tongshan',
    label: 'Tongshan0516'
  },
  {
    code: '0573',
    name: '桐乡',
    pinyin: 'Tongxiang',
    label: 'Tongxiang0573'
  },
  {
    code: '0513',
    name: '通州',
    pinyin: 'Tongzhou',
    label: 'Tongzhou0513'
  },
  {
    code: '0913',
    name: '渭南',
    pinyin: 'Weinan',
    label: 'Weinan0913'
  },
  {
    code: '0536',
    name: '潍坊',
    pinyin: 'Weifang',
    label: 'Weifang0536'
  },
  {
    code: '0631',
    name: '威海',
    pinyin: 'Weihai',
    label: 'Weihai0631'
  },
  {
    code: '0510',
    name: '无锡',
    pinyin: 'Wuxi',
    label: 'Wuxi0510'
  },
  {
    code: '0876',
    name: '文山',
    pinyin: 'Wenshan',
    label: 'Wenshan0876'
  },
  {
    code: '0577',
    name: '温州',
    pinyin: 'Wenzhou',
    label: 'Wenzhou0577'
  },
  {
    code: '0473',
    name: '乌海',
    pinyin: 'Wuhai',
    label: 'Wuhai0473'
  },
  {
    code: '0553',
    name: '芜湖',
    pinyin: 'Wuhu',
    label: 'Wuhu0553'
  },
  {
    code: '0474',
    name: '乌兰察布',
    pinyin: 'Wulanchabu',
    label: 'Wulanchabu0474'
  },
  {
    code: '0991',
    name: '乌鲁木齐',
    pinyin: 'Wulumuqi',
    label: 'Wulumuqi0991'
  },
  {
    code: '0935',
    name: '武威',
    pinyin: 'Wuwei',
    label: 'Wuwei0935'
  },
  {
    code: '0953',
    name: '吴忠',
    pinyin: 'Wuzhong',
    label: 'Wuzhong0953'
  },
  {
    code: '0774',
    name: '梧州',
    pinyin: 'Wuzhou',
    label: 'Wuzhou0774'
  },
  {
    code: '0411',
    name: '瓦房店',
    pinyin: 'Wafangdian',
    label: 'Wafangdian0411'
  },
  {
    code: '0796',
    name: '万安',
    pinyin: 'Wanan',
    label: 'Wanan0796'
  },
  {
    code: '0731',
    name: '望城',
    pinyin: 'Wangcheng',
    label: 'Wangcheng0731'
  },
  {
    code: '0556',
    name: '望江',
    pinyin: 'Wangjiang',
    label: 'Wangjiang0556'
  },
  {
    code: '0793',
    name: '万年',
    pinyin: 'Wannian',
    label: 'Wannian0793'
  },
  {
    code: '0795',
    name: '万载',
    pinyin: 'Wanzai',
    label: 'Wanzai0795'
  },
  {
    code: '0537',
    name: '微山',
    pinyin: 'Weishan',
    label: 'Weishan0537'
  },
  {
    code: '0577',
    name: '文成',
    pinyin: 'Wencheng',
    label: 'Wencheng0577'
  },
  {
    code: '0631',
    name: '文登',
    pinyin: 'Wendeng',
    label: 'Wendeng0631'
  },
  {
    code: '0751',
    name: '翁源',
    pinyin: 'Wengyuan',
    label: 'Wengyuan0751'
  },
  {
    code: '0576',
    name: '温岭',
    pinyin: 'Wenling',
    label: 'Wenling0576'
  },
  {
    code: '0537',
    name: '汶上',
    pinyin: 'Wenshang',
    label: 'Wenshang0537'
  },
  {
    code: '0391',
    name: '温县',
    pinyin: 'Wenxian',
    label: 'Wenxian0391'
  },
  {
    code: '0558',
    name: '涡阳',
    pinyin: 'Woyang',
    label: 'Woyang0558'
  },
  {
    code: '0451',
    name: '五常',
    pinyin: 'Wuchang',
    label: 'Wuchang0451'
  },
  {
    code: '0534',
    name: '武城',
    pinyin: 'Wucheng',
    label: 'Wucheng0534'
  },
  {
    code: '0759',
    name: '吴川',
    pinyin: 'Wuchuan',
    label: 'Wuchuan0759'
  },
  {
    code: '0543',
    name: '无棣',
    pinyin: 'Wudi',
    label: 'Wudi0543'
  },
  {
    code: '0552',
    name: '五河',
    pinyin: 'Wuhe',
    label: 'Wuhe0552'
  },
  {
    code: '0553',
    name: '芜湖',
    pinyin: 'Wuhu',
    label: 'Wuhu0553'
  },
  {
    code: '0753',
    name: '五华',
    pinyin: 'Wuhua',
    label: 'Wuhua0753'
  },
  {
    code: '0311',
    name: '无极',
    pinyin: 'Wuji',
    label: 'Wuji0311'
  },
  {
    code: '0512',
    name: '吴江',
    pinyin: 'Wujiang',
    label: 'Wujiang0512'
  },
  {
    code: '0633',
    name: '五莲',
    pinyin: 'Wulian',
    label: 'Wulian0633'
  },
  {
    code: '023',
    name: '武隆',
    pinyin: 'Wulong',
    label: 'Wulong023'
  },
  {
    code: '0771',
    name: '武鸣',
    pinyin: 'Wuming',
    label: 'Wuming0771'
  },
  {
    code: '0792',
    name: '武宁',
    pinyin: 'Wuning',
    label: 'Wuning0792'
  },
  {
    code: '0597',
    name: '武平',
    pinyin: 'Wuping',
    label: 'Wuping0597'
  },
  {
    code: '023',
    name: '巫山',
    pinyin: 'Wushan',
    label: 'Wushan023'
  },
  {
    code: '0565',
    name: '无为',
    pinyin: 'Wuwei',
    label: 'Wuwei0565'
  },
  {
    code: '023',
    name: '巫溪',
    pinyin: 'Wuxi',
    label: 'Wuxi023'
  },
  {
    code: '0579',
    name: '武义',
    pinyin: 'Wuyi',
    label: 'Wuyi0579'
  },
  {
    code: '0599',
    name: '武夷山',
    pinyin: 'Wuyishan',
    label: 'Wuyishan0599'
  },
  {
    code: '0793',
    name: '婺源',
    pinyin: 'Wuyuan',
    label: 'Wuyuan0793'
  },
  {
    code: '0391',
    name: '武陟',
    pinyin: 'Wuzhi',
    label: 'Wuzhi0391'
  },
  {
    code: '0710',
    name: '襄樊',
    pinyin: 'Xiangfan',
    label: 'Xiangfan0710'
  },
  {
    code: '0374',
    name: '许昌',
    pinyin: 'Xuchang',
    label: 'Xuchang0374'
  },
  {
    code: '0516',
    name: '徐州',
    pinyin: 'Xuzhou',
    label: 'Xuzhou0516'
  },
  {
    code: '0732',
    name: '湘潭',
    pinyin: 'Xiangtan',
    label: 'Xiangtan0732'
  },
  {
    code: '0743',
    name: '湘西',
    pinyin: 'Xiangxi',
    label: 'Xiangxi0743'
  },
  {
    code: '0715',
    name: '咸宁',
    pinyin: 'Xianning',
    label: 'Xianning0715'
  },
  {
    code: '029',
    name: '咸阳',
    pinyin: 'Xianyang',
    label: 'Xianyang029'
  },
  {
    code: '0712',
    name: '孝感',
    pinyin: 'Xiaogan',
    label: 'Xiaogan0712'
  },
  {
    code: '0479',
    name: '锡林郭勒盟',
    pinyin: 'Xilinguolemeng',
    label: 'Xilinguolemeng0479'
  },
  {
    code: '0482',
    name: '兴安盟',
    pinyin: 'Xinganmeng',
    label: 'Xinganmeng0482'
  },
  {
    code: '0319',
    name: '邢台',
    pinyin: 'Xingtai',
    label: 'Xingtai0319'
  },
  {
    code: '0971',
    name: '西宁',
    pinyin: 'Xining',
    label: 'Xining0971'
  },
  {
    code: '0373',
    name: '新乡',
    pinyin: 'Xinxiang',
    label: 'Xinxiang0373'
  },
  {
    code: '0376',
    name: '信阳',
    pinyin: 'Xinyang',
    label: 'Xinyang0376'
  },
  {
    code: '0790',
    name: '新余',
    pinyin: 'Xinyu',
    label: 'Xinyu0790'
  },
  {
    code: '0350',
    name: '忻州',
    pinyin: 'Xinzhou',
    label: 'Xinzhou0350'
  },
  {
    code: '0691',
    name: '西双版纳',
    pinyin: 'Xishuangbanna',
    label: 'Xishuangbanna0691'
  },
  {
    code: '0563',
    name: '宣城',
    pinyin: 'Xuancheng',
    label: 'Xuancheng0563'
  },
  {
    code: '0796',
    name: '峡江',
    pinyin: 'Xiajiang',
    label: 'Xiajiang0796'
  },
  {
    code: '0534',
    name: '夏津',
    pinyin: 'Xiajin',
    label: 'Xiajin0534'
  },
  {
    code: '0574',
    name: '象山',
    pinyin: 'Xiangshan',
    label: 'Xiangshan0574'
  },
  {
    code: '0515',
    name: '响水',
    pinyin: 'Xiangshui',
    label: 'Xiangshui0515'
  },
  {
    code: '0576',
    name: '仙居',
    pinyin: 'Xianju',
    label: 'Xianju0576'
  },
  {
    code: '0594',
    name: '仙游',
    pinyin: 'Xianyou',
    label: 'Xianyou0594'
  },
  {
    code: '0557',
    name: '萧县',
    pinyin: 'Xiaoxian',
    label: 'Xiaoxian0557'
  },
  {
    code: '0593',
    name: '霞浦',
    pinyin: 'Xiapu',
    label: 'Xiapu0593'
  },
  {
    code: '0851',
    name: '息烽',
    pinyin: 'Xifeng',
    label: 'Xifeng0851'
  },
  {
    code: '0379',
    name: '新安',
    pinyin: 'Xinan',
    label: 'Xinan0379'
  },
  {
    code: '0575',
    name: '新昌',
    pinyin: 'Xinchang',
    label: 'Xinchang0575'
  },
  {
    code: '0797',
    name: '信丰',
    pinyin: 'Xinfeng',
    label: 'Xinfeng0797'
  },
  {
    code: '0751',
    name: '新丰',
    pinyin: 'Xinfeng',
    label: 'Xinfeng0751'
  },
  {
    code: '0796',
    name: '新干',
    pinyin: 'Xingan',
    label: 'Xingan0796'
  },
  {
    code: '0797',
    name: '兴国',
    pinyin: 'Xingguo',
    label: 'Xingguo0797'
  },
  {
    code: '0523',
    name: '兴化',
    pinyin: 'Xinghua',
    label: 'Xinghua0523'
  },
  {
    code: '0753',
    name: '兴宁',
    pinyin: 'Xingning',
    label: 'Xingning0753'
  },
  {
    code: '0311',
    name: '行唐',
    pinyin: 'Xingtang',
    label: 'Xingtang0311'
  },
  {
    code: '0371',
    name: '荥阳',
    pinyin: 'Xingyang',
    label: 'Xingyang0371'
  },
  {
    code: '0792',
    name: '星子',
    pinyin: 'Xingzi',
    label: 'Xingzi0792'
  },
  {
    code: '0311',
    name: '辛集',
    pinyin: 'Xinji',
    label: 'Xinji0311'
  },
  {
    code: '0791',
    name: '新建',
    pinyin: 'Xinjian',
    label: 'Xinjian0791'
  },
  {
    code: '028',
    name: '新津',
    pinyin: 'Xinjin',
    label: 'Xinjin028'
  },
  {
    code: '0311',
    name: '新乐',
    pinyin: 'Xinle',
    label: 'Xinle0311'
  },
  {
    code: '024',
    name: '新民',
    pinyin: 'Xinmin',
    label: 'Xinmin024'
  },
  {
    code: '0371',
    name: '新密',
    pinyin: 'Xinmi',
    label: 'Xinmi0371'
  },
  {
    code: '0538',
    name: '新泰',
    pinyin: 'Xintai',
    label: 'Xintai0538'
  },
  {
    code: '0766',
    name: '新兴',
    pinyin: 'Xinxing',
    label: 'Xinxing0766'
  },
  {
    code: '0516',
    name: '新沂',
    pinyin: 'Xinyi',
    label: 'Xinyi0516'
  },
  {
    code: '0668',
    name: '信宜',
    pinyin: 'Xinyi',
    label: 'Xinyi0668'
  },
  {
    code: '0371',
    name: '新郑',
    pinyin: 'Xinzheng',
    label: 'Xinzheng0371'
  },
  {
    code: '0559',
    name: '休宁',
    pinyin: 'Xiuning',
    label: 'Xiuning0559'
  },
  {
    code: '023',
    name: '秀山',
    pinyin: 'Xiushan',
    label: 'Xiushan023'
  },
  {
    code: '0792',
    name: '修水',
    pinyin: 'Xiushui',
    label: 'Xiushui0792'
  },
  {
    code: '0851',
    name: '修文',
    pinyin: 'Xiuwen',
    label: 'Xiuwen0851'
  },
  {
    code: '0391',
    name: '修武',
    pinyin: 'Xiuwu',
    label: 'Xiuwu0391'
  },
  {
    code: '0871',
    name: '寻甸',
    pinyin: 'Xundian',
    label: 'Xundian0871'
  },
  {
    code: '0797',
    name: '寻乌',
    pinyin: 'Xunwu',
    label: 'Xunwu0797'
  },
  {
    code: '0759',
    name: '徐闻',
    pinyin: 'Xuwen',
    label: 'Xuwen0759'
  },
  {
    code: '0517',
    name: '盱眙',
    pinyin: 'Xuyi',
    label: 'Xuyi0517'
  },
  {
    code: '0835',
    name: '雅安',
    pinyin: 'Yaan',
    label: 'Yaan0835'
  },
  {
    code: '0514',
    name: '扬州',
    pinyin: 'Yangzhou',
    label: 'Yangzhou0514'
  },
  {
    code: '0535',
    name: '烟台',
    pinyin: 'Yantai',
    label: 'Yantai0535'
  },
  {
    code: '0911',
    name: '延安',
    pinyin: 'Yanan',
    label: 'Yanan0911'
  },
  {
    code: '0433',
    name: '延边',
    pinyin: 'Yanbian',
    label: 'Yanbian0433'
  },
  {
    code: '0662',
    name: '阳江',
    pinyin: 'Yangjiang',
    label: 'Yangjiang0662'
  },
  {
    code: '0353',
    name: '阳泉',
    pinyin: 'Yangquan',
    label: 'Yangquan0353'
  },
  {
    code: '0831',
    name: '宜宾',
    pinyin: 'Yibin',
    label: 'Yibin0831'
  },
  {
    code: '0717',
    name: '宜昌',
    pinyin: 'Yichang',
    label: 'Yichang0717'
  },
  {
    code: '0458',
    name: '伊春',
    pinyin: 'Yichun',
    label: 'Yichun0458'
  },
  {
    code: '0795',
    name: '宜春',
    pinyin: 'Yichun',
    label: 'Yichun0795'
  },
  {
    code: '0999',
    name: '伊犁哈萨克',
    pinyin: 'Yilihasake',
    label: 'Yilihasake0999'
  },
  {
    code: '0951',
    name: '银川',
    pinyin: 'Yinchuan',
    label: 'Yinchuan0951'
  },
  {
    code: '0417',
    name: '营口',
    pinyin: 'Yingkou',
    label: 'Yingkou0417'
  },
  {
    code: '0701',
    name: '鹰潭',
    pinyin: 'Yingtan',
    label: 'Yingtan0701'
  },
  {
    code: '0737',
    name: '益阳',
    pinyin: 'Yiyang',
    label: 'Yiyang0737'
  },
  {
    code: '0746',
    name: '永州',
    pinyin: 'Yongzhou',
    label: 'Yongzhou0746'
  },
  {
    code: '0730',
    name: '岳阳',
    pinyin: 'Yueyang',
    label: 'Yueyang0730'
  },
  {
    code: '0775',
    name: '玉林',
    pinyin: 'Yulin',
    label: 'Yulin0775'
  },
  {
    code: '0912',
    name: '榆林',
    pinyin: 'Yulin',
    label: 'Yulin0912'
  },
  {
    code: '0359',
    name: '运城',
    pinyin: 'Yuncheng',
    label: 'Yuncheng0359'
  },
  {
    code: '0766',
    name: '云浮',
    pinyin: 'Yunfu',
    label: 'Yunfu0766'
  },
  {
    code: '0976',
    name: '玉树',
    pinyin: 'Yushu',
    label: 'Yushu0976'
  },
  {
    code: '0877',
    name: '玉溪',
    pinyin: 'Yuxi',
    label: 'Yuxi0877'
  },
  {
    code: '0662',
    name: '阳春',
    pinyin: 'Yangchun',
    label: 'Yangchun0662'
  },
  {
    code: '0662',
    name: '阳东',
    pinyin: 'Yangdong',
    label: 'Yangdong0662'
  },
  {
    code: '0635',
    name: '阳谷',
    pinyin: 'Yanggu',
    label: 'Yanggu0635'
  },
  {
    code: '0763',
    name: '阳山',
    pinyin: 'Yangshan',
    label: 'Yangshan0763'
  },
  {
    code: '0543',
    name: '阳信',
    pinyin: 'Yangxin',
    label: 'Yangxin0543'
  },
  {
    code: '0662',
    name: '阳西',
    pinyin: 'Yangxi',
    label: 'Yangxi0662'
  },
  {
    code: '0511',
    name: '扬中',
    pinyin: 'Yangzhong',
    label: 'Yangzhong0511'
  },
  {
    code: '0379',
    name: '偃师',
    pinyin: 'Yanshi',
    label: 'Yanshi0379'
  },
  {
    code: '0451',
    name: '延寿',
    pinyin: 'Yanshou',
    label: 'Yanshou0451'
  },
  {
    code: '0537',
    name: '兖州',
    pinyin: 'Yanzhou',
    label: 'Yanzhou0537'
  },
  {
    code: '0379',
    name: '伊川',
    pinyin: 'Yichuan',
    label: 'Yichuan0379'
  },
  {
    code: '0795',
    name: '宜丰',
    pinyin: 'Yifeng',
    label: 'Yifeng0795'
  },
  {
    code: '0794',
    name: '宜黄',
    pinyin: 'Yihuang',
    label: 'Yihuang0794'
  },
  {
    code: '0451',
    name: '依兰',
    pinyin: 'Yilan',
    label: 'Yilan0451'
  },
  {
    code: '0871',
    name: '宜良',
    pinyin: 'Yiliang',
    label: 'Yiliang0871'
  },
  {
    code: '0539',
    name: '沂南',
    pinyin: 'Yinan',
    label: 'Yinan0539'
  },
  {
    code: '0763',
    name: '英德',
    pinyin: 'Yingde',
    label: 'Yingde0763'
  },
  {
    code: '0558',
    name: '颍上',
    pinyin: 'Yingshang',
    label: 'Yingshang0558'
  },
  {
    code: '0539',
    name: '沂水',
    pinyin: 'Yishui',
    label: 'Yishui0539'
  },
  {
    code: '0579',
    name: '义乌',
    pinyin: 'Yiwu',
    label: 'Yiwu0579'
  },
  {
    code: '0559',
    name: '黟县',
    pinyin: 'Yixian',
    label: 'Yixian0559'
  },
  {
    code: '0510',
    name: '宜兴',
    pinyin: 'Yixing',
    label: 'Yixing0510'
  },
  {
    code: '0793',
    name: '弋阳',
    pinyin: 'Yiyang',
    label: 'Yiyang0793'
  },
  {
    code: '0379',
    name: '宜阳',
    pinyin: 'Yiyang',
    label: 'Yiyang0379'
  },
  {
    code: '0533',
    name: '沂源',
    pinyin: 'Yiyuan',
    label: 'Yiyuan0533'
  },
  {
    code: '0514',
    name: '仪征',
    pinyin: 'Yizheng',
    label: 'Yizheng0514'
  },
  {
    code: '0598',
    name: '永安',
    pinyin: 'Yongan',
    label: 'Yongan0598'
  },
  {
    code: '023',
    name: '永川',
    pinyin: 'Yongchuan',
    label: 'Yongchuan023'
  },
  {
    code: '0595',
    name: '永春',
    pinyin: 'Yongchun',
    label: 'Yongchun0595'
  },
  {
    code: '0931',
    name: '永登',
    pinyin: 'Yongdeng',
    label: 'Yongdeng0931'
  },
  {
    code: '0597',
    name: '永定',
    pinyin: 'Yongding',
    label: 'Yongding0597'
  },
  {
    code: '0796',
    name: '永丰',
    pinyin: 'Yongfeng',
    label: 'Yongfeng0796'
  },
  {
    code: '0423',
    name: '永吉',
    pinyin: 'Yongji',
    label: 'Yongji0423'
  },
  {
    code: '0577',
    name: '永嘉',
    pinyin: 'Yongjia',
    label: 'Yongjia0577'
  },
  {
    code: '0579',
    name: '永康',
    pinyin: 'Yongkang',
    label: 'Yongkang0579'
  },
  {
    code: '0771',
    name: '邕宁',
    pinyin: 'Yongning',
    label: 'Yongning0771'
  },
  {
    code: '0591',
    name: '永泰',
    pinyin: 'Yongtai',
    label: 'Yongtai0591'
  },
  {
    code: '0796',
    name: '永新',
    pinyin: 'Yongxin',
    label: 'Yongxin0796'
  },
  {
    code: '0792',
    name: '永修',
    pinyin: 'Yongxiu',
    label: 'Yongxiu0792'
  },
  {
    code: '0598',
    name: '尤溪',
    pinyin: 'Youxi',
    label: 'Youxi0598'
  },
  {
    code: '023',
    name: '酉阳',
    pinyin: 'Youyang',
    label: 'Youyang023'
  },
  {
    code: '0311',
    name: '元氏',
    pinyin: 'Yuanshi',
    label: 'Yuanshi0311'
  },
  {
    code: '0534',
    name: '禹城',
    pinyin: 'Yucheng',
    label: 'Yucheng0534'
  },
  {
    code: '0797',
    name: '于都',
    pinyin: 'Yudu',
    label: 'Yudu0797'
  },
  {
    code: '0556',
    name: '岳西',
    pinyin: 'Yuexi',
    label: 'Yuexi0556'
  },
  {
    code: '0793',
    name: '余干',
    pinyin: 'Yugan',
    label: 'Yugan0793'
  },
  {
    code: '0576',
    name: '玉环',
    pinyin: 'Yuhuan',
    label: 'Yuhuan0576'
  },
  {
    code: '0701',
    name: '余江',
    pinyin: 'Yujiang',
    label: 'Yujiang0701'
  },
  {
    code: '0766',
    name: '郁南',
    pinyin: 'Yunan',
    label: 'Yunan0766'
  },
  {
    code: '0766',
    name: '云安',
    pinyin: 'Yunan',
    label: 'Yunan0766'
  },
  {
    code: '0530',
    name: '郓城',
    pinyin: 'Yuncheng',
    label: 'Yuncheng0530'
  },
  {
    code: '0578',
    name: '云和',
    pinyin: 'Yunhe',
    label: 'Yunhe0578'
  },
  {
    code: '0596',
    name: '云霄',
    pinyin: 'Yunxiao',
    label: 'Yunxiao0596'
  },
  {
    code: '023',
    name: '云阳',
    pinyin: 'Yunyang',
    label: 'Yunyang023'
  },
  {
    code: '0793',
    name: '玉山',
    pinyin: 'Yushan',
    label: 'Yushan0793'
  },
  {
    code: '0431',
    name: '榆树',
    pinyin: 'Yushu',
    label: 'Yushu0431'
  },
  {
    code: '0537',
    name: '鱼台',
    pinyin: 'Yutai',
    label: 'Yutai0537'
  },
  {
    code: '0315',
    name: '玉田',
    pinyin: 'Yutian',
    label: 'Yutian0315'
  },
  {
    code: '0574',
    name: '余姚',
    pinyin: 'Yuyao',
    label: 'Yuyao0574'
  },
  {
    code: '0931',
    name: '榆中',
    pinyin: 'Yuzhong',
    label: 'Yuzhong0931'
  },
  {
    code: '0596',
    name: '漳州',
    pinyin: 'Zhangzhou',
    label: 'Zhangzhou0596'
  },
  {
    code: '0760',
    name: '中山',
    pinyin: 'Zhongshan',
    label: 'Zhongshan0760'
  },
  {
    code: '0756',
    name: '珠海',
    pinyin: 'Zhuhai',
    label: 'Zhuhai0756'
  },
  {
    code: '0623',
    name: '枣庄',
    pinyin: 'Zaozhuang',
    label: 'Zaozhuang0623'
  },
  {
    code: '0744',
    name: '张家界',
    pinyin: 'Zhangjiajie',
    label: 'Zhangjiajie0744'
  },
  {
    code: '0313',
    name: '张家口',
    pinyin: 'Zhangjiakou',
    label: 'Zhangjiakou0313'
  },
  {
    code: '0936',
    name: '张掖',
    pinyin: 'Zhangye',
    label: 'Zhangye0936'
  },
  {
    code: '0759',
    name: '湛江',
    pinyin: 'Zhanjiang',
    label: 'Zhanjiang0759'
  },
  {
    code: '0758',
    name: '肇庆',
    pinyin: 'Zhaoqing',
    label: 'Zhaoqing0758'
  },
  {
    code: '0870',
    name: '昭通',
    pinyin: 'Zhaotong',
    label: 'Zhaotong0870'
  },
  {
    code: '0511',
    name: '镇江',
    pinyin: 'Zhenjiang',
    label: 'Zhenjiang0511'
  },
  {
    code: '0955',
    name: '中卫',
    pinyin: 'Zhongwei',
    label: 'Zhongwei0955'
  },
  {
    code: '0394',
    name: '周口',
    pinyin: 'Zhoukou',
    label: 'Zhoukou0394'
  },
  {
    code: '0580',
    name: '舟山',
    pinyin: 'Zhoushan',
    label: 'Zhoushan0580'
  },
  {
    code: '0396',
    name: '驻马店',
    pinyin: 'Zhumadian',
    label: 'Zhumadian0396'
  },
  {
    code: '0731',
    name: '株洲',
    pinyin: 'Zhuzhou',
    label: 'Zhuzhou0731'
  },
  {
    code: '0533',
    name: '淄博',
    pinyin: 'Zibo',
    label: 'Zibo0533'
  },
  {
    code: '0813',
    name: '自贡',
    pinyin: 'Zigong',
    label: 'Zigong0813'
  },
  {
    code: '028',
    name: '资阳',
    pinyin: 'Ziyang',
    label: 'Ziyang028'
  },
  {
    code: '0852',
    name: '遵义',
    pinyin: 'Zunyi',
    label: 'Zunyi0852'
  },
  {
    code: '0311',
    name: '赞皇',
    pinyin: 'Zanhuang',
    label: 'Zanhuang0311'
  },
  {
    code: '020',
    name: '增城',
    pinyin: 'Zengcheng',
    label: 'Zengcheng020'
  },
  {
    code: '0512',
    name: '张家港',
    pinyin: 'Zhangjiagang',
    label: 'Zhangjiagang0512'
  },
  {
    code: '0597',
    name: '漳平',
    pinyin: 'Zhangping',
    label: 'Zhangping0597'
  },
  {
    code: '0596',
    name: '漳浦',
    pinyin: 'Zhangpu',
    label: 'Zhangpu0596'
  },
  {
    code: '0531',
    name: '章丘',
    pinyin: 'Zhangqiu',
    label: 'Zhangqiu0531'
  },
  {
    code: '0795',
    name: '樟树',
    pinyin: 'Zhangshu',
    label: 'Zhangshu0795'
  },
  {
    code: '0543',
    name: '沾化',
    pinyin: 'Zhanhua',
    label: 'Zhanhua0543'
  },
  {
    code: '0311',
    name: '赵县',
    pinyin: 'Zhaoxian',
    label: 'Zhaoxian0311'
  },
  {
    code: '0535',
    name: '招远',
    pinyin: 'Zhaoyuan',
    label: 'Zhaoyuan0535'
  },
  {
    code: '0311',
    name: '正定',
    pinyin: 'Zhengding',
    label: 'Zhengding0311'
  },
  {
    code: '0599',
    name: '政和',
    pinyin: 'Zhenghe',
    label: 'Zhenghe0599'
  },
  {
    code: '0593',
    name: '柘荣',
    pinyin: 'Zherong',
    label: 'Zherong0593'
  },
  {
    code: '0371',
    name: '中牟',
    pinyin: 'Zhongmou',
    label: 'Zhongmou0371'
  },
  {
    code: '023',
    name: '忠县',
    pinyin: 'Zhongxian',
    label: 'Zhongxian023'
  },
  {
    code: '0593',
    name: '周宁',
    pinyin: 'Zhouning',
    label: 'Zhouning0593'
  },
  {
    code: '029',
    name: '周至',
    pinyin: 'Zhouzhi',
    label: 'Zhouzhi029'
  },
  {
    code: '0411',
    name: '庄河',
    pinyin: 'Zhuanghe',
    label: 'Zhuanghe0411'
  },
  {
    code: '0536',
    name: '诸城',
    pinyin: 'Zhucheng',
    label: 'Zhucheng0536'
  },
  {
    code: '0575',
    name: '诸暨',
    pinyin: 'Zhuji',
    label: 'Zhuji0575'
  },
  {
    code: '0762',
    name: '紫金',
    pinyin: 'Zijin',
    label: 'Zijin0762'
  },
  {
    code: '0794',
    name: '资溪',
    pinyin: 'Zixi',
    label: 'Zixi0794'
  },
  {
    code: '0537',
    name: '邹城',
    pinyin: 'Zoucheng',
    label: 'Zoucheng0537'
  },
  {
    code: '0543',
    name: '邹平',
    pinyin: 'Zouping',
    label: 'Zouping0543'
  },
  {
    code: '0315',
    name: '遵化',
    pinyin: 'Zunhua',
    label: 'Zunhua0315'
  }
]
