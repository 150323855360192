// admin
export const adminData = [
  {
    id: '2',
    title: '物资发放',
    icon: 'el-icon-truck',
    router: '/materialDistribution',
    active: require('@/assets/images/navMenu/9.svg'),
    inactive: require('@/assets/images/navMenu/8.svg')
  },
  {
    id: '3',
    title: '选手管理',
    icon: 'el-icon-user',
    router: '/playerManagement',
    active: require('@/assets/images/navMenu/7.svg'),
    inactive: require('@/assets/images/navMenu/6.svg')
  },
  {
    id: '4',
    title: '芯片管理',
    icon: 'el-icon-star-off',
    router: '/chipManagement',
    active: require('@/assets/images/navMenu/5.svg'),
    inactive: require('@/assets/images/navMenu/4.svg')
  },
  {
    id: '5',
    title: '赛事管理',
    icon: 'el-icon-setting',
    router: '/eventManagement',
    active: require('@/assets/images/navMenu/3.svg'),
    inactive: require('@/assets/images/navMenu/2.svg')
  },
  {
    id: '5',
    title: '设备管理',
    icon: 'el-icon-receiving',
    router: '/deviceManagement',
    active: require('@/assets/images/navMenu/13.svg'),
    inactive: require('@/assets/images/navMenu/12.svg')
  },
  {
    id: '5',
    title: '组别管理',
    icon: 'el-icon-coin',
    router: '/groupManagement',
    active: require('@/assets/images/navMenu/15.svg'),
    inactive: require('@/assets/images/navMenu/14.svg')
  },
  {
    id: '6',
    title: '账号管理',
    icon: 'el-icon-mouse',
    router: '/accountManagement',
    active: require('@/assets/images/navMenu/1.svg'),
    inactive: require('@/assets/images/navMenu/0.svg')
  },
  {
    id: '7',
    title: '预留号码',
    icon: 'el-icon-mouse',
    router: '/groupList',
    active: require('@/assets/images/navMenu/1.svg'),
    inactive: require('@/assets/images/navMenu/0.svg')
  }
]
// 主办方
export const SponsorData = [
  {
    id: '2',
    title: '物资发放',
    icon: 'el-icon-truck',
    router: '/materialDistribution',
    active: require('@/assets/images/navMenu/9.svg'),
    inactive: require('@/assets/images/navMenu/8.svg')
  },
  {
    id: '3',
    title: '选手管理',
    icon: 'el-icon-user',
    router: '/playerManagement',
    active: require('@/assets/images/navMenu/7.svg'),
    inactive: require('@/assets/images/navMenu/6.svg')
  },
  {
    id: '4',
    title: '芯片管理',
    icon: 'el-icon-star-off',
    router: '/chipManagement',
    active: require('@/assets/images/navMenu/5.svg'),
    inactive: require('@/assets/images/navMenu/4.svg')
  },
  {
    id: '7',
    title: '预留号码',
    icon: 'el-icon-mouse',
    router: '/groupList',
    active: require('@/assets/images/navMenu/1.svg'),
    inactive: require('@/assets/images/navMenu/0.svg')
  }
]
// 发包
export const contractOutData = [
  {
    id: '2',
    title: '物资发放',
    icon: 'el-icon-truck',
    router: '/materialDistribution',
    active: require('@/assets/images/navMenu/9.svg'),
    inactive: require('@/assets/images/navMenu/8.svg')
  }
]
