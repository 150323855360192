<template>
  <div class="playerManagement">
    <div class="event-title">
      <span>预留号码：</span>
    </div>
    <!-- 搜索 -->
    <el-row class="row-bg">
      <div class="playerManagement-search">
        <el-button type="primary" @click="reBack">返回</el-button>
        <span>
          <el-button type="primary" @click="add">新增号码</el-button>
          <el-button type="primary" @click="lookList">查看赛事队列</el-button>
          <!-- <el-button type="primary" @click="clearList">清空赛事队列</el-button> -->
        </span>
      </div>
    </el-row>
    <!-- table -->
    <el-card shadow="hover">
      <h3>号码列表</h3>
      <el-table
        :data="tableNumber"
        style="width: 100%"
        height="300"
        :header-cell-style="{ textAlign: 'center', background: '#EFF7FF', color: '#222222' }"
        :cell-style="{ textAlign: 'center', color: '#666666' }"
      >
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column prop="name" label="组别" show-overflow-tooltip></el-table-column>
        <el-table-column prop="value" label="号码" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" width="200px" fixed="right">
          <template #default="{row}">
            <span @click="handleDeleteEvent(row)" class="deleteStyle">删除</span>
          </template>
        </el-table-column>
      </el-table>
      <h3>号码段列表</h3>
      <el-table
        :data="tablePart"
        height="300"
        style="width: 100%"
        :header-cell-style="{ textAlign: 'center', background: '#EFF7FF', color: '#222222' }"
        :cell-style="{ textAlign: 'center', color: '#666666' }"
      >
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column prop="name" label="组别" show-overflow-tooltip></el-table-column>
        <el-table-column prop="value" label="号码段" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" width="200px" fixed="right">
          <template #default="{row}">
            <span @click="handleDeleteEvent(row)" class="deleteStyle">删除</span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <!-- <paging
          class="fixedRightBottom"
          :pageTotal="pageTotal"
          :currentPageSize="currentPageSize"
          @handle-current-change="handleCurrentChange"
        ></paging> -->
    </el-card>
    <!-- 新增赛事 -->
    <el-dialog title="新增号码" :close-on-click-modal="false" v-model="dialogVisible" width="690px" center>
      <el-form ref="ruleRef" :model="ruleForm" inline label-width="70px" class="demo-ruleForm">
        <h3>固定号码布</h3>
        <el-form-item
          label="号码"
          v-for="(seqNum, index) in ruleForm.seqNums"
          :key="seqNum.key"
          :prop="`seqNums[${index}].seqNum`"
          :rules="{
            required: true,
            validator: validNumber
          }"
        >
          <el-input style="width: 210px;" v-model.trim="seqNum.seqNum" placeholder="请输入号码" autocomplete="off">
            <template #append v-if="index !== 0">
              <el-button @click="del('seqNums', index)" icon="el-icon-delete"></el-button>
            </template>
          </el-input>
        </el-form-item>
        <p style="text-align: right;">
          <el-button @click="addNum" type="primary">增加号码</el-button>
        </p>
        <h3>号码段</h3>
        <el-form-item
          label="号码段"
          v-for="(seqSegment, index) in ruleForm.seqSegments"
          :key="seqSegment.key"
          :prop="`seqSegments[${index}].seqNum`"
          :rules="{
            required: true,
            type: 'array',
            validator: validPart
          }"
        >
          <!-- <el-input style="width: 180px;" v-model.trim="seqSegment.seqNum" placeholder="请输入号码段" autocomplete="off">
            <template v-if="index !== 0" #append>
              <el-button @click="del('seqSegments', index)" icon="el-icon-delete"></el-button>
            </template>
          </el-input> -->
          <el-input style="width: 88px;" v-model="seqSegment.seqNum[0]" :controls="false"> </el-input>
          -
          <el-input style="width: 88px;" v-model="seqSegment.seqNum[1]" :controls="false"> </el-input>
          <el-button v-if="index !== 0" @click="del('seqSegments', index)" icon="el-icon-delete" type="danger" style="margin-left: 5px;"></el-button>
          <span v-else style="padding: 5px 25px;"></span>
        </el-form-item>
        <p style="text-align: right;">
          <el-button @click="addPart" type="primary">增加号码</el-button>
        </p>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" plain @click="handleAddConfirm">确认</el-button>
          <el-button type="info" plain @click="resetForm">取消</el-button>
        </span>
      </template>
    </el-dialog>
    <matchList ref="matchListRef" />
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, ref, watch } from 'vue'
import matchList from './matchList.vue'
// import paging from '../paging.vue'
import api from '@/api/api'
import { ElMessageBox, ElMessage } from 'element-plus'
export default defineComponent({
  components: { matchList },
  emits: ['closeInfo'],
  setup(props, context) {
    const matchListRef = ref()
    // 号码
    const tableNumber = ref([])
    // 号码段
    const tablePart = ref([])
    const dialogVisible = ref(false)
    const groupId = ref()
    const groupName = ref('')
    const ruleRef = ref()
    const ruleForm = reactive({
      groupId: '',
      seqNums: [
        {
          key: Date.now(),
          seqNum: 0
        }
      ],
      seqSegments: [
        {
          key: Date.now(),
          seqNum: [0, 99]
        }
      ]
    })
    // 添加号码段
    const addPart = () => {
      ruleForm.seqSegments.push({
        key: Date.now(),
        seqNum: [0, 99]
      })
    }
    // 添加号码
    const addNum = () => {
      ruleForm.seqNums.push({
        key: Date.now(),
        seqNum: 0
      })
    }
    // 获取列表
    const getList = () => {
      api.reservedNumber.getList(groupId.value).then((res: any) => {
        if (res) {
          tableNumber.value = res.seqNums.map(seq => {
            return {
              name: groupName.value,
              value: seq,
              id: res._id
            }
          })
          tablePart.value = res.seqSegments.map(seq => {
            return {
              name: groupName.value,
              value: seq,
              id: res._id
            }
          })
        }
      })
    }
    // 号码校验
    const validNumber = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入号码'))
      } else {
        const indexs = ruleForm.seqNums.filter(item => Number(value) === Number(item.seqNum))
        if (indexs.length > 1) {
          callback(new Error('重复号码'))
        } else {
          callback()
        }
      }
    }
    // 号码段校验
    const validPart = (rule, value, callback) => {
      console.log('value', value)
      if ((!value[0] && value[0] !== 0) || (!value[1] && value[1] !== 0)) {
        callback(new Error('请输入完整号码段'))
      } else {
        const indexs = ruleForm.seqSegments.filter(item => `${value[0]}-${value[1]}` === `${item.seqNum[0]}-${item.seqNum[1]}`)
        if (indexs.length > 1) {
          callback(new Error('重复号码段'))
        } else {
          callback()
        }
      }
    }
    const open = group => {
      console.log('group', group)
      groupId.value = group.id
      groupName.value = group.name
      tableNumber.value = []
      tablePart.value = []
      getList()
    }
    const lookList = () => {
      ElMessageBox.confirm('是否确定查看号码队列', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        matchListRef.value.open(groupId.value)
      })
    }
    const clearList = () => {
      ElMessageBox.confirm('是否确定清空', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          api.reservedNumber.lookList(groupId.value).then(() => {
            ElMessage.success('清空成功')
          })
        })
        .catch(err => {
          ElMessage.error(err.errMsg)
        })
    }
    const add = () => {
      dialogVisible.value = true
      // resetForm()
    }
    const reBack = () => {
      context.emit('closeInfo')
    }
    // 新增时多个号码段删除
    const del = (type: string, index: number) => {
      ruleForm[type].splice(index, 1)
    }
    const handleDeleteEvent = (row: any) => {
      console.log('删除', row)
      ElMessageBox.confirm('是否确定删除该选手', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          // 这里请求删除api
          api.reservedNumber.del({ seqNumber: row.value, id: row.id }).then((res: any) => {
            console.log(res)
            ElMessage.success('删除成功')
            getList()
          })
        })
        .catch(() => {
          ElMessage('取消成功')
        })
    }
    const handleAddConfirm = () => {
      ruleRef.value.validate(valid => {
        if (valid) {
          const seqNums = ruleForm.seqNums.map(s => s.seqNum)
          const seqSegments = ruleForm.seqSegments.map(s => `${s.seqNum[0]}-${s.seqNum[1]}`)
          const params = {
            groupId: groupId.value as string,
            seqNums,
            seqSegments
          }
          console.log('params', params)
          api.reservedNumber
            .add(params)
            .then(() => {
              ElMessage.success('新增成功')
              dialogVisible.value = false
              getList()
            })
            .catch(e => {
              ElMessage.error(e.errMsg)
            })
        }
      })
    }
    watch(dialogVisible, nv => {
      if (!nv) {
        ruleForm.groupId = ''
        ruleForm.seqNums = [
          {
            key: Date.now(),
            seqNum: 0
          }
        ]
        ruleForm.seqSegments = [
          {
            key: Date.now(),
            seqNum: [0, 99]
          }
        ]
      }
    })
    const resetForm = () => {
      dialogVisible.value = false
    }
    return {
      tableNumber,
      tablePart,
      dialogVisible,
      ruleForm,
      ruleRef,
      matchListRef,
      clearList,
      lookList,
      validNumber,
      validPart,
      del,
      addPart,
      addNum,
      handleAddConfirm,
      resetForm,
      handleDeleteEvent,
      open,
      reBack,
      add
    }
  }
})
</script>
<style lang="scss" scoped>
.playerManagement {
  width: 100%;
  .event-title {
    font-size: 20px;
    font-weight: 600;
    color: #222222;
  }
  .row-bg {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    background-color: #fff;
    padding: 0 20px;
    margin-top: 15px;
    box-shadow: 0px 0.5px 10px #ddd;
    .playerManagement-search {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    .el-button {
      margin-left: 10px;
    }
  }
}
:deep(.box-inner-width) {
  width: 178px !important;
}
.into-part {
  margin-bottom: 0px;
}
.gate-card {
  :deep(.el-card__header) {
    font-size: 14px;
    padding: 10px;
  }
}
.dialog-footer {
  .el-button {
    margin-left: 40px;
  }
}
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-card {
  min-height: 750px;
  position: relative;
  .el-table {
    color: #909399;
    font-size: 14px;
  }
  .fixedRightBottom {
    position: absolute;
    bottom: 15px;
    right: 15px;
  }
}
.editStyle {
  color: #2c88fe;
  margin-right: 20px;
  cursor: pointer;
}
.deleteStyle {
  color: #f24b53;
  cursor: pointer;
}
.gate-group {
  display: flex;
}
:deep(.el-dialog__header) {
  padding: 20px 20px 15px;
  background-color: #eff7ff;
}
</style>
