
import { defineComponent, ref, onMounted, watch, unref } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import paging from '../paging.vue'
import api from '@/api/api'
import { FormValidate } from '@/utils/format'
import { eventTypeNames } from '@/utils/enum'
export default defineComponent({
  components: { paging },
  setup() {
    const eventGroupName = ref()
    const tableData = ref()
    // 新增
    const newGroupDialog = ref(false)
    const addEventGroupParams = ref({
      type: '',
      groupName: '',
      sort: 0
    })
    const ruleRef = ref(null)
    const eventsEditDialog = ref(false)
    const eventEditForm: any = ref({
      type: '',
      groupId: '',
      groupName: '',
      sort: 0
    })
    // 总条数
    const pageTotal = ref(100)
    // 当前页数
    const currentPage = ref(1)
    // 当前页数条数
    const currentPageSize = ref(15)
    // 表单验证
    const rules = ref({
      groupName: [{ trigger: 'blur', required: true, validator: FormValidate.FormOne().NoEmpty }],
      type: [{ trigger: 'blur', required: true, message: '请选择赛事类型' }]
    })
    const addAccountNumber = () => {
      newGroupDialog.value = true
    }
    const handleAddConfirm = async () => {
      const form: any = unref(ruleRef)
      if (!form) return
      try {
        await form.validate()
        api.eventGroup
          .addEventGroup(addEventGroupParams.value)
          .then((res: any) => {
            if (res) {
              ElMessage.success('创建成功')
              newGroupDialog.value = false
              getEventGroupsList()
            }
          })
          .catch(err => {
            ElMessage.error(err.errMsg)
          })
      } catch (error: any) {
        ElMessage.error(error.err.Msg)
      }
    }
    // 取消并且清除数据
    const handleCancelForm = () => {
      const form: any = unref(ruleRef)
      form.resetFields()
      newGroupDialog.value = false
      eventsEditDialog.value = false
    }
    onMounted(() => {
      getEventGroupsList()
    })
    // 删除
    const handleDeleteEventGroup = (row: any) => {
      ElMessageBox.confirm('是否确定删除该组别', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          // 这里请求删除api
          api.eventGroup.deleteEventGroup({ groupId: row.id }).then(res => {
            if (res) {
              ElMessage.success('删除成功')
              getEventGroupsList()
            }
          })
        })
        .catch(() => {
          ElMessage('取消成功')
        })
    }
    const resetForm = () => {
      const form: any = unref(ruleRef)
      form.resetFields()
      newGroupDialog.value = false
    }
    const handleEdit = (row: any) => {
      eventsEditDialog.value = true
      eventEditForm.value.groupId = row.id
      eventEditForm.value.groupName = row.name
      eventEditForm.value.type = row.type
    }
    const handleEditConfirm = async () => {
      const form: any = unref(ruleRef)
      if (!form) return
      try {
        await form.validate()
        api.eventGroup
          .editEventGroup(eventEditForm.value)
          .then((res: any) => {
            if (res) {
              ElMessage.success('修改成功')
              eventsEditDialog.value = false
              getEventGroupsList()
            }
          })
          .catch(err => {
            ElMessage.error(err.errMsg)
          })
      } catch (error:any) {
        ElMessage.error(error.errMsg)
      }
    }
    const handleEditCancelForm = () => {
      eventsEditDialog.value = false
    }

    // 分页
    const handleCurrentChange = (val: any) => {
      currentPage.value = val
      getEventGroupsList()
    }
    // 搜索
    const handleGetInputSearchValue = () => {
      getEventGroupsList()
    }
    // 监听数据是否为空
    watch(
      eventGroupName,
      nv => {
        if (nv === '') {
          eventGroupName.value = ' '
          getEventGroupsList()
        }
      },
      { deep: true, immediate: true }
    )

    // 获取组别列表
    const getEventGroupsList = () => {
      api.eventGroup
        .getEventGroupList({
          name: encodeURIComponent(eventGroupName.value === undefined ? ' ' : eventGroupName.value),
          page: currentPage.value,
          size: currentPageSize.value
        })
        .then((res: any) => {
          tableData.value = res.groups
          pageTotal.value = res.pages.total
        })
        .catch(err => {
          ElMessage.error(err.errMsg)
        })
    }
    // 序列号自增
    const tableIndex = index => {
      return (currentPage.value - 1) * currentPageSize.value + index + 1
    }
    return {
      eventGroupName,
      tableData,
      eventTypeNames,
      addAccountNumber,
      handleEdit,
      handleCancelForm,

      handleDeleteEventGroup,
      handleEditCancelForm,

      pageTotal,
      currentPage,
      currentPageSize,
      handleCurrentChange,
      handleGetInputSearchValue,

      eventsEditDialog,
      eventEditForm,
      handleEditConfirm,

      //  新增
      newGroupDialog,
      addEventGroupParams,
      handleAddConfirm,
      rules,
      ruleRef,
      resetForm,
      tableIndex
    }
  }
})
