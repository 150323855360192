<template>
  <el-container>
    <div class="playerManagement">
      <div class="event-title">
        <span>赛事管理</span>
      </div>
      <!-- 搜索 -->
      <el-row class="row-bg">
        <div class="playerManagement-search">
          <el-input placeholder="请输入赛事名称" v-model.trim="eventName"></el-input>
          <el-button type="primary" @click="handleGetInputSearchValue">搜索</el-button>
        </div>
        <el-button @click="addAccountNumber">新增</el-button>
      </el-row>
      <!-- table -->
      <el-card shadow="hover">
        <el-table
          :data="tableData"
          style="width: 100%"
          :header-cell-style="{ textAlign: 'center', background: '#EFF7FF', color: '#222222' }"
          :cell-style="{ textAlign: 'center', color: '#666666' }"
        >
          <el-table-column type="index" :index="tableIndex" label="序号"></el-table-column>
          <el-table-column prop="name" label="赛事名称" width="200" show-overflow-tooltip></el-table-column>
          <el-table-column prop="organizers" label="举办方" width="100" show-overflow-tooltip></el-table-column>
          <el-table-column prop="venue" label="举办地" width="150" show-overflow-tooltip></el-table-column>
          <el-table-column prop="time" width="135" label="举办时间"></el-table-column>
          <el-table-column prop="operation" label="领物方式" width="150"></el-table-column>
          <el-table-column prop="note" label="闸机类型" width="150">
            <template #default="scope">
              {{ scope.row.note === 0 ? '窗口' : scope.row.note === 1 ? '号码布' : '窗口+号码布' }}
            </template>
          </el-table-column>
          <el-table-column prop="enventsGroup" label="赛事组别" :show-overflow-tooltip="true">
            <template #default="scope">
              <span v-for="item in scope.row.enventsGroup" :key="item.groupId">
                <span v-if="item">{{ item.groupName + ';' }}</span>
                <span v-if="item.groupName === ''">{{ item.groupName }}</span>
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="recognitionRate" label="识别准确率" width="150"></el-table-column>
          <el-table-column prop="createdAt" width="135" label="创建时间"></el-table-column>
          <el-table-column prop="creator" label="创建人" width="100"></el-table-column>
          <el-table-column label="操作" width="100px" fixed="right">
            <template #default="scope">
              <span @click="handleEdit(scope.row)" class="editStyle">编辑</span>
              <span @click="handleDeleteEvent(scope.row)" class="deleteStyle">删除</span>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <paging
          class="fixedRightBottom"
          :pageTotal="pageTotal"
          :currentPageSize="currentPageSize"
          @handle-current-change="handleCurrentChange"
        ></paging>
      </el-card>
      <!-- 新增赛事 -->
      <el-dialog title="新增赛事" :close-on-click-modal="false" v-model="newEventsDialogVisible" width="700px" align-center @close="resetForm" top="5vh">
        <el-form ref="ruleRef" :rules="rules" :model="ruleForm" inline label-width="100px" class="demo-ruleForm">
          <el-form-item label="赛事Id" prop="eventsId">
            <el-input v-model.trim="ruleForm.eventsId" placeholder="请输入赛事Id" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="saas赛事Id" prop="faceMatchId">
            <el-input-number style="width: 100%;text-align: left;" class="input-number-custom" v-model="ruleForm.faceMatchId" type="number" :controls="false" placeholder="请输入saas赛事Id" autocomplete="off"></el-input-number>
          </el-form-item>
          <el-form-item label="赛事名称" prop="name">
            <el-input v-model.trim="ruleForm.name" placeholder="请输入赛事名称" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="举办方" prop="organizers">
            <el-input v-model.trim="ruleForm.organizers" placeholder="请输入举办方" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="举办地" prop="venue">
            <el-input v-model.trim="ruleForm.venue" placeholder="请输入举办地"></el-input>
          </el-form-item>
          <el-form-item label="举办时间" prop="time">
            <el-date-picker
              class="box-inner-width"
              v-model="ruleForm.time"
              type="datetime"
              placeholder="选择日期时间"
              @change="handleTimeValue"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="领物方式" prop="operation">
            <el-select class="box-inner-width" v-model="ruleForm.operation" placeholder="请选择领物方式">
              <el-option v-for="item in selectOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="闸机类型" prop="note">
            <el-select class="box-inner-width" v-model="ruleForm.note" placeholder="请选择闸机类型">
              <el-option label="窗口" :value="0"></el-option>
              <el-option label="号码布" :value="1"></el-option>
              <el-option label="窗口+号码布" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <template v-if="ruleForm.operation === '闸机分配号码布' && ruleForm.gates && ruleForm.gates.length">
            <el-card class="gate-card" shadow="never" :body-style="{ padding: '8px' }" style="min-height: 100px;margin-bottom: 18px;">
              <template #header>
                <div class="card-header">
                  <span>闸机分配号码布选项</span>
                </div>
              </template>
              <div v-for="(gate, key) in ruleForm.gates" :key="key" class="gate-group">
                <el-form-item
                  label="组别"
                  :prop="`gates[${key}].group`"
                  label-width="58px"
                  :rules="{
                    required: true,
                    message: '不能为空',
                    trigger: 'blur'
                  }"
                >
                  <el-select style="width: 150px;" filterable v-model="ruleForm.gates[key].group" placeholder="请选择">
                    <el-option v-for="item in eventGroupsList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="号码段标识"
                  label-width="100px"
                  :prop="`gates[${key}].numFlag`"
                  :rules="{
                    required: true,
                    message: '不能为空',
                    trigger: 'blur'
                  }"
                >
                  <el-input style="width: 80px;" v-model="ruleForm.gates[key].numFlag"></el-input>
                </el-form-item>
                <el-form-item
                  label="号码段"
                  label-width="65px"
                  :prop="`gates[${key}].numPartA`"
                  :rules="{
                    required: true,
                    message: '不能为空',
                    trigger: 'blur'
                  }"
                >
                  <el-input style="width: 80px;" v-model="ruleForm.gates[key].numPartA"></el-input>
                </el-form-item>
                <!-- <el-form-item
                  label="号码段"
                  label-width="65px"
                  :prop="`gates[${key}].numPartA`"
                  :rules="{
                    required: true,
                    message: '',
                    trigger: 'blur'
                  }"
                >
                  <el-form-item
                    label=""
                    label-width="0px"
                    class="into-part"
                    :prop="`gates[${key}].numPartA`"
                    :rules="{
                      required: true,
                      message: '不能为空',
                      trigger: 'blur'
                    }"
                  >
                    <el-input style="width: 70px;" v-model="ruleForm.gates[key].numPartA"></el-input>
                  </el-form-item>
                  至 &nbsp;
                  <el-form-item
                    label=""
                    class="into-part"
                    label-width="0px"
                    :prop="`gates[${key}].numPartB`"
                    :rules="{
                      required: true,
                      message: '不能为空',
                      trigger: 'blur'
                    }"
                  >
                    <el-input style="width: 70px;" v-model="ruleForm.gates[key].numPartB"></el-input>
                  </el-form-item>
                </el-form-item> -->
                <el-tooltip v-if="key !== 0" class="item" effect="dark" content="删除" placement="top-start">
                  <el-button @click="delGate(key)" type="text" style="margin-bottom: 18px;" icon="el-icon-close"></el-button>
                </el-tooltip>
              </div>
              <el-button @click="addGate">新增</el-button>
            </el-card>
          </template>
          <el-form-item label="赛事类型" prop="type" :filterable="true">
            <el-select
              v-model="ruleForm.type"
              class="box-inner-width"
              allow-create
              default-first-option
              placeholder="请选择赛事类型"
              @change="ruleForm.group = []"
            >
              <el-option v-for="(item, key) in eventTypeNames" :key="key" :label="item" :value="key"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="赛事组别" prop="group" :filterable="true">
            <el-select v-model="ruleForm.group" class="box-inner-width" multiple allow-create default-first-option placeholder="请选择赛事组别">
              <el-option v-for="item in addGroupsList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="识别方式" prop="recognition">
            <el-select v-model="ruleForm.recognition" class="box-inner-width" placeholder="请选择识别方式">
              <el-option v-for="item in IdentificationMode" :key="item.id" :label="item.value" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="识别准确率" prop="recognitionRate">
            <el-input v-model.trim="ruleForm.recognitionRate" placeholder="请输入识别准确率"></el-input>
          </el-form-item>
          <el-form-item label="校验人脸" prop="isCheckFace">
            <el-select v-model="ruleForm.isCheckFace" class="box-inner-width" placeholder="请选择识别方式">
              <el-option label="是" :value="true"></el-option>
              <el-option label="否" :value="false"></el-option>
            </el-select>
          </el-form-item>
          <p>设备绑定</p>
          <!-- <el-form-item label="设备绑定"> -->
          <el-form-item label="闸机设备" prop="afc">
            <el-select v-model="ruleForm.afc" class="box-inner-width" multiple allow-create default-first-option placeholder="请选择闸机设备">
              <el-option v-for="item in newGateEquipmentList" :key="item.seq" :label="item.name" :value="item.seq"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="领物设备" prop="rec">
            <el-select v-model="ruleForm.rec" class="box-inner-width" multiple allow-create default-first-option placeholder="请选择领物设备">
              <el-option v-for="item in newPickingEquipmentList" :key="item.seq" :label="item.name" :value="item.seq"></el-option>
            </el-select>
          </el-form-item>
          <!-- </el-form-item> -->
          <p>背景图配置</p>
          <el-form-item label="上传图片" prop="background">
            <uploadBg :uploadLength="1" :defaultList="defaultList" :onUploadSuccess="onUploadSuccess" :onRemove="onRemove" />
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="primary" plain @click="handleAddConfirm">确认</el-button>
            <el-button type="info" plain @click="resetForm">取消</el-button>
          </span>
        </template>
      </el-dialog>
      <!-- 编辑 -->
      <el-dialog title="编辑赛事" :close-on-click-modal="false" v-model="eventsEditDialog" width="700px" center @closed="resetForm">
        <el-form status-icon ref="ruleRef" :rules="rules" :model="eventEditForm" inline label-width="100px" class="demo-ruleForm">
          <el-form-item label="赛事名称" prop="name">
            <el-input v-model.trim="eventEditForm.name" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="saas赛事Id" prop="faceMatchId">
            <el-input v-model.trim="eventEditForm.faceMatchId" placeholder="请输入saas赛事Id" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="举办方" prop="organizers">
            <el-input v-model.trim="eventEditForm.organizers" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="举办地" prop="venue">
            <el-input v-model.trim="eventEditForm.venue"></el-input>
          </el-form-item>
          <el-form-item label="举办时间" prop="time">
            <el-date-picker
              v-model="eventEditForm.time"
              class="box-inner-width"
              type="datetime"
              placeholder="选择日期时间"
              @change="handleEditValue"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="领物方式" prop="operation">
            <el-select v-model="eventEditForm.operation" class="box-inner-width" placeholder="请选择领物方式">
              <el-option v-for="item in selectOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="闸机类型" prop="note">
            <el-select class="box-inner-width" v-model="eventEditForm.note" placeholder="请选择闸机类型">
              <el-option label="窗口" :value="0"></el-option>
              <el-option label="号码布" :value="1"></el-option>
              <el-option label="窗口+号码布" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <template v-if="eventEditForm.operation === '闸机分配号码布' && eventEditForm.gates && eventEditForm.gates.length">
            <el-card class="gate-card" shadow="never" :body-style="{ padding: '8px' }" style="min-height: 100px;margin-bottom: 18px;">
              <template #header>
                <div class="card-header">
                  <span>闸机分配号码布选项</span>
                </div>
              </template>
              <div v-for="(gate, key) in eventEditForm.gates" :key="key" class="gate-group">
                <el-form-item
                  label="组别"
                  :prop="`gates[${key}].group`"
                  label-width="58px"
                  :rules="{
                    required: true,
                    message: '不能为空',
                    trigger: 'blur'
                  }"
                >
                  <el-select style="width: 150px;" v-model="eventEditForm.gates[key].group" placeholder="请选择">
                    <el-option v-for="item in selectOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="号码段标识"
                  label-width="100px"
                  :prop="`gates[${key}].numFlag`"
                  :rules="{
                    required: true,
                    message: '不能为空',
                    trigger: 'blur'
                  }"
                >
                  <el-input style="width: 80px;" v-model="eventEditForm.gates[key].numFlag"></el-input>
                </el-form-item>
                <el-form-item
                  label="号码段"
                  label-width="65px"
                  :prop="`gates[${key}].numPartA`"
                  :rules="{
                    required: true,
                    message: '不能为空',
                    trigger: 'blur'
                  }"
                >
                  <el-input style="width: 80px;" v-model="eventEditForm.gates[key].numPartA"></el-input>
                </el-form-item>
                <el-tooltip v-if="key !== 0" class="item" effect="dark" content="删除" placement="top-start">
                  <el-button @click="delGate(key)" type="text" style="margin-bottom: 18px;" icon="el-icon-close"></el-button>
                </el-tooltip>
              </div>
              <el-button @click="addGate">新增</el-button>
            </el-card>
          </template>
          <el-form-item label="赛事类型" prop="type" :filterable="true">
            <el-select
              v-model="eventEditForm.type"
              class="box-inner-width"
              allow-create
              default-first-option
              placeholder="请选择赛事类型"
              @change="eventEditForm.group = []"
            >
              <el-option v-for="(item, key) in eventTypeNames" :key="key" :label="item" :value="key"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="赛事组别" prop="group">
            <el-select v-model="eventEditForm.group" multiple allow-create class="box-inner-width" default-first-option placeholder="请选择赛事组别">
              <el-option v-for="item in editGroupsList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="识别方式" prop="recognition">
            <el-select v-model="eventEditForm.recognition" class="box-inner-width" placeholder="请选择识别方式">
              <el-option v-for="item in IdentificationMode" :key="item.id" :label="item.value" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="识别准确率" prop="recognitionRate">
            <el-input v-model.trim="eventEditForm.recognitionRate" placeholder="请输入识别准确率"></el-input>
          </el-form-item>
          <el-form-item label="校验人脸" prop="isCheckFace">
            <el-select v-model="eventEditForm.isCheckFace" class="box-inner-width" placeholder="请选择识别方式">
              <el-option label="是" :value="true"></el-option>
              <el-option label="否" :value="false"></el-option>
            </el-select>
          </el-form-item>
          <p>设备绑定</p>
          <!-- <el-form-item label="设备绑定" prop="afc"> -->
          <el-form-item label="闸机设备" prop="afc">
            <el-select v-model="eventEditForm.afc" class="box-inner-width" multiple allow-create default-first-option placeholder="请选择闸机设备">
              <el-option v-for="item in gateEquipmentList" :key="item.seq" :label="item.name" :value="item.seq"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="领物设备" prop="rec">
            <el-select v-model="eventEditForm.rec" class="box-inner-width" multiple allow-create default-first-option placeholder="请选择领物设备">
              <el-option v-for="item in pickingEquipmentList" :key="item.seq" :label="item.name" :value="item.seq"></el-option>
            </el-select>
          </el-form-item>
          <!-- </el-form-item> -->
          <p>背景图配置</p>
          <el-form-item label="领物设备" prop="rec">
            <uploadBg :uploadLength="1" :defaultList="defaultList" :onUploadSuccess="onUploadSuccess" :onRemove="onRemove" />
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="primary" plain @click="handleEditConfirm">确认</el-button>
            <el-button type="info" plain @click="resetForm">取消</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </el-container>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, watch, unref, computed } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { eventTypeNames } from '@/utils/enum'
import paging from '../paging.vue'
import { useOptions } from './events-hooks/sel-options'
import api from '@/api/api'
import { FormValidate } from '@/utils/format'
import useGroupList from './common-hooks/all-groups'
import uploadBg from './upload.vue'

export default defineComponent({
  components: { paging, uploadBg },
  setup() {
    const eventName = ref()
    const tableData = ref()
    const ruleRef = ref(null)
    const newEventsDialogVisible = ref(false)
    const gateEquipmentList: any = ref([])
    const gateEquipmentSelectValue = ref([])
    const newGateEquipmentList: any = ref([])
    const newPickingEquipmentList: any = ref([])
    const { eventGroupsList } = useGroupList()
    const pickingEquipmentList: any = ref([])
    const pickingEquipmentSelectValue = ref([])
    const eventsEditDialog = ref(false)
    const eventEditForm: any = ref({})
    const pageTotal = ref(100)
    const currentPage = ref(1)
    const currentPageSize = ref(15)
    const { IdentificationMode, selectOptions } = useOptions()
    const defaultList: any = ref([])
    const rules = ref({
      eventsId: [{ trigger: 'blur', required: true, validator: FormValidate.Form().EventsId }],
      name: [{ trigger: 'blur', required: true, message: '请输入赛事名称' }],
      faceMatchId: [{ trigger: 'blur', required: true, message: '请输入saas赛事id' }],
      time: [{ type: 'date', required: true, message: '请选择日期', trigger: 'change' }],
      type: [{ type: 'string', required: true, message: '请选择赛事类型' }],
      group: [{ type: 'array', required: true, message: '请选择分组' }],
      organizers: [{ trigger: 'blur', required: true, message: '请输入举办方' }],
      venue: [{ trigger: 'blur', required: true, message: '请输入举办地' }],
      recognition: [{ trigger: 'change', required: true, message: '请选择识别方式' }],
      isCheckFace: [{ trigger: 'change', required: true, message: '请选择' }],
      operation: [{ trigger: 'change', required: true, message: '请选择领物方式' }],
      note: [{ trigger: 'change', required: true, message: '请选择闸机类型' }],
      recognitionRate: [{ trigger: 'change', required: true, validator: FormValidate.Form().Accuracy }]
    })
    // 新增赛事
    const ruleForm: any = ref({
      eventsId: '',
      faceMatchId: '',
      name: '',
      organizers: '',
      venue: '',
      time: '',
      type: '',
      group: [],
      gates: [{ group: '', numFlag: '', numPartA: '', numPartB: '' }],
      operation: '',
      note: '',
      background: '',
      recognition: '',
      recognitionRate: 0.6,
      isCheckFace: true,
      afc: [],
      rec: []
    })

    // 上传图片成功
    const onUploadSuccess = info => {
      ruleForm.value.background = info
      eventEditForm.value.background = info
    }

    // 清空上传图片
    const onRemove = () => {
      ruleForm.value.background = ''
      eventEditForm.value.background = ''
      defaultList.value = []
    }

    // 举办时间转换为时间戳
    const handleTimeValue = () => {
      ruleForm.value.time = ruleForm.value.time.getTime()
    }
    // 新增按钮
    const addAccountNumber = () => {
      getDeliverySelect()
      newEventsDialogVisible.value = true
    }
    onMounted(() => {
      getEventList()
    })
    const addGroupsList = computed(() => {
      const groups = eventGroupsList.value.filter(e => e.type === ruleForm.value.type)
      return groups
    })
    const editGroupsList = computed(() => {
      const groups = eventGroupsList.value.filter(e => e.type === eventEditForm.value.type)
      return groups
    })
    // 新增设备绑定
    const getDeliverySelect = () => {
      api.device
        .getDeviceSelect()
        .then(res => {
          newGateEquipmentList.value = res
          newPickingEquipmentList.value = res
        })
        .catch(err => {
          ElMessage.error(err.errMsg)
        })
    }
    const addNumRule = () => {
      const params = ruleForm.value.gates.map(gate => {
        return {
          groupId: gate.group,
          fixNumber: gate.numFlag,
          startNumber: gate.numPartA
        }
      })
      return api.event.addRule(params)
    }
    // 新增提交api
    const handleAddConfirm = async () => {
      if (ruleForm.value.operation === '闸机分配号码布') {
        addNumRule()
          .then(() => {
            creatGate()
            resetForm()
          })
          .catch(err => {
            ElMessage.error(err.errMsg || '新增号码布规则失败')
          })
      } else {
        creatGate()
      }
    }
    // c创建赛事
    const creatGate = async () => {
      const form: any = unref(ruleRef)
      if (!form) return
      try {
        await form.validate()
      } catch (error) {
        if (error) {
          return false
        }
      }
      ruleForm.value.time = new Date(ruleForm.value.time).getTime()
      ruleForm.value.recognitionRate = Number(ruleForm.value.recognitionRate)
      api.event
        .addEvent(ruleForm.value)
        .then(res => {
          if (res) {
            ElMessage.success('新增成功')
            newEventsDialogVisible.value = false
            getEventList()
          }
        })
        .catch(err => {
          ElMessage.error(err.errMsg)
        })
    }
    // 删除
    const handleDeleteEvent = (row: any) => {
      ElMessageBox.confirm('是否确定删除该赛事', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          // 这里请求删除api
          if (row.afc.length === 0 && row.rec.length === 0) {
            api.event
              .deleteEvent({ eventsId: row.id })
              .then((res: any) => {
                if (!res) {
                  ElMessage.success('删除成功')
                  getEventList()
                }
              })
              .catch(err => {
                ElMessage.error(err.errMsg)
              })
          } else {
            ElMessage.error('该赛事绑定有设备,请先解绑设备后再删除赛事')
          }
        })
        .catch(() => {
          ElMessage('取消成功')
        })
    }
    const resetForm = () => {
      const form: any = unref(ruleRef)
      form.resetFields()
      ruleForm.value.operation = ''
      ruleForm.value.note = ''
      ruleForm.value.background = ''
      eventEditForm.value.background = ''
      defaultList.value = []
      eventsEditDialog.value = false
      newEventsDialogVisible.value = false
    }
    // 编辑
    const handleEditValue = async (val: any) => {
      eventEditForm.value.time = val.getTime()
    }
    const handleEdit = (row: any) => {
      api.device
        .getDeviceSelect()
        .then((res: any) => {
          // 编辑设备
          gateEquipmentList.value = res.concat(row.afc)
          pickingEquipmentList.value = res.concat(row.rec)
        })
        .catch(err => {
          ElMessage.error(err.errMsg)
        })
      const getGroupsName: any = ref([])
      row.enventsGroup.forEach((item: any) => {
        getGroupsName.value.push(item.groupId)
      })
      const getGroupsNameGetAfcName: any = ref([])
      row.afc.forEach((item: any) => {
        getGroupsNameGetAfcName.value.push(item.seq)
      })
      const getGroupsNameGetRecName: any = ref([])
      row.rec.forEach((item: any) => {
        getGroupsNameGetRecName.value.push(item.seq)
      })
      eventsEditDialog.value = true
      eventEditForm.value.id = row.id
      eventEditForm.value.faceMatchId = row.faceMatchId
      eventEditForm.value.name = row.name
      eventEditForm.value.organizers = row.organizers
      eventEditForm.value.venue = row.venue
      eventEditForm.value.time = row.time
      eventEditForm.value.operation = row.operation
      eventEditForm.value.note = row.note
      eventEditForm.value.group = getGroupsName.value
      eventEditForm.value.type = row.type
      eventEditForm.value.recognition = row.recognition
      eventEditForm.value.isCheckFace = row.isCheckFace || false
      eventEditForm.value.recognitionRate = row.recognitionRate
      eventEditForm.value.afc = getGroupsNameGetAfcName.value
      eventEditForm.value.rec = getGroupsNameGetRecName.value
      eventEditForm.value.background = row.background
      defaultList.value = row.background ? [{ url: row.background }] : []

      if (row.recognition === '闸机分配号码布') {
        eventEditForm.value.gates = row.gates
      }
    }
    const handleEditConfirm = async () => {
      const form: any = unref(ruleRef)
      if (!form) return
      const resultValue: any = ref(false)
      try {
        await form.validate()
        eventEditForm.value.time = new Date(eventEditForm.value.time).getTime()
        eventEditForm.value.recognitionRate = Number(eventEditForm.value.recognitionRate)
        eventEditForm.value.afc.forEach((item: any) => {
          resultValue.value = eventEditForm.value.rec.some((item1: any) => {
            return item === item1
          })
        })
        if (resultValue.value) {
          ElMessage.error('不能同时绑定两种设备')
        } else {
          api.event
            .eventEdit(eventEditForm.value)
            .then((res: any) => {
              if (res) {
                ElMessage.success('编辑成功')
                eventsEditDialog.value = false
                getEventList()
              }
            })
            .catch(err => {
              ElMessage.error(err.errMsg)
            })
        }
      } catch (error) {
        console.log(error)
      }
    }
    const handleCurrentChange = (val: any) => {
      currentPage.value = val
      getEventList()
    }
    // 搜索
    const handleGetInputSearchValue = () => {
      getEventList()
    }
    // 监听数据是否为空
    watch(
      eventName,
      nv => {
        if (nv === '') {
          eventName.value = ' '
          getEventList()
        }
      },
      { deep: true, immediate: true }
    )
    // 获取赛事列表
    const getEventList = () => {
      api.event
        .eventList({
          name: encodeURIComponent(eventName.value === undefined ? ' ' : eventName.value),
          page: currentPage.value,
          size: currentPageSize.value
        })
        .then((res: any) => {
          tableData.value = res.events
          pageTotal.value = res.pages.total
        })
        .catch(err => {
          ElMessage.error(err.errMsg)
        })
    }
    // 新增闸机簿
    const addGate = () => {
      ruleForm.value.gates.push({ group: '', numFlag: '', numPartA: '', numPartB: '' })
    }
    const delGate = key => {
      ruleForm.value.gates.splice(key, 1)
    }
    // 序列号自增
    const tableIndex = index => {
      return (currentPage.value - 1) * currentPageSize.value + index + 1
    }
    return {
      onRemove,
      defaultList,
      onUploadSuccess,

      eventName,
      tableData,
      eventTypeNames,
      addGroupsList,
      editGroupsList,

      addAccountNumber,
      handleAddConfirm,
      handleEdit,
      handleTimeValue,
      IdentificationMode,

      // 闸机
      gateEquipmentList,
      gateEquipmentSelectValue,

      // 领物
      pickingEquipmentList,
      pickingEquipmentSelectValue,

      newEventsDialogVisible,
      selectOptions,
      eventGroupsList,

      newGateEquipmentList,
      newPickingEquipmentList,
      ruleForm,
      handleDeleteEvent,

      pageTotal,
      currentPage,
      currentPageSize,
      handleCurrentChange,
      handleGetInputSearchValue,

      eventsEditDialog,
      eventEditForm,
      handleEditConfirm,
      handleEditValue,
      addGate,
      delGate,
      ruleRef,
      rules,
      resetForm,
      tableIndex
    }
  }
})
</script>
<style lang="scss" scoped>
.playerManagement {
  width: 100%;
  .event-title {
    font-size: 20px;
    font-weight: 600;
    color: #222222;
  }
  .row-bg {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 90px;
    background-color: #fff;
    padding: 0 20px;
    margin-top: 15px;
    box-shadow: 0px 0.5px 10px #ddd;
    .playerManagement-search {
      display: flex;
      height: 20px;
    }
    .el-button {
      margin-left: 10px;
    }
  }
}
:deep(.box-inner-width) {
  width: 100% !important;
}
:deep(.el-form-item--mini){
  width: 48%;
}
:deep(.el-form-item__error){
  padding-top: 4px;
}
:deep(.el-form-item--mini.el-form-item, .el-form-item--small.el-form-item){
  margin-bottom: 25px;
}
.input-number-custom {
  :deep(.el-input__inner){
    text-align: left;
  }
}
.into-part {
  margin-bottom: 0px;
}
.gate-card {
  :deep(.el-card__header) {
    font-size: 14px;
    padding: 10px;
  }
}
.dialog-footer {
  .el-button {
    margin-left: 40px;
  }
}
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-card {
  min-height: 750px;
  position: relative;
  .el-table {
    color: #909399;
    font-size: 14px;
  }
  .fixedRightBottom {
    position: absolute;
    bottom: 15px;
    right: 15px;
  }
}
.editStyle {
  color: #2c88fe;
  margin-right: 20px;
  cursor: pointer;
}
.deleteStyle {
  color: #f24b53;
  cursor: pointer;
}
.gate-group {
  display: flex;
}
:deep(.el-dialog__header) {
  background-color: #eff7ff;
}
</style>
