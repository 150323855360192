<template>
  <el-dialog :title="`号牌发放队列（总数：${count}）`" v-model="show" width="500" top="10vh" center>
    <el-table
      :data="tableNumber"
      style="width: 100%"
      height="600"
      :header-cell-style="{ textAlign: 'center', background: '#EFF7FF', color: '#222222' }"
      :cell-style="{ textAlign: 'center', color: '#666666' }"
    >
      <el-table-column type="index" label="序号"></el-table-column>
      <!-- <el-table-column prop="name" label="组别" show-overflow-tooltip></el-table-column> -->
      <el-table-column prop="value" label="号码" show-overflow-tooltip></el-table-column>
      <!-- <el-table-column label="操作" width="200px" fixed="right">
          <template #default="{row}">
            <span @click="handleDeleteEvent(row)" class="deleteStyle">删除</span>
          </template>
        </el-table-column> -->
    </el-table>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="info" plain @click="show = false">关闭</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue'
import api from '@/api/api'
import { ElMessage } from 'element-plus'
export default defineComponent({
  setup() {
    const tableNumber = ref([])
    const show = ref(false)
    const count = ref(0)
    const isLoading = ref(false)
    const open = groupId => {
      show.value = true
      tableNumber.value = []
      count.value = 0
      setTimeout(() => {
        isLoading.value = true
      }, 1000)
      api.reservedNumber
        .lookList(groupId)
        .then((res: any) => {
          tableNumber.value = res.list.map(item => {
            return {
              value: item
            }
          })
          count.value = res.count
          console.log('list', res)
          isLoading.value = false
        })
        .catch(err => {
          isLoading.value = false
          ElMessage.error(err.errMsg)
        })
    }
    return {
      tableNumber,
      show,
      open,
      count,
      isLoading
    }
  }
})
</script>
<style scoped></style>
