import common from '@/common/common'
import router, { routerNames } from '@/router'
import CryptoJS from 'crypto-js'
import { createStore, Commit } from 'vuex'
export interface SessionState {
  token: string,
  name: string,
  roleId: string,
  role: string,
  events: string,
  eventsId: string,
  deviceId: string,
  operation: string,
  faceMatchId: number,
  isCheckFace: boolean,
}
export default createStore({
  state: (): SessionState => ({
    token: '',
    name: '',
    roleId: '',
    role: '',
    events: '',
    eventsId: '',
    faceMatchId: 0,
    isCheckFace: true,
    deviceId: '',
    operation: ''
  }),
  getters: {
    hasLogin (state: SessionState) {
      return (key: string): boolean => {
        return window.sessionStorage.getItem(key) !== null ||
        state.token !== ''
      }
    },
    isValid (state: SessionState): boolean {
      return state.name !== '' && state.token !== ''
    }
  },
  mutations: {
    setSession (state: SessionState, session: SessionState): void {
      state.token = session.token
      state.name = session.name
      state.roleId = session.roleId
      state.role = session.role
      state.events = session.events
      state.eventsId = session.eventsId
      state.faceMatchId = session.faceMatchId
      state.deviceId = session.deviceId
      state.operation = session.operation
      state.isCheckFace = session.isCheckFace
    },
    cleanSession (state: SessionState): void {
      state.token = ''
      state.name = ''
    }
  },
  actions: {
    login (context: { commit: Commit }, payload: {session: SessionState, keySysAdminSession: string}): void {
      context.commit('setSession', payload.session)
      window.sessionStorage
        .setItem(payload.keySysAdminSession, CryptoJS.AES.encrypt(JSON.stringify(payload.session), common.secret).toString())
    },
    logout (context: { commit: Commit }, payload: {keySysAdminSession: string}): void {
      context.commit('cleanSession')
      window.sessionStorage.removeItem(payload.keySysAdminSession)
      router.push({ name: routerNames.Login })
    },

    clear (context: {commit: Commit}, payload: {keySysAdminSession: string}): void {
      context.commit('cleanSession')
      window.sessionStorage.removeItem(payload.keySysAdminSession)
    },

    restoreSessionFromLocalStorage (context: { commit: Commit }, payload: {keySysAdminSession: string}): void {
      const encryptedSession: string | null = sessionStorage.getItem(payload.keySysAdminSession)
      const bytes = CryptoJS.AES.decrypt(encryptedSession as string, common.secret)
      const session = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
      context.commit('setSession', session)
    }
  },
  modules: {
  }
})
