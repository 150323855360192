<template>
  <el-container>
    <div class="playerManagement" ref="getElContainer">
      <div class="playerManagement-title">
        <div>
          <span style="font-size:21px; color:#000; font-weight:600">{{ whoImportsName }}</span
          >/导入EXCEL
        </div>
      </div>
      <!-- 搜索 -->
      <el-row class="row-bg">
        <div class="upload-left">
          <a href="javascript:;" class="a-upload">
            <input type="file" ref="refFile" name="file" id="files" @change="importCsv" />选择解析文件
          </a>
          <span class="a-upload-text" v-if="fileName"
            >{{ fileName }} <i style="color:#67C23A;margin-left:30px" class="el-icon-circle-check"></i
          ></span>
        </div>
        <el-button plain type="primary" @click="handleClickBack">返回</el-button>
      </el-row>
      <div class="elCheckboxGroup" v-if="isShowTable">
        <el-checkbox-group v-model="checkedCities">
          <el-checkbox
            :disabled="city.id == 'chipNo2' ? false : city.isChecked"
            v-for="city in paramsValue"
            :label="city.value"
            :key="city.id"
            style="margin: 0;"
            >{{ city.value }}</el-checkbox
          >
        </el-checkbox-group>
        <div class="uploadCsv">
          <el-button plain v-if="isShowTable" size="medium" type="primary" @click="handleFileChange">导入文件和字段</el-button>
        </div>
      </div>
    </div>
    <el-dialog title="错误提示" v-model="errorConfirmation" width="460px" :show-close="false" center :before-close="handleCloseCallback">
      <p>
        总条数：<span style="color: #409EFF">{{ errData.total }}</span>
      </p>
      <p>
        成功：<span style="color: #67C23A">{{ errData.success }}</span>
      </p>
      <p>
        失败：<span style="color: #F56C6C">{{ errData.fail }}</span>
      </p>
      <div v-for="item in errData.errRows" :key="item.index">
        <span style="color: #E6A23C">{{ item.index > 0 ? `第${item.index}行：` : '' }}{{ item.errMsg }}</span>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button size="medium" @click="handleErrorConfirmation">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </el-container>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue'
import XLSX from 'xlsx'
import router from '@/router'
import api from '@/api/api'
import { ElMessage, ElLoading } from 'element-plus'

export default defineComponent({
  setup() {
    const refFile = ref()
    const fileName = ref()
    const isShowTable = ref(false)
    const paramsValue = ref()
    const formData = ref()
    const errorConfirmation = ref(false)
    // 选择的文件流
    const selectedFile: any = ref(null)
    const errData: any = ref({
      total: 0,
      success: 0,
      fail: 0,
      errRows: []
    })
    // 获取局部loading元素
    const getElContainer: any = ref(HTMLInputElement)
    // 选手和芯片上传前对比的数据
    const numberNoValueList: any = ref([])
    const checkedCities: any = ref([])
    // 上传CSv
    // 判断字段是否相等的函数
    const FilterData = (a: any, b: string[]) => {
      const result: any = []
      for (var i = 0; i < a.length; i++) {
        if (b.indexOf(a[i]) === -1) {
          result.push(a[i])
        }
      }
      return result
    }
    const errorMsg = (val: any) => {
      ElMessage.error({
        message: `解析失败，该（${val}）字段有误，请修改重新上传`,
        type: 'error',
        duration: 4000
      })
    }
    const importCsv = async (e: any) => {
      selectedFile.value = refFile.value.files[0]
      const result = refFile.value.files[0] && refFile.value.files[0].name.split('.')
      const suffix = result && result[result.length - 1]
      const loadingInstance = ElLoading.service({ target: getElContainer.value, text: '正在解析文件...' })
      if (suffix === 'xlsx' || suffix === 'xls') {
        const reader = new FileReader()
        let zzExcel: any = null
        reader.readAsBinaryString(selectedFile.value)
        reader.onload = (e: any) => {
          const data: any = e.target.result
          zzExcel = XLSX.read(data, {
            type: 'binary'
          })
          // const xclData: string[] = []
          // const sheet2JSONOpts = {
          //   defval: '',
          //   headers: 1
          // }
          // console.log('zzExcel.value.Sheets[zzExcel.value.SheetNames]', zzExcel.value.Sheets[zzExcel.value.SheetNames])
          // const headers: any = XLSX.utils.sheet_to_json(zzExcel.Sheets[zzExcel.SheetNames[0]], sheet2JSONOpts)[0]
          const headers = getHeaderRow(zzExcel.Sheets[zzExcel.SheetNames[0]])
          // console.log('headers', headers)
          // for (const key in headers) {
          //   xclData.push(key)
          // }
          const result = FilterData(numberNoValueList.value, headers)
          console.log(result)
          // console.log(result, xclData, 'xclData')
          if (result.length !== 0) {
            setTimeout(() => {
              errorMsg(result)
              loadingInstance.close()
              isShowTable.value = false
              fileName.value = ''
            }, 200)
            return false
          }
          if (headers) {
            checkedCities.value = headers
            fileName.value = selectedFile.value.name
            setTimeout(() => {
              loadingInstance.close()
            }, 200)
            isShowTable.value = true
          }
        }
      } else {
        setTimeout(() => {
          loadingInstance.close()
        }, 200)
        ElMessage.error('解析文件格式有误，请重新上传解析')
      }
      e.target.value = ''
    }
    const getHeaderRow = sheet => {
      const headers: any[] = [] // 定义数组，用于存放解析好的数据
      const range = XLSX.utils.decode_range(sheet['!ref']) // 读取sheet的单元格数据
      let C
      const R = range.s.r
      /* start in the first row */
      for (C = range.s.c; C <= range.e.c; ++C) {
        /* walk every column in the range */
        const cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })]
        /* find the cell in the first row */
        let hdr: any = 'UNKNOWN ' + C // <-- replace with your desired default
        if (cell && cell.t) {
          hdr = XLSX.utils.format_cell(cell)
          headers.push(hdr)
        }
      }
      return headers // 经过上方一波操作遍历，得到最终的第一行头数据
    }
    const chipPlayerFiltering = (val: any) => {
      for (const item in val) {
        numberNoValueList.value.push(val[item].value)
      }
    }
    // 判断是选手还是芯片
    const whoImportsName = ref()
    watch(
      () => router.currentRoute.value.params.chipPlayerId,
      () => {
        if (router.currentRoute.value.params.chipPlayerId === 'chip') {
          whoImportsName.value = '芯片管理'
          paramsValue.value = [
            { id: 'chipNo', value: '芯片号', isChecked: true },
            { id: 'numberPlate', value: '号码布', isChecked: true },
            { id: 'events', value: '赛事', isChecked: true },
            { id: 'chipNo2', value: '芯片号2', isChecked: true },
            { id: 'group', value: '组别', isChecked: false },
            { id: 'sex', value: '性别', isChecked: false }
          ]
          chipPlayerFiltering(paramsValue.value)
        } else if (router.currentRoute.value.params.chipPlayerId === 'player') {
          whoImportsName.value = '选手管理'
          paramsValue.value = [
            { id: 'name', value: '姓名', isChecked: true },
            { id: 'sex', value: '性别', isChecked: true },
            { id: 'age', value: '年龄', isChecked: true },
            { id: 'identityType', value: '证件类型', isChecked: true },
            { id: 'cardNum', value: '证件号码', isChecked: true },
            { id: 'group', value: '组别', isChecked: true },
            { id: 'groupEnId', value: '团报ID' },
            { id: 'status', value: '领物状态', isChecked: true },
            { id: 'phone', value: '手机号码', isChecked: true },
            { id: 'events', value: '赛事', isChecked: true },
            { id: 'city', value: '城市', isChecked: true },
            { id: 'numberPlate', value: '号码布' },
            { id: 'window', value: '领物窗口' },
            { id: 'bagStorage', value: '存包区' },
            { id: 'clothesSize', value: '衣服尺码' },
            { id: 'bagType', value: '参赛包类型' },
            { id: 'remark', value: '备注' }
          ]
          chipPlayerFiltering(paramsValue.value)
        }
      },
      { deep: true, immediate: true }
    )
    // 返回上一页
    const handleClickBack = () => {
      router.go(-1)
    }
    const handleFileChange = () => {
      const emptyArray: any = ref([])
      checkedCities.value.forEach((item: any) => {
        switch (item) {
          case '姓名':
            return emptyArray.value.push({ name: item })
          case '性别':
            return emptyArray.value.push({ sex: item })
          case '年龄':
            return emptyArray.value.push({ age: item })
          case '证件类型':
            return emptyArray.value.push({ identityType: item })
          case '证件号码':
            return emptyArray.value.push({ cardNum: item })
          case '组别':
            return emptyArray.value.push({ group: item })
          case '团报ID':
            return emptyArray.value.push({ groupEnId: item })
          case '手机号码':
            return emptyArray.value.push({ phone: item })
          case '领物窗口':
            return emptyArray.value.push({ window: item })
          case '存包区':
            return emptyArray.value.push({ bagStorage: item })
          case '衣服尺码':
            return emptyArray.value.push({ clothesSize: item })
          case '参赛包类型':
            return emptyArray.value.push({ bagType: item })
          case '备注':
            return emptyArray.value.push({ remark: item })
          case '领物状态':
            return emptyArray.value.push({ status: item })
          case '赛事':
            return emptyArray.value.push({ events: item })
          case '城市':
            return emptyArray.value.push({ city: item })
          case '号码布':
            return emptyArray.value.push({ numberPlate: item })
          case '芯片号':
            return emptyArray.value.push({ chipNo: item })
          case '芯片号2':
            return emptyArray.value.push({ chipNo2: item })
          default:
            break
        }
      })
      const loadingInstance = ElLoading.service({ target: getElContainer.value, text: '正在导入文件...' })
      formData.value = new FormData()
      formData.value.append('file', selectedFile.value)
      formData.value.append('field', JSON.stringify(emptyArray.value))
      if (router.currentRoute.value.params.chipPlayerId === 'chip') {
        api.chip
          .chipUploadFile(formData.value)
          .then(res => {
            if (res) {
              ElMessage.success('上传成功')
              router.go(-1)
              loadingInstance.close()
            }
          })
          .catch(err => {
            loadingInstance.close()
            errorConfirmation.value = true
            const errorData = JSON.parse(err.errMsg)
            errData.value.total = errorData.total
            errData.value.success = errorData.success
            errData.value.fail = errorData.fail
            errData.value.errRows = errorData.errRows
          })
      } else if (router.currentRoute.value.params.chipPlayerId === 'player') {
        api.player
          .playerUploadFile(formData.value)
          .then(res => {
            if (res) {
              ElMessage.success('上传成功')
              router.go(-1)
              loadingInstance.close()
            }
          })
          .catch(err => {
            loadingInstance.close()
            errorConfirmation.value = true
            const errorData = JSON.parse(err.errMsg)
            errData.value.total = errorData.total
            errData.value.success = errorData.success
            errData.value.fail = errorData.fail
            errData.value.errRows = errorData.errRows
          })
      }
    }
    const handleErrorConfirmation = () => {
      errorConfirmation.value = false
      router.go(-1)
    }
    // 关闭之前的回调
    const handleCloseCallback = () => {
      errorConfirmation.value = false
      router.go(-1)
    }
    return {
      importCsv,
      refFile,
      handleClickBack,
      paramsValue,
      fileName,
      handleFileChange,
      isShowTable,
      errorConfirmation,
      errData,
      handleErrorConfirmation,
      checkedCities,
      handleCloseCallback,
      getElContainer,
      whoImportsName
    }
  }
})
</script>
<style lang="scss" scoped>
.playerManagement {
  width: 100%;
  .playerManagement-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
  }
  .row-bg {
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    align-items: center;
    background: #fff;
    padding: 0 20px;
    margin-top: 15px;
    box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
    .upload-left {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 700px;
    }
  }
}
.a-upload {
  padding: 5px 10px;
  height: 20px;
  position: relative;
  cursor: pointer;
  color: #409eff;
  background: #ecf5ff;
  border: 1px solid #b3d8ff;
  border-radius: 4px;
  overflow: hidden;
  display: inline-block;
  *display: inline;
  *zoom: 1;
  text-decoration: none;
}
.a-upload input {
  position: absolute;
  font-size: 100px;
  right: 0;
  top: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  cursor: pointer;
}
.a-upload:hover {
  background: #409eff;
  border-color: #409eff;
  color: #fff;
}
.a-upload-text {
  // margin-left: 20px;
  width: 570px;
}
.uploadCsv {
  text-align: center;
  margin: 50px;
}
.elCheckboxGroup {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: #fff;
  box-sizing: border-box;
  margin-top: 20px;
  .el-checkbox-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    width: 350px;
    height: 440px;
    border: 1px solid rgb(0 21 41 / 8%);
    box-sizing: border-box;
    padding: 20px;
    margin: 20px;
    border-radius: 10px;
    .el-checkbox {
      width: 100px;
    }
  }
}
</style>
