
import { defineComponent, ref, watch } from 'vue'
import appHeader from '@/common/components/appHeader.vue'
import { adminData, SponsorData, contractOutData } from '@/utils/sideData'
import { useStore } from 'vuex'
import router from '@/router'
import { useRoute } from 'vue-router'
export default defineComponent({
  components: { appHeader },
  setup () {
    const isCollapse = ref(false)
    const routerPath = ref()
    watch(router.currentRoute.value, () => {
      routerPath.value = router.currentRoute.value.fullPath
    }, { deep: true, immediate: true })
    const route = useRoute()
    watch(() => route.path, () => {
      routerPath.value = route.path
    })
    const store = useStore()
    // 默认是admin权限
    const sideNav = ref(adminData)
    // 获取store得觉得进行相应的菜单权限展示
    if (store.state.roleId === '60fab62af7fefc45ffa1790a') {
      sideNav.value = adminData
    } else if (store.state.roleId === '60fab63af7fefc45ffa1790c') {
      sideNav.value = SponsorData
    } else {
      sideNav.value = contractOutData
    }
    const showSidebar = (val: any) => {
      isCollapse.value = val
    }
    return {
      sideNav,
      routerPath,
      isCollapse,
      showSidebar
    }
  }
})
