
import { defineComponent, reactive, ref, watch } from 'vue'
import matchList from './matchList.vue'
// import paging from '../paging.vue'
import api from '@/api/api'
import { ElMessageBox, ElMessage } from 'element-plus'
export default defineComponent({
  components: { matchList },
  emits: ['closeInfo'],
  setup(props, context) {
    const matchListRef = ref()
    // 号码
    const tableNumber = ref([])
    // 号码段
    const tablePart = ref([])
    const dialogVisible = ref(false)
    const groupId = ref()
    const groupName = ref('')
    const ruleRef = ref()
    const ruleForm = reactive({
      groupId: '',
      seqNums: [
        {
          key: Date.now(),
          seqNum: 0
        }
      ],
      seqSegments: [
        {
          key: Date.now(),
          seqNum: [0, 99]
        }
      ]
    })
    // 添加号码段
    const addPart = () => {
      ruleForm.seqSegments.push({
        key: Date.now(),
        seqNum: [0, 99]
      })
    }
    // 添加号码
    const addNum = () => {
      ruleForm.seqNums.push({
        key: Date.now(),
        seqNum: 0
      })
    }
    // 获取列表
    const getList = () => {
      api.reservedNumber.getList(groupId.value).then((res: any) => {
        if (res) {
          tableNumber.value = res.seqNums.map(seq => {
            return {
              name: groupName.value,
              value: seq,
              id: res._id
            }
          })
          tablePart.value = res.seqSegments.map(seq => {
            return {
              name: groupName.value,
              value: seq,
              id: res._id
            }
          })
        }
      })
    }
    // 号码校验
    const validNumber = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入号码'))
      } else {
        const indexs = ruleForm.seqNums.filter(item => Number(value) === Number(item.seqNum))
        if (indexs.length > 1) {
          callback(new Error('重复号码'))
        } else {
          callback()
        }
      }
    }
    // 号码段校验
    const validPart = (rule, value, callback) => {
      console.log('value', value)
      if ((!value[0] && value[0] !== 0) || (!value[1] && value[1] !== 0)) {
        callback(new Error('请输入完整号码段'))
      } else {
        const indexs = ruleForm.seqSegments.filter(item => `${value[0]}-${value[1]}` === `${item.seqNum[0]}-${item.seqNum[1]}`)
        if (indexs.length > 1) {
          callback(new Error('重复号码段'))
        } else {
          callback()
        }
      }
    }
    const open = group => {
      console.log('group', group)
      groupId.value = group.id
      groupName.value = group.name
      tableNumber.value = []
      tablePart.value = []
      getList()
    }
    const lookList = () => {
      ElMessageBox.confirm('是否确定查看号码队列', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        matchListRef.value.open(groupId.value)
      })
    }
    const clearList = () => {
      ElMessageBox.confirm('是否确定清空', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          api.reservedNumber.lookList(groupId.value).then(() => {
            ElMessage.success('清空成功')
          })
        })
        .catch(err => {
          ElMessage.error(err.errMsg)
        })
    }
    const add = () => {
      dialogVisible.value = true
      // resetForm()
    }
    const reBack = () => {
      context.emit('closeInfo')
    }
    // 新增时多个号码段删除
    const del = (type: string, index: number) => {
      ruleForm[type].splice(index, 1)
    }
    const handleDeleteEvent = (row: any) => {
      console.log('删除', row)
      ElMessageBox.confirm('是否确定删除该选手', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          // 这里请求删除api
          api.reservedNumber.del({ seqNumber: row.value, id: row.id }).then((res: any) => {
            console.log(res)
            ElMessage.success('删除成功')
            getList()
          })
        })
        .catch(() => {
          ElMessage('取消成功')
        })
    }
    const handleAddConfirm = () => {
      ruleRef.value.validate(valid => {
        if (valid) {
          const seqNums = ruleForm.seqNums.map(s => s.seqNum)
          const seqSegments = ruleForm.seqSegments.map(s => `${s.seqNum[0]}-${s.seqNum[1]}`)
          const params = {
            groupId: groupId.value as string,
            seqNums,
            seqSegments
          }
          console.log('params', params)
          api.reservedNumber
            .add(params)
            .then(() => {
              ElMessage.success('新增成功')
              dialogVisible.value = false
              getList()
            })
            .catch(e => {
              ElMessage.error(e.errMsg)
            })
        }
      })
    }
    watch(dialogVisible, nv => {
      if (!nv) {
        ruleForm.groupId = ''
        ruleForm.seqNums = [
          {
            key: Date.now(),
            seqNum: 0
          }
        ]
        ruleForm.seqSegments = [
          {
            key: Date.now(),
            seqNum: [0, 99]
          }
        ]
      }
    })
    const resetForm = () => {
      dialogVisible.value = false
    }
    return {
      tableNumber,
      tablePart,
      dialogVisible,
      ruleForm,
      ruleRef,
      matchListRef,
      clearList,
      lookList,
      validNumber,
      validPart,
      del,
      addPart,
      addNum,
      handleAddConfirm,
      resetForm,
      handleDeleteEvent,
      open,
      reBack,
      add
    }
  }
})
