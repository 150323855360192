
import { defineComponent, nextTick, onMounted, ref, watch, computed } from 'vue'
import api from '@/api/api'
import { useStore } from 'vuex'
import { ElLoading, ElMessage, ElMessageBox, ElTag } from 'element-plus'
import { socket, connectSocket } from '@/utils/socket'
import { FaceAxios } from '@/http/index'
export default defineComponent({
  components: { ElTag },
  setup() {
    const store = useStore()
    const searchRef = ref()
    const pickingType = store.state.operation
    const title = ref('')
    title.value = store.state.events
    const tempVal = ref()
    const isSHowInfo = ref(false)
    const playerInformationValue: any = ref({
      name: '',
      sex: '',
      cardNum: '',
      group: '',
      phone: '',
      numberPlate: '',
      window: '',
      clothesSize: '',
      bagType: '',
      identityType: '',
      bagStorage: '',
      status: ''
    })
    const documentType = ref()
    const sexType = ref()
    const claimStatus = ref()
    const faceStatus = ref('未录入')
    const changeSearchValue = ref('')
    const errMsg = ref('')
    const errorConfirmation = ref(false)
    const isBound = computed(() => !!playerInformationValue.value.numberPlate)
    onMounted(() => {
      handleSearchButton()
      init()
      searchRef.value.focus()
    })
    watch(changeSearchValue, newVal => {
      if (newVal.length === 18) {
        handleSearchButton()
      }
      // 扫码出来的身份证号多个 '
      if (newVal?.includes("'")) {
        changeSearchValue.value = newVal.replace("'", '')
      }
    })
    watch(isSHowInfo, newVal => {
      if (!newVal && searchRef.value) {
        searchRef.value.focus()
      }
    })
    watch(playerInformationValue, val => {
      // console.log('val号码薄', val.numberPlate)
      tempVal.value = val.numberPlate
    })
    const identityType = ref()
    const cardNum = ref()
    // 获取焦点定义的变量
    const numberPlateFocus: any = ref(null as HTMLInputElement | null)
    const chipFocus: any = ref(null as HTMLInputElement | null)
    // 芯片号
    const chipNo = ref()
    const numChipNo = ref()
    // 使用change事件只触发一次获取数据
    const typeValue = ref('password')
    const numTypeValue = ref('password')
    // 搜索
    const isNumberPlate = ref('')
    const isNumberPlates = ref(false)
    const isNumberPlatesValue = ref('')
    const contrast = ref(false)
    const isContrast = ref(false)
    // 获取局部loading元素
    const getElContainer: any = ref(HTMLInputElement)
    const handleSearchButton = () => {
      if (changeSearchValue.value === '') {
        return false
      }
      isNumberPlatesValue.value = ''
      tempVal.value = ''
      isNumberPlates.value = false
      contrast.value = false
      isContrast.value = false
      api.delivery
        .getDeliveryList(encodeURIComponent(changeSearchValue.value.replace('x', 'X')))
        .then(async (res: any) => {
          // 进来就获取焦点，但是不应该在搜索里面是socket返回数据的时候获取焦点
          const identityTypeS: any = ref()
          switch (res.identityType) {
            case 1:
              identityTypeS.value = '身份证'
              break
            case 2:
              identityTypeS.value = '护照'
              break
            case 3:
              identityTypeS.value = '港澳居民来往内地通行证'
              break
            case 4:
              identityTypeS.value = '台湾居民来往大陆通行证'
              break
            case 5:
              identityTypeS.value = '军官证'
              break
            default:
              break
          }
          documentType.value = identityTypeS.value
          sexType.value = res.sex === 1 ? '男' : '女'
          claimStatus.value = res.status === false ? '未领取' : '已领取'
          playerInformationValue.value = await res
          identityType.value = res.identityType
          isNumberPlate.value = res.numberPlate
          cardNum.value = res.cardNum
          isSHowInfo.value = true
          await nextTick(() => {
            if (res.numberPlate) {
              chipFocus.value && chipFocus.value.focus()
            } else {
              numberPlateFocus.value && numberPlateFocus.value.focus()
            }
            numTypeValue.value = res.numberPlate ? 'text' : 'password'
            typeValue.value = 'password'
          })
          checkFacePic(res.identityType)
        }).catch(err => {
          ElMessage.error(err.errMsg)
        })
    }
    const checkFacePic = (it) => {
      FaceAxios.post('/api/face/identify/search', {
        card_type: it,
        card_sn: changeSearchValue.value.replace('x', 'X'),
        saas_match_id: store.state.faceMatchId
      }).then(res => {
        if (res.data.data?.face_pic) {
          faceStatus.value = '已录入'
        } else {
          faceStatus.value = '未录入'
          if (store.state.isCheckFace) {
            ElMessageBox.confirm('选手未录入人脸', '警告', {
              confirmButtonText: '继续',
              showCancelButton: false,
              type: 'warning'
            })
              .then(() => {
                console.log('继续')
              })
              .catch(() => {
                // catch error
              })
          }
        }
      })
    }
    // socket实时获取后端返回的信息弹框
    const init = () => {
      // 监听服务端数据
      socket.on('delivery', async (cid: any) => {
        await nextTick(() => {
          typeValue.value = 'password'
          numTypeValue.value = 'password'
          chipFocus.value && chipFocus.value.focus()
        })
        isSHowInfo.value = true
        // 存在号码布存在禁用输入框禁用保存
        if (cid.numberPlate) {
          nextTick(() => {
            typeValue.value = 'password'
            numTypeValue.value = 'password'
            chipFocus.value && chipFocus.value.focus()
          })
          isNumberPlatesValue.value = ''
          isNumberPlates.value = false
          contrast.value = false
          isContrast.value = false
          isNumberPlate.value = cid.numberPlate
          const identityTypeS: any = ref()
          switch (cid.identityType) {
            case 1:
              identityTypeS.value = '身份证'
              break
            case 2:
              identityTypeS.value = '护照'
              break
            case 3:
              identityTypeS.value = '港澳居民来往内地通行证'
              break
            case 4:
              identityTypeS.value = '台湾居民来往大陆通行证'
              break
            case 5:
              identityTypeS.value = '军官证'
              break
            default:
              break
          }
          documentType.value = identityTypeS.value
          sexType.value = cid.sex === 1 ? '男' : '女'
          claimStatus.value = cid.status === false ? '未领取' : '已领取'
          playerInformationValue.value = cid
          identityType.value = cid.identityType
          cardNum.value = cid.cardNum
        } else {
          tempVal.value = ''
          isNumberPlate.value = cid.numberPlate
          await nextTick(() => {
            chipFocus.value && chipFocus.value.focus()
          })
          const identityTypeS: any = ref()
          switch (cid.identityType) {
            case 1:
              identityTypeS.value = '身份证'
              break
            case 2:
              identityTypeS.value = '护照'
              break
            case 3:
              identityTypeS.value = '港澳居民来往内地通行证'
              break
            case 4:
              identityTypeS.value = '台湾居民来往大陆通行证'
              break
            case 5:
              identityTypeS.value = '军官证'
              break
            default:
              break
          }
          documentType.value = identityTypeS.value
          sexType.value = cid.sex === 1 ? '男' : '女'
          claimStatus.value = cid.status === false ? '未领取' : '已领取'
          playerInformationValue.value = cid
          identityType.value = cid.identityType
          cardNum.value = cid.cardNum
          await nextTick(() => {
            chipFocus.value && chipFocus.value.focus()
          })
        }
      })
    }
    // 验证号码薄
    const handleCheckNumber = val => {
      if (val === '') {
        return false
      }
      chipNo.value = val
      api.delivery
        .chipOn(val)
        .then(async (res: any) => {
          isNumberPlatesValue.value = JSON.parse(JSON.stringify(res))
          isNumberPlates.value = true
          typeValue.value = 'text'
          chipFocus.value && chipFocus.value.blur()
          // console.log('校验号码布返回信息====', playerInformationValue.value.numberPlate, res)
          if (playerInformationValue.value.numberPlate === res) {
            isContrast.value = true
            contrast.value = true
          } else {
            isContrast.value = true
            contrast.value = false
          }
        })
        .catch(err => {
          ElMessage.error(err.errMsg)
        })
    }
    // 修改号码布
    const handleInput = (val: any) => {
      if (val === '') {
        return false
      }
      numChipNo.value = val
      api.delivery
        .chipOn(val)
        .then(async (res: any) => {
          tempVal.value = JSON.parse(JSON.stringify(res))
          numTypeValue.value = 'text'
          // typeValue.value = 'text'
          numberPlateFocus.value && numberPlateFocus.value.blur()
        })
        .catch(err => {
          ElMessage.error(err.errMsg)
        })
    }
    // 保存传递芯片号和号码布
    const handleConfirmButton = async () => {
      if (!tempVal.value) {
        ElMessage.warning('请输入号码布！')
        numberPlateFocus.value && numberPlateFocus.value.focus()
        return
      }
      const loadingInstance = ElLoading.service({ target: getElContainer.value })
      const confirmParams = {
        identityType: identityType.value,
        cardNum: cardNum.value,
        numberPlate: tempVal.value,
        chipNo: numChipNo.value ? numChipNo.value : ''
      }
      await nextTick(() => {
        setTimeout(() => {
          api.delivery
            .bindingInfo(confirmParams)
            .then(async (resp: any) => {
              console.log('res', resp)
              const code = resp.code
              if (code === 0) {
                // documentType.value = ''
                // sexType.value = ''
                // claimStatus.value = ''
                // playerInformationValue.value = ''
                // changeSearchValue.value = ''
                playerInformationValue.value.numberPlate = tempVal.value
                loadingInstance.close()
                ElMessage.success('绑定成功')
                claimStatus.value = '已领取'
                isSHowInfo.value = false
              } else if (code === 1) {
                loadingInstance.close()
                errMsg.value = '该号码布已绑定用户'
                errorConfirmation.value = true
              } else if (code === 2) {
                loadingInstance.close()
                errMsg.value = '该用户已绑定号码布'
                errorConfirmation.value = true
              } else if (code === 3) {
                loadingInstance.close()
                errMsg.value = '该用户不存在'
                errorConfirmation.value = true
              } else if (code === 4) {
                loadingInstance.close()
                errMsg.value = '未找到对应芯片号与号码布'
                errorConfirmation.value = true
              } else if (code === 5) {
                loadingInstance.close()
                errMsg.value = '获取芯片号失败'
                errorConfirmation.value = true
              } else if (code === 6) {
                loadingInstance.close()
                errMsg.value = '其它失败'
                errorConfirmation.value = true
              } else if (code === 9) {
                loadingInstance.close()
                errMsg.value = resp.message
                errorConfirmation.value = true
              } else if (code === 10) {
                loadingInstance.close()
                errMsg.value = '当前号码布与选手组别不一致，请检查选手小票是否为当前窗口'
                errorConfirmation.value = true
              } else if (code === 11) {
                loadingInstance.close()
                errMsg.value = '当前号码布与选手性别不一致，请检查选手小票是否为当前窗口'
                errorConfirmation.value = true
              }
            })
            .catch(err => {
              loadingInstance.close()
              errMsg.value = err.errMsg
              errorConfirmation.value = true
            })
        }, 1000)
      })
    }
    watch(
      () => tempVal.value,
      () => {
        if (tempVal.value === '') {
          numTypeValue.value = 'password'
        }
      },
      { deep: true, immediate: true }
    )

    // 判断是否有绑定芯片号过程 有的话直接显示按钮进行绑定  没有则禁止保存按钮
    const handleCancel = () => {
      changeSearchValue.value = ''
      isSHowInfo.value = false
    }
    const handleErrorConfirmation = async () => {
      errorConfirmation.value = false
      await nextTick(() => {
        chipFocus.value && chipFocus.value.focus()
      })
    }
    // 是否链接socket
    const getDeviceId = store.state.deviceId
    const eventsId = store.state.eventsId
    // if (getDeviceId) {
    //   if (process.env.NODE_ENV === 'development') {
    //     // 仅开发模式可用
    //     connectSocket()
    //   } else if (process.env.NODE_ENV === 'production') {
    //     connectSocket()
    //   }
    // }
    if (getDeviceId) {
      // 仅开发模式可用
      connectSocket()
    }
    return {
      tempVal,
      pickingType,
      isBound,
      playerInformationValue,
      handleConfirmButton,
      title,
      changeSearchValue,
      searchRef,
      handleSearchButton,
      numChipNo,
      documentType,
      sexType,
      claimStatus,
      faceStatus,
      isSHowInfo,
      numberPlateFocus,
      chipFocus,
      handleCancel,
      handleCheckNumber,
      handleInput,
      typeValue,
      numTypeValue,
      eventsId,
      errMsg,
      errorConfirmation,
      handleErrorConfirmation,
      isNumberPlate,
      isNumberPlates,
      isNumberPlatesValue,
      getElContainer,
      contrast,
      isContrast
    }
  }
})
