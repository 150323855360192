import { ref, Ref } from 'vue'
import api from '@/api/api'
import { ElMessage } from 'element-plus'
import { EventType } from '@/utils/enum'
interface EventsList {
  eventsId: string
  events: string
  type: EventType
}
export default function(): {bindEvents: Ref<EventsList[]>} {
  const bindEvents = ref<EventsList[]>([])
  const getBindEvents = () => {
    api.player
      .getBindEvents()
      .then(res => {
        bindEvents.value = res || []
        console.log(res, 'res')
      })
      .catch(err => {
        ElMessage.error(err.message)
      })
  }
  getBindEvents()
  return { bindEvents }
}
