
/* eslint-disable */
import { defineComponent, ref, onMounted, watch, unref, reactive, nextTick, computed } from 'vue'
import { ElMessage, ElMessageBox, ElLoading, ElTable } from 'element-plus'
import useClipboard from 'vue-clipboard3'
import paging from '../paging.vue'
import api from '@/api/api'
import router, { routerNames } from '@/router'
import axios from 'axios'
import { useStore } from 'vuex'
import { FormValidate } from '@/utils/format'
import { cityData } from '@/utils/city'
import PrintContainer from './print/print-template.vue'
import Print from './print/print'
import useBindEvent from './player-hooks/event-list'
import { EventType } from '@/utils/enum'
import useGroupList from './common-hooks/all-groups'
export interface PrintData {
  title: string
  name: string
  sex: string
  age: number
  remark?: string
  event: string
  size: string
  code: string
  wNum: string
}
export default defineComponent({
  components: { paging, PrintContainer },
  setup() {
    const printContainerRef = ref<HTMLElement | null>(null)
    const printInstance = ref()
    const cityDatas = ref(cityData)
    const store = useStore()
    const isAdmin = store.state?.roleId === '60fab62af7fefc45ffa1790a'
    const eventName = ref()
    const tableData = ref()
    const { toClipboard } = useClipboard()
    const printData = ref<PrintData[]>([])
    const { bindEvents } = useBindEvent()
    const { eventGroupsList } = useGroupList()
    // console.log('bindEvents', bindEvents)
    // 编辑
    const receiveParam = reactive({
      playerId: '',
      groupId: ''
    })
    // 领取 赛事/状态
    const defaultCollectionValue = ref(2)
    const defaultEventValue = ref('')
    const defaultEvent = ref('')
    const isOneTeam = ref(1)
    const addPlayerDialog = ref(false)
    const groups: any = ref([])
    const ruleRef = ref(null)
    const EditPlayerDialog = ref(false)
    // 总条数
    const pageTotal = ref(0)
    // 当前页数
    const currentPage = ref(1)
    // 当前页数条数
    const currentPageSize = ref(15)
    const state = ref(true)
    // 搜索状态
    const receiveOrNot = ref([
      {
        value: 2,
        label: '全部'
      },
      {
        value: 0,
        label: '未领取'
      },
      {
        value: 1,
        label: '已领取'
      }
    ])
    // 领取状态
    const status = ref([
      {
        id: 0,
        value: '未领取'
      },
      {
        id: 1,
        value: '已领取'
      }
    ])
    // 证件类型
    const identityType = ref([
      {
        id: 1,
        name: '身份证'
      },
      {
        id: 2,
        name: '护照'
      },
      {
        id: 3,
        name: '港澳居民来往内地通行证'
      },
      {
        id: 4,
        name: '台湾居民来往大陆通行证'
      },
      {
        id: 5,
        name: '军官证'
      }
    ])
    // 性别
    const sex = ref([
      {
        id: 1,
        name: '男'
      },
      {
        id: 2,
        name: '女'
      }
    ])
    const addPlayerForm: any = ref({
      name: '',
      sex: null,
      age: null,
      identityType: null,
      cardNum: '',
      group: '',
      phone: '',
      eventsId: '',
      groupEnId: '',
      city: '',
      numberPlate: '',
      window: '',
      bagStorage: '',
      clothesSize: '',
      bagType: '',
      status: null,
      remark: ''
    })
    const copyText = async (text: string) => {
      try {
        await toClipboard(text)
        ElMessage.success('已粘贴到剪切板！')
      } catch (err) {
        ElMessage.error('拷贝失败！')
      }
    }
    const addGroupsList = computed(() => {
      if (defaultEvent.value == '') {
        return eventGroupsList.value
      } else {
        const eventType = bindEvents.value.find(b => b.eventsId == defaultEvent.value)?.type
        // console.log('eventType', eventType, defaultEvent.value)
        return eventGroupsList.value.filter(e => e.type === eventType)
      }
    })
    // 初始化打印dom
    onMounted(() => {
      printInstance.value = new Print(printContainerRef.value)
    })
    // 一键打印
    const printAll = () => {
      const needPrintPlayers = selectedPlayers.value?.length ? selectedPlayers.value : tableData.value
      if (needPrintPlayers?.length > 6 && !selectedPlayers.value?.length) {
        ElMessage.warning('请选择要打印的数据！')
      } else if (!needPrintPlayers?.length) {
        ElMessage.warning('暂无打印数据！')
      } else {
        const data = needPrintPlayers.map(player => ({
          title: player.eventsName,
          name: player.name,
          event: player.group,
          sex: player.sex,
          age: player.age,
          remark: player.remark,
          code: player.cardNum || '',
          size: player.clothesSize,
          wNum: player.note === 0 ? `${player.window}窗口` : player.note === 1 ? player.numberPlate : `${player.window}窗口 ${player.numberPlate}`
        }))
        printData.value = data
        nextTick(() => {
          printInstance.value.writeIframe(printContainerRef.value?.innerHTML)
        })
      }
    }
    // 单独打印
    const printOne = player => {
      const data = {
        title: player.eventsName,
        name: player.name,
        event: player.group,
        sex: player.sex,
        age: player.age,
        remark: player.remark,
        code: player.cardNum || '',
        size: player.clothesSize,
        wNum: player.note === 0 ? `${player.window}窗口` : player.note === 1 ? player.numberPlate : `${player.window}窗口 ${player.numberPlate}`
      }
      printData.value = [data]
      nextTick(() => {
        // console.log('1111111111111', printContainerRef.value?.innerHTML)
        printInstance.value.writeIframe(printContainerRef.value?.innerHTML)
      })
    }
    // 编辑
    const editParams: any = ref({
      playerId: '',
      name: '',
      age: null,
      sex: null,
      identityType: null,
      cardNum: '',
      group: '',
      phone: '',
      eventsId: '',
      groupEnId: '',
      city: '',
      numberPlate: '',
      window: '',
      bagStorage: '',
      clothesSize: '',
      bagType: '',
      status: null,
      remark: ''
    })
    // 获取局部loading元素
    const getElContainer: any = ref(HTMLInputElement)
    // 获取赛事列表
    const eventsGroupsList: any = ref([])
    const pid = ref('') // 上一页最后一个选手的Id
    const prePages = ref(0) // 上次点击的页数
    const currentId: any = ref() // 当前页面的第一个选手Id
    const selectedPlayers = ref([])
    const tableDataRef = ref<InstanceType<typeof ElTable>>()
    const getEventsList = (): any => {
      api.account
        .getAccountMatchList()
        .then((res: any) => {
          eventsGroupsList.value = res
        })
        .catch(() => {
          ElMessage.error('未获取到赛事列表')
        })
    }
    const handleSelectionChange = val => {
      console.log('val', val)
      selectedPlayers.value = val
    }
    const reverseSelect = () => {
      tableData.value.forEach(row => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        tableDataRef.value!.toggleRowSelection(row, undefined)
      })
    }
    const addPlayer = () => {
      addPlayerForm.value.identityType = null
      getEventsList()
      addPlayerDialog.value = true
    }
    // 新增选手
    const handleAddPlayerConfirm = async () => {
      // 点击确认按钮进行二次表单验证
      const form: any = unref(ruleRef)
      if (!form) return
      try {
        await form.validate()
        const { cardNum } = addPlayerForm.value
        console.log(cardNum)
      } catch (error) {
        if (error) {
          return false
        }
      }
      addPlayerForm.value.age = Number(addPlayerForm.value.age)
      const chineseCity: any = []
      const province: any = ''
      const cities: any = addPlayerForm.value.city
      chineseCity.push(province)
      chineseCity.push(cities)
      // 新增
      api.player
        .addPlayer({
          name: addPlayerForm.value.name.trim(),
          sex: addPlayerForm.value.sex,
          age: addPlayerForm.value.age,
          identityType: addPlayerForm.value.identityType,
          cardNum: addPlayerForm.value.cardNum.replace('x', 'X'),
          group: addPlayerForm.value.group,
          phone: addPlayerForm.value.phone,
          eventsId: addPlayerForm.value.eventsId,
          groupEnId: addPlayerForm.value.groupEnId,
          city: chineseCity,
          numberPlate: addPlayerForm.value.numberPlate,
          window: addPlayerForm.value.window,
          bagStorage: addPlayerForm.value.bagStorage,
          clothesSize: addPlayerForm.value.clothesSize,
          bagType: addPlayerForm.value.bagType,
          status: addPlayerForm.value.status,
          remark: addPlayerForm.value.remark
        })
        .then((res: any) => {
          if (res) {
            ElMessage.success('添加成功')
            addPlayerDialog.value = false
            getRefreshPlayerList()
          }
        })
        .catch(err => {
          const result = err.errMsg.split(' ')
          if (result[result.length - 3] === 'cardNum:') {
            ElMessage.error('身份证号码已存在列表，请勿重复操作')
          } else if (result[result.length - 3] === 'phone:') {
            ElMessage.error('手机证号码已存在列表，请勿重复操作')
          } else if (err.errMsg === '错误的证件号码参数类型') {
            ElMessage.error('输入证件号码错误')
          } else {
            ElMessage.error(err.errMsg)
          }
        })
    }
    const changeIdentityType = (val: any) => {
      editParams.value.identityType = val
      addPlayerForm.value.identityType = val
    }
    // 判断是证件号码 还是身份证号码  进行验证规则
    const cardNumReg = (rule: any, value: any, callback: any) => {
      const regId = /(^\d{18}$)|(^\d{17}(\d|X|x)$)/
      if (!value) {
        return callback(new Error('证件号码不能为空'))
      }
      if (editParams.value.identityType === 1) {
        if (!regId.test(value)) {
          callback(new Error('请输入正确的身份号码'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    // 表单验证
    const rules = ref({
      name: [{ trigger: 'blur', required: true }],
      sex: [{ trigger: 'change', required: true, message: '请选择性别' }],
      age: [{ trigger: 'change', required: true, validator: FormValidate.Form().Age }],
      identityType: [{ trigger: 'change', required: true, message: '请选择证件类型' }],
      cardNum: [{ validator: cardNumReg, trigger: 'change', required: true }],
      group: [{ trigger: 'blur', required: true, message: '请选择组别' }],
      status: [{ trigger: 'change', required: true, message: '请选择领取状态' }],
      eventsId: [{ trigger: 'change', required: true, message: '请选择赛事名称' }],
      city: [{ trigger: 'change', required: true, message: '请选择城市' }],
      phone: [{ validator: FormValidate.Form().Tel, trigger: 'blur', required: true }]
    })
    onMounted(() => {
      getPlayerLists()
    })
    // 删除
    const handleDeleteEvent = (row: any) => {
      ElMessageBox.confirm('是否确定删除该选手', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          // 这里请求删除api
          api.player.deletePlayer(row.id).then((res: any) => {
            console.log(res)
            ElMessage.success('删除成功')
            getPlayerLists()
          })
        })
        .catch(() => {
          ElMessage('取消成功')
        })
    }
    const receiveNum = () => {
      api.player
        .getNumber(receiveParam)
        .then((res: any) => {
          editParams.value.numberPlate = res
        })
        .catch(err => {
          ElMessage.error(err.errMsg || '获取失败')
        })
    }
    const resetForm = async () => {
      const form: any = unref(ruleRef)
      form.resetFields()
      addPlayerDialog.value = false
      EditPlayerDialog.value = false
    }
    const changeSex = (val: any) => {
      editParams.value.sex = val
    }
    const changeGroup = (val: any) => {
      editParams.value.group = val
    }
    const changeStatus = (val: any) => {
      editParams.value.status = val
    }
    // 编辑
    const editPlayer = (row: any) => {
      console.log('row', row)
      getEventsList()
      getEventLists(row.eventsId)
      receiveParam.groupId = row.groupId
      receiveParam.playerId = row.id
      const identityTypeS: any = ref()
      switch (row.identityType) {
        case '身份证':
          identityTypeS.value = 1
          break
        case '护照':
          identityTypeS.value = 2
          break
        case '港澳居民来往内地通行证':
          identityTypeS.value = 3
          break
        case '台湾居民来往大陆通行证':
          identityTypeS.value = 4
          break
        case '军官证':
          identityTypeS.value = 5
          break
        default:
          break
      }
      const chineseCity = row.city[1]
      EditPlayerDialog.value = true
      editParams.value.playerId = row.id // 选手Id
      editParams.value.name = row.name
      editParams.value.sex = row.sex === '男' ? 1 : 2
      editParams.value.age = row.age
      editParams.value.identityType = identityTypeS.value
      editParams.value.cardNum = row.cardNum
      editParams.value.group = row.groupId // groupId
      editParams.value.groupEnId = row.groupEnId // groupEnId
      editParams.value.phone = row.phone
      editParams.value.eventsId = row.eventsId
      editParams.value.city = chineseCity
      editParams.value.numberPlate = row.numberPlate
      editParams.value.window = row.window
      editParams.value.bagStorage = row.bagStorage
      editParams.value.clothesSize = row.clothesSize
      editParams.value.bagType = row.bagType
      editParams.value.status = row.status === '已领取' ? 1 : 0
      editParams.value.remark = row.remark
    }
    const handleEditPlayerConfirm = async () => {
      const form: any = unref(ruleRef)
      if (!form) return
      try {
        await form.validate()
        const { cardNum } = editParams.value
        console.log(cardNum)
      } catch (error) {
        if (error) {
          return false
        }
      }
      editParams.value.age = Number(editParams.value.age)
      const chineseCity: any = []
      const province: any = ''
      const cities: any = editParams.value.city
      chineseCity.push(province)
      chineseCity.push(cities)

      // 编辑
      api.player
        .editPlayer({
          playerId: editParams.value.playerId,
          name: editParams.value.name.trim(),
          sex: editParams.value.sex,
          age: editParams.value.age,
          identityType: editParams.value.identityType,
          cardNum: editParams.value.cardNum.replace('x', 'X'),
          group: editParams.value.group,
          phone: editParams.value.phone,
          eventsId: editParams.value.eventsId,
          groupEnId: editParams.value.groupEnId,
          city: chineseCity,
          numberPlate: editParams.value.numberPlate,
          window: editParams.value.window,
          bagStorage: editParams.value.bagStorage,
          clothesSize: editParams.value.clothesSize,
          bagType: editParams.value.bagType,
          status: editParams.value.status,
          remark: editParams.value.remark
        })
        .then((res: any) => {
          if (res) {
            EditPlayerDialog.value = false
            ElMessage.success('修改成功')
            getPlayerLists()
          }
        })
        .catch(err => {
          const result = err.errMsg.split(' ')
          if (result[result.length - 3] === 'cardNum:') {
            ElMessage.error('身份证号码已存在列表，请勿重复操作')
          } else if (result[result.length - 3] === 'phone:') {
            ElMessage.error('手机证号码已存在列表，请勿重复操作')
          } else if (err.errMsg === '错误的证件号码参数类型') {
            ElMessage.error('输入证件号码错误')
          } else {
            ElMessage.error(err.errMsg)
          }
        })
    }
    // 搜索
    const handleGetInputSearchValue = () => {
      state.value = false
      pid.value = '' // 点搜索重置pid
      if (!state.value) {
        nextTick(() => {
          getPlayerLists()
        })
      }
    }
    // 分页
    const handleCurrentChange = (val: any) => {
      prePages.value = currentPage.value
      currentPage.value = val
      if (prePages.value > Math.ceil(pageTotal.value / currentPageSize.value)) {
        return
      }
      if (currentPage.value < prePages.value) {
        pid.value = currentId.value
      } else {
        pid.value = tableData.value[tableData.value.length - 1].id
      }
      if (state.value) {
        getPlayerLists()
      }
    }
    // 监听数据是否为空
    watch(
      eventName,
      nv => {
        if (nv === '') {
          eventName.value = ' '
          getPlayerLists()
        }
        // 扫码出来的身份证号多个 '
        if (nv?.includes("'")) {
          eventName.value = nv.replace("'", '')
        }
      },
      { deep: true, immediate: true }
    )
    // 获取选手列表
    const getPlayerLists = () => {
      api.player
        .getPlayerList({
          name: encodeURIComponent(eventName.value === undefined ? ' ' : eventName.value.replace('x', 'X')),
          size: currentPageSize.value,
          prePage: prePages.value,
          page: currentPage.value,
          pid: encodeURIComponent(pid.value === '' ? ' ' : pid.value),
          status: defaultCollectionValue.value,
          group: encodeURIComponent(defaultEventValue.value === '' ? ' ' : defaultEventValue.value),
          eventsName: encodeURIComponent(defaultEvent.value === '' ? ' ' : defaultEvent.value),
          checkGroupEnroll: isOneTeam.value
        })
        .then((res: any) => {
          state.value = true
          tableData.value = res.players
          pageTotal.value = res.pages.total
          currentId.value = tableData.value[0].id
        })
        .catch(err => {
          console.log(err)
        })
    }
    // 刷新列表
    const getRefreshPlayerList = async () => {
      await api.player
        .getPlayerList({
          name: encodeURIComponent(' '),
          size: currentPageSize.value,
          prePage: 0,
          page: 1,
          pid: encodeURIComponent(' '),
          status: defaultCollectionValue.value,
          group: encodeURIComponent(defaultEventValue.value === '' ? ' ' : defaultEventValue.value),
          eventsName: encodeURIComponent(defaultEvent.value === '' ? ' ' : defaultEvent.value),
          checkGroupEnroll: isOneTeam.value
        })
        .then(async (res: any) => {
          tableData.value = res.players
          pageTotal.value = res.pages.total
        })
        .catch(err => {
          console.log(err)
        })
    }
    // 导入CSV
    const handleClickVSC = () => {
      router.push({ name: routerNames.importCSV, params: { chipPlayerId: 'player' } })
    }
    const exportExcelSelect = () => {
      const loadingInstance = ElLoading.service({ target: getElContainer.value, text: '正在导出EXCEL...' })
      const BASE_URL: string = process.env.VUE_APP_BASE_URL as string
      axios
        .get(`${BASE_URL}/api/v1/player/excel`, {
          headers: {
            token: store.state.token
          },
          responseType: 'blob'
        })
        .then((res: any) => {
          loadingInstance.close()
          console.log(res)
          const BLOB = new Blob([res.data])
          const url = window.URL.createObjectURL(BLOB)
          const link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          link.setAttribute('download', '选手信息.xlsx')
          document.body.appendChild(link)
          link.click()
        })
    }
    const handlePrompt = (eventsIds: any) => {
      if (!eventsIds) {
        ElMessage.error('请先绑定的赛事')
      }
    }
    // 新增 某场赛事下面的组别
    const changeEventsAddValue = (val: any) => {
      addPlayerForm.value.group = ''
      if (val) {
        getEventLists(val)
      } else {
        ElMessage.error('请先绑定的赛事')
      }
    }
    // 编辑 某场赛事下面的组别
    const changeEventsEditValue = (val: any) => {
      editParams.value.group = ''
      getEventLists(val)
    }
    // 组别
    const getEventLists = (eventsId: any) => {
      api.player.getEventsToGroups(eventsId).then((res: any) => {
        groups.value = res
      })
    }
    // 序列号自增
    const tableIndex = index => {
      return (currentPage.value - 1) * currentPageSize.value + index + 1
    }
    return {
      eventName,
      tableData,
      addGroupsList,
      tableDataRef,
      printData,
      printContainerRef,
      bindEvents,
      isAdmin,

      receiveOrNot,
      defaultCollectionValue,
      defaultEventValue,
      defaultEvent,
      isOneTeam,
      handleSelectionChange,
      reverseSelect,
      // 新增
      addPlayer,
      status,
      addPlayerForm,
      editPlayer,
      addPlayerDialog,
      handleAddPlayerConfirm,
      identityType,
      groups,
      sex,
      receiveNum,
      handleDeleteEvent,
      print,
      copyText,
      printOne,
      printAll,

      pageTotal,
      currentPage,
      currentPageSize,
      handleCurrentChange,
      handleGetInputSearchValue,

      // 导出excel
      exportExcelSelect,
      handleClickVSC,
      ruleRef,
      rules,
      editParams,
      handleEditPlayerConfirm,
      EditPlayerDialog,
      changeSex,
      changeIdentityType,
      changeGroup,
      changeStatus,
      resetForm,
      eventsGroupsList,
      getElContainer,
      handlePrompt,
      changeEventsAddValue,
      changeEventsEditValue,
      cityDatas,
      tableIndex
    }
  }
})
