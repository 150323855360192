
import { defineComponent, ref } from 'vue'
import FormLogin from '@/common/components/FormLogin.vue'
import api, { LoginForm } from '../api/api'
import router, { routerNames } from '../router'
import { useStore } from 'vuex'
import common from '@/common/common'
import { ElMessage } from 'element-plus'
export default defineComponent({
  components: { FormLogin },
  setup() {
    const title = ref('账户密码登录')
    const store = useStore()
    const handleLogin = (loginFrom: LoginForm): void => {
      api.session
        .login({ username: loginFrom.username, password: loginFrom.password })
        .then((res: any) => {
          sessionStorage.setItem('deviceId', res.deviceId)
          // 这里提交到vuex里面去！！！
          store.dispatch('login', { session: res, keySysAdminSession: common.keySysAdminSession })
          router.push({ name: routerNames.Workspace })
          ElMessage.success('登录成功')
        })
        .catch(err => {
          ElMessage.error(err.errMsg)
        })
    }
    return {
      handleLogin,
      title
    }
  }
})
