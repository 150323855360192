import { ref, Ref } from 'vue'
import api from '@/api/api'
import { ElMessage } from 'element-plus'
import { EventType } from '@/utils/enum'
interface GroupList {
  id: number
  name: string
  type: EventType
}
export default function(): { eventGroupsList: Ref<GroupList[]>; getEventsGroup: () => void } {
  const eventGroupsList: any = ref([])
  const getEventsGroup = () => {
    api.event
      .getEventList()
      .then(res => {
        eventGroupsList.value = ((res as any) || []).reverse()
      })
      .catch(err => {
        ElMessage.error(err.errMsg)
      })
  }
  getEventsGroup()
  return { eventGroupsList, getEventsGroup }
}
