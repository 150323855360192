import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import api, { Api } from './api/api'
import installElementPlus from './plugins/element'
declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $api: Api
    componentsGroups: any
  }
}
const app = createApp(App)
app.config.globalProperties.$api = api
installElementPlus(app).use(store).use(router).mount('#app')
