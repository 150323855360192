
import { defineComponent, ref, PropType, watch } from 'vue'
import { multipartUpload, baseOSSUrl } from '@/utils/oss'
import { nanoid } from 'nanoid'

interface defaultItem {
  name?: string;
  url : string;
}
export type Sumbform = Array<defaultItem>;

export default defineComponent({
  props: {
    uploadLength: {
      type: Number,
      default: 1
    },
    defaultList: {
      type: Object as PropType<Sumbform> || undefined
    },
    onUploadSuccess: Function,
    onRemove: Function
  },
  setup(props) {
    console.log(nanoid())
    // 查看上传图片地址
    const dialogImageUrl = ref('')
    // dialoag是否展示
    const dialogVisible = ref(false)
    // disabled
    const disabled = ref(false)
    // file maxLength
    const showUploadEntry = ref(true)
    // isUploading
    const isUploading = ref(false)
    // fileList
    const fileList = ref(props.defaultList || [])

    const handleRemove = file => {
      fileList.value = fileList.value.filter(item => item.url !== file.url)
      typeof props.onRemove === 'function' && props.onRemove(file)
    }

    const handlePictureCardPreview = file => {
      dialogImageUrl.value = file.url
      dialogVisible.value = true
    }

    const onChangeFile = async file => {
      isUploading.value = true
      fileList.value.push(file)
      const fileName = nanoid() + file.name.substr(file.name.lastIndexOf('.'))
      const info = await multipartUpload(fileName, file.raw)
      typeof props.onUploadSuccess === 'function' && props.onUploadSuccess(baseOSSUrl + info.name)
      showUploadEntry.value = false
      isUploading.value = false
    }

    watch(() => props.defaultList, (value) => {
      fileList.value = value?.length ? value : []
    })

    return {
      // 上传背景图相关
      fileList,
      isUploading,
      disabled,
      showUploadEntry,
      dialogVisible,
      dialogImageUrl,
      handleRemove,
      handlePictureCardPreview,
      onChangeFile
    }
  }
})
