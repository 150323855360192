
import { defineComponent, ref, onMounted, watch } from 'vue'
import paging from '../paging.vue'
import api from '@/api/api'
import router, { routerNames } from '@/router'
import { ElMessage } from 'element-plus'
export default defineComponent({
  components: { paging },
  setup() {
    const eventName = ref()
    const tableData = ref()
    // 新增按钮
    onMounted(() => {
      getEventList()
    })
    // 分页
    // 总条数
    const pageTotal = ref(100)
    // 当前页数
    const currentPage = ref(1)
    // 当前页数条数
    const currentPageSize = ref(15)
    const handleCurrentChange = (val: any) => {
      currentPage.value = val
      getEventList()
    }
    // 搜索
    const handleGetInputSearchValue = () => {
      getEventList()
    }
    // 监听数据是否为空
    watch(
      eventName,
      nv => {
        if (nv === '') {
          eventName.value = ' '
          getEventList()
        }
      },
      { deep: true, immediate: true }
    )
    // 获取芯片列表
    const getEventList = () => {
      api.chip
        .getChipList({
          name: encodeURIComponent(eventName.value === undefined ? ' ' : eventName.value),
          page: currentPage.value,
          size: currentPageSize.value
        })
        .then((res: any) => {
          tableData.value = res.chips
          pageTotal.value = res.pages.total
        })
        .catch(err => {
          ElMessage.error(err.errMsg)
        })
    }
    const handleClickVSC = (text: string) => {
      router.push({ name: routerNames.importCSV, params: { chipPlayerId: text } })
    }
    // 序列号自增
    const tableIndex = index => {
      return (currentPage.value - 1) * currentPageSize.value + index + 1
    }
    return {
      eventName,
      tableData,
      pageTotal,
      currentPage,
      currentPageSize,
      handleCurrentChange,
      handleGetInputSearchValue,

      handleClickVSC,
      tableIndex
    }
  }
})
