
import { defineComponent, ref, onMounted, watch, unref } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import paging from '../paging.vue'
import api from '@/api/api'
import { FormValidate } from '@/utils/format'
export default defineComponent({
  components: { paging },
  setup () {
    const eventName = ref()
    const tableData = ref()
    const ruleRef = ref(null)
    const editId = ref()
    const dialogStatus:any = ref()
    const titleMap:any = ref({
      addEquipment: '添加设备',
      editEquipment: '编辑设备'
    })
    const newEquipmentDialog = ref(false)
    const newEquipmentParamsObject = ref({
      name: '',
      seq: ''
    })
    const eventsId = ref()
    const eventsName = ref()
    const accountName = ref()
    // 表单验证
    const rules = ref({
      name: [
        { validator: FormValidate.FormOne().Name, trigger: 'blur', required: true }
      ],
      seq: [
        { trigger: 'blur', required: true, message: '请输入设备序号' }
      ]
    })
    const bizTypeId:any = ref()
    const pageTotal = ref(100)
    // 当前页数
    const currentPage = ref(1)
    // 当前页数条数
    const currentPageSize = ref(15)
    onMounted(() => {
      getDeviceList()
    })
    // 删除
    const handleDeleteEvent = (row:any) => {
      ElMessageBox.confirm('是否确定删除该设备', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 这里请求删除api
        api.device.deleteDevice(row.seq).then((res:any) => {
          if (res) {
            ElMessage.success('删除成功')
            getDeviceList()
          }
        }).catch((err) => {
          ElMessage.error(err.errMsg)
        })
      }).catch(() => {
        ElMessage('取消成功')
      })
    }
    // 新增
    const addAccountNumber = () => {
      newEquipmentParamsObject.value.name = ''
      newEquipmentParamsObject.value.seq = ''
      dialogStatus.value = 'addEquipment'
      newEquipmentDialog.value = true
    }
    // 编辑 绑定赛事
    const refName = ref()
    const handleEditDevice = (row:any) => {
      refName.value = ref(null as HTMLInputElement | null)
      newEquipmentDialog.value = true
      dialogStatus.value = 'editEquipment'
      editId.value = row.id
      eventsId.value = row.eventsId
      newEquipmentParamsObject.value = JSON.parse(JSON.stringify(row))
      eventsName.value = row.events
      accountName.value = row.user
      bizTypeId.value = row.bizType
    }
    // 编辑解绑
    const handleAccountRelieve = () => {
      accountName.value = ''
    }
    // 列表解绑
    const handleUnbound = (row:any) => {
      ElMessageBox.confirm('是否确定解绑', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 这里请求删除api
        api.device.relieveEvents(row.id).then((res:any) => {
          if (res) {
            ElMessage.success('解绑成功')
            newEquipmentDialog.value = false
            getDeviceList()
          }
        }).catch((err) => {
          ElMessage.error(err.errMsg)
        })
      }).catch(() => {
        ElMessage('取消成功')
      })
    }
    const handleAddConfirm = async () => {
      const form: any = unref(ruleRef)
      if (!form) return
      try {
        await form.validate()
        if (dialogStatus.value === 'addEquipment') {
          api.device.addDevice(newEquipmentParamsObject.value).then((res:any) => {
            if (res) {
              ElMessage.success('添加成功')
              newEquipmentDialog.value = false
              getDeviceList()
            }
          }).catch(err => {
            const result = err.errMsg.split(' ')
            if (result[result.length - 3] === 'name:') {
              ElMessage.error('设备名称已存在，请勿重复操作')
            } else if (result[result.length - 3] === 'seq:') {
              ElMessage.error('设备序列号已存在，请勿重复操作')
            } else {
              ElMessage.error(err.errMsg)
            }
          })
        } else {
          const editParams = {
            id: editId.value,
            name: newEquipmentParamsObject.value.name,
            seq: newEquipmentParamsObject.value.seq,
            events: eventsName.value ? eventsId.value : '',
            user: accountName.value,
            bizType: bizTypeId.value === '闸机设备' ? 1 : 2
          }
          api.device.editDevice(editParams).then((res:any) => {
            if (res) {
              ElMessage.success('编辑成功')
              newEquipmentDialog.value = false
              getDeviceList()
            }
          }).catch((err) => {
            const result = err.errMsg.split(' ')
            if (result[result.length - 3] === 'name:') {
              ElMessage.error('设备名称已存在，请勿重复操作')
            } else if (result[result.length - 3] === 'seq:') {
              ElMessage.error('设备序列号已存在，请勿重复操作')
            } else {
              ElMessage.error(err.errMsg)
            }
          })
        }
      } catch (error:any) {
        ElMessage.error(error.errMsg)
      }
    }
    // 取消清空所有表单值
    const resetForm = () => {
      const form: any = unref(ruleRef)
      form.resetFields()
      newEquipmentDialog.value = false
    }
    // 分页总条数
    const handleCurrentChange = (val:any) => {
      currentPage.value = val
      getDeviceList()
    }
    // 搜索
    const handleGetInputSearchValue = () => {
      getDeviceList()
    }
    // 监听数据是否为空
    watch(eventName, (nv) => {
      if (nv === '') {
        eventName.value = ' '
        getDeviceList()
      }
    }, { deep: true, immediate: true })
    // 获取赛事列表
    const getDeviceList = () => {
      api.device.getDeviceList({
        name: encodeURIComponent(eventName.value === undefined ? ' ' : eventName.value),
        page: currentPage.value,
        size: currentPageSize.value
      }).then((res:any) => {
        tableData.value = res.devices
        pageTotal.value = res.pages.total
      }).catch((err) => {
        ElMessage.error(err.errMsg)
      })
    }
    const isShowTooltip = ref(false)
    const onMouseOver = (val:any) => {
      const parentWidth = val.value.parentNode.offsetWidth
      const contentWidth = val.value.offsetWidth
      if (contentWidth > parentWidth) {
        isShowTooltip.value = false
      } else {
        isShowTooltip.value = true
      }
    }
    // 序列号自增
    const tableIndex = (index) => {
      return (currentPage.value - 1) * currentPageSize.value + index + 1
    }
    return {
      eventName,
      tableData,

      addAccountNumber,
      // 新增
      newEquipmentDialog,
      newEquipmentParamsObject,
      handleAddConfirm,
      ruleRef,
      resetForm,
      rules,
      handleEditDevice,
      dialogStatus,
      titleMap,
      handleDeleteEvent,

      pageTotal,
      currentPage,
      currentPageSize,
      handleCurrentChange,
      handleGetInputSearchValue,
      eventsId,
      handleAccountRelieve,
      handleUnbound,
      eventsName,
      accountName,
      refName,
      onMouseOver,
      isShowTooltip,
      tableIndex
    }
  }
})
