import Http from '@/http'
import store from '@/store'
import { AxiosInstance, AxiosResponse } from 'axios'
import router, { routerNames } from '../router'
const axiosInstance: AxiosInstance = new Http(store, router, routerNames.Login).instance

export interface LoginForm {
  username: string
  password: string
}
export interface Register {
  username: string
  password: string
  roleId: string
  eventsId: string
  deviceSeq: string
}
export interface AccountDelete {
  userId: string
}
export interface AccountEdit {
  userId: string
  password: string
  roleId: string
  eventsId: string
  deviceSeq: string
}
export interface accountListParams {
  username: string
  page: number
  size: number
}
export interface AddEvent {
  eventsId: string
  name: string
  organizers: string
  venue: string
  time: number | null
  group: Array<any>
  operation: string
  recognition: number | null
  recognitionRate: number
  afc: Array<any>
  rec: Array<any>
}
export interface EventList {
  size: number
  page: number
  name: string
}
export interface RuleParam {
  groupId: string
  fixNumber: string
  startNumber: string
}
export interface DeleteEvent {
  eventsId: string
}
export interface EventEdit {
  id: string
  name: string
  organizers: string
  venue: string
  time: number | null
  group: Array<any>
  operation: string
  recognition: number | null
  afc: Array<any>
  rec: Array<any>
}
export interface AddEventGroup {
  groupName: string
  sort: number
}
export interface GetEventGroupList {
  size: number
  page: number
  name: string
}
export interface EditEventGroup {
  groupId: string
  groupName: string
  sort: number
}
export interface DeleteEventGroup {
  groupId: string
}
export interface AddDevice {
  name: string
  seq: string
}
export interface EditDevice {
  id: string
  name: string
  seq: string
  events: string
  user: string
  bizType: number | null
}
export interface GetDeviceList {
  size: number
  page: number
  name: string
}
export interface AddPlayer {
  name: string
  sex: number | null
  age: number | null
  identityType: number | null
  cardNum: string
  group: string
  phone: string
  eventsId: string
  groupEnId: string
  city: Array<any>
  numberPlate: string
  window: string
  bagStorage: string
  clothesSize: string
  bagType: string
  status: number | null
  remark?: string
}
export interface EditPlayer {
  playerId: string
  name: string
  sex: number | null | string
  age: number | null
  identityType: number | null | string
  cardNum: string
  group: string
  phone: string
  eventsId: string
  groupEnId: string
  city: Array<any>
  numberPlate: string
  window: string
  bagStorage: string
  clothesSize: string
  bagType: string
  status: number | null | string
  remark?: string
}
export interface GetPlayerList {
  size: number
  page: number
  name: string
  status: number | null
  group: string
  eventsName: string | null
  checkGroupEnroll: number
  pid: string
  prePage: number | null
}
export interface GetChipList {
  size: number
  page: number
  name: string
}
export interface ChipUploadFileParams {
  file: any
  field: Array<any>
}
export interface PlayerUploadFile {
  file: any
  field: Array<any>
}

export interface RelieveEvents {
  deviceId: string
}
export interface BindingInfo {
  identityType: number | null
  cardNum: string
  numberPlate: string
  chipNo: string
}
export interface AddNumber {
  groupId: string
  seqNums: number[]
  seqSegments: string[]
}

export interface SessionApi {
  register(data: Register): Promise<AxiosResponse>
  login(data: LoginForm): Promise<AxiosResponse>
  logout(): Promise<AxiosResponse>
}
export interface accountApi {
  accountList(data: accountListParams): Promise<AxiosResponse>
  accountDelete(data: AccountDelete): Promise<AxiosResponse>
  getAccountRoleList(): Promise<AxiosResponse>
  getAccountMatchList(): Promise<AxiosResponse>
  accountEdit(data: AccountEdit): Promise<AxiosResponse>
}
export interface eventApi {
  addEvent(data: AddEvent): Promise<AxiosResponse>
  eventList(data: EventList): Promise<AxiosResponse>
  deleteEvent(data: DeleteEvent): Promise<AxiosResponse>
  addRule(data: RuleParam): Promise<AxiosResponse>
  eventEdit(data: EventEdit): Promise<AxiosResponse>
  getEventList(): Promise<AxiosResponse>
}
export interface eventGroupApi {
  addEventGroup(data: AddEventGroup): Promise<AxiosResponse>
  getEventGroupList(data: GetEventGroupList): Promise<AxiosResponse>
  editEventGroup(data: EditEventGroup): Promise<AxiosResponse>
  deleteEventGroup(data: DeleteEventGroup): Promise<AxiosResponse>
}
export interface deviceApi {
  addDevice(data: AddDevice): Promise<AxiosResponse>
  editDevice(data: EditDevice): Promise<AxiosResponse>
  deleteDevice(deviceSeq: string): Promise<AxiosResponse>
  relieveEvents(deviceId: string): Promise<AxiosResponse>
  getDeviceSelect(): Promise<AxiosResponse>
  getDeviceList(data: GetDeviceList): Promise<AxiosResponse>
  getAccountDeviceList(eventsId: string): Promise<AxiosResponse>
}
export interface playerApi {
  getBindEvents(): Promise<any>
  addPlayer(data: AddPlayer): Promise<AxiosResponse>
  editPlayer(data: EditPlayer): Promise<AxiosResponse>
  deletePlayer(player: string): Promise<AxiosResponse>
  getPlayerList(data: GetPlayerList): Promise<AxiosResponse>
  playerUploadFile(data: PlayerUploadFile): Promise<AxiosResponse>
  getEventsToGroups(eventsId: string): Promise<AxiosResponse>
  getNumber(data: unknown): Promise<AxiosResponse>
}
export interface chipApi {
  getChipList(data: GetChipList): Promise<AxiosResponse>
  chipUploadFile(data: ChipUploadFileParams): Promise<AxiosResponse>
  exportDownload(): Promise<AxiosResponse>
}
export interface deliveryApi {
  getDeliveryList(text: string): Promise<AxiosResponse>
  bindingInfo(data: BindingInfo): Promise<AxiosResponse>
  chipOn(chipNo: string): Promise<AxiosResponse>
}
export interface reservedNumberApi {
  getList(text: string): Promise<AxiosResponse>
  add(data: AddNumber): Promise<AxiosResponse>
  del(chipNo: unknown): Promise<AxiosResponse>
  getGroupList(): Promise<AxiosResponse>
  lookList(groupId: string): Promise<AxiosResponse>
  clearList(groupId: string): Promise<AxiosResponse>
}
export interface Api {
  session: SessionApi
  account: accountApi
  event: eventApi
  eventGroup: eventGroupApi
  device: deviceApi
  player: playerApi
  chip: chipApi
  delivery: deliveryApi
  reservedNumber: reservedNumberApi
}

const api: Api = {
  session: {
    register: (data: Register): Promise<AxiosResponse> => axiosInstance.post('api/v1/account/register', data),
    login: (data: LoginForm): Promise<AxiosResponse> => axiosInstance.post('api/v1/sessions/login', data),
    logout: (): Promise<AxiosResponse> => axiosInstance.delete('api/v1/sessions/logout')
  },
  account: {
    accountList: (data: accountListParams): Promise<AxiosResponse> =>
      axiosInstance.get(`api/v1/account/list/${data.username}/${data.size}/${data.page}`),
    accountDelete: (data: AccountDelete): Promise<AxiosResponse> => axiosInstance.delete('api/v1/account/user', { data: data }),
    getAccountRoleList: (): Promise<AxiosResponse> => axiosInstance.get('api/v1/account/roles'),
    getAccountMatchList: (): Promise<AxiosResponse> => axiosInstance.get('api/v1/account/events'),
    accountEdit: (data: AccountEdit): Promise<AxiosResponse> => axiosInstance.patch('api/v1/account/user', data)
  },
  event: {
    addEvent: (data: AddEvent): Promise<AxiosResponse> => axiosInstance.post('api/v1/events', data),
    eventList: (data: EventList): Promise<AxiosResponse> => axiosInstance.get(`api/v1/events/list/${data.name}/${data.size}/${data.page}`),
    deleteEvent: (data: DeleteEvent): Promise<AxiosResponse> => axiosInstance.delete('api/v1/events', { data: data }),
    eventEdit: (data: EventEdit): Promise<AxiosResponse> => axiosInstance.patch('api/v1/events', data),
    addRule: (data: RuleParam): Promise<AxiosResponse> => axiosInstance.post('api/v1/events/addNumPlateRule', data),
    getEventList: (): Promise<AxiosResponse> => axiosInstance.get('api/v1/eventsGroup/list')
  },
  eventGroup: {
    addEventGroup: (data: AddEventGroup): Promise<AxiosResponse> => axiosInstance.post('api/v1/eventsGroup', data),
    getEventGroupList: (data: GetEventGroupList): Promise<AxiosResponse> =>
      axiosInstance.get(`api/v1/eventsGroup/list/${data.name}/${data.size}/${data.page}`),
    editEventGroup: (data: EditEventGroup): Promise<AxiosResponse> => axiosInstance.patch('api/v1/eventsGroup', data),
    deleteEventGroup: (data: DeleteEventGroup): Promise<AxiosResponse> => axiosInstance.delete('api/v1/eventsGroup', { data: data })
  },
  device: {
    addDevice: (data: AddDevice): Promise<AxiosResponse> => axiosInstance.post('api/v1/device', data),
    editDevice: (data: EditDevice): Promise<AxiosResponse> => axiosInstance.patch('api/v1/device', data),
    deleteDevice: (deviceSeq: string): Promise<AxiosResponse> => axiosInstance.delete(`api/v1/device/${deviceSeq}`),
    relieveEvents: (deviceId: string): Promise<AxiosResponse> => axiosInstance.patch(`api/v1/device/${deviceId}`),
    getDeviceSelect: (): Promise<AxiosResponse> => axiosInstance.get('api/v1/device'),
    getDeviceList: (data: GetDeviceList): Promise<AxiosResponse> => axiosInstance.get(`api/v1/device/list/${data.name}/${data.size}/${data.page}`),
    getAccountDeviceList: (eventsId: string): Promise<AxiosResponse> => axiosInstance.get(`api/v1/device/events/${eventsId}`)
  },
  player: {
    getBindEvents: (): Promise<AxiosResponse> => axiosInstance.get('/api/v1/account/events'),
    addPlayer: (data: AddPlayer): Promise<AxiosResponse> => axiosInstance.post('api/v1/player', data),
    editPlayer: (data: EditPlayer): Promise<AxiosResponse> => axiosInstance.patch('api/v1/player', data),
    deletePlayer: (playerId: string): Promise<AxiosResponse> => axiosInstance.delete(`api/v1/player/${playerId}`),
    getPlayerList: (data: GetPlayerList): Promise<AxiosResponse> =>
      axiosInstance.get(`api/v1/player/list/${data.name}/${data.size}/${data.prePage}/${data.page}/${data.pid}/${data.status}/${data.group}/${data.eventsName}/${data.checkGroupEnroll}`),
    playerUploadFile: (data: PlayerUploadFile): Promise<AxiosResponse> => axiosInstance.post('api/v1/player/csv', data),
    getEventsToGroups: (eventsId: string): Promise<AxiosResponse> => axiosInstance.get(`/api/v1/events/group/${eventsId}`),
    getNumber: (data: { playerId: string; groupId: string }): Promise<AxiosResponse> =>
      axiosInstance.post(`/api/v1/player/assign/${data.playerId}/${data.groupId}`)
  },
  chip: {
    getChipList: (data: GetChipList): Promise<AxiosResponse> => axiosInstance.get(`api/v1/chip/list/${data.name}/${data.size}/${data.page}`),
    chipUploadFile: (data: ChipUploadFileParams): Promise<AxiosResponse> => axiosInstance.post('api/v1/chip/upload', data),
    exportDownload: (): Promise<AxiosResponse> => axiosInstance.get('api/v1/chip/excel')
  },
  delivery: {
    getDeliveryList: (text: string): Promise<AxiosResponse> => axiosInstance.get(`api/v1/delivery/find/${text}`),
    bindingInfo: (data: BindingInfo): Promise<AxiosResponse> => axiosInstance.post('api/v1/delivery', data),
    chipOn: (chipNo: string): Promise<AxiosResponse> => axiosInstance.get(`api/v1/delivery/numberplate/${chipNo}`)
  },
  reservedNumber: {
    getList: (text: string): Promise<AxiosResponse> => axiosInstance.get(`/api/v1/numberPlate/list/group/${text}`),
    add: (data: AddNumber): Promise<AxiosResponse> => axiosInstance.post('api/v1/numberPlate', data),
    lookList: (groupId: string): Promise<AxiosResponse> => axiosInstance.get(`/api/v1/numberPlate/checkQueue/${groupId}`),
    clearList: (groupId: string): Promise<AxiosResponse> => axiosInstance.post(`/api/v1/numberPlate/emptyQueue/${groupId}`),
    del: (chipNo: { seqNumber: string; id: string }): Promise<AxiosResponse> =>
      axiosInstance.delete(`/api/v1/numberPlate/${chipNo.id}/${chipNo.seqNumber}`),
    getGroupList: (): Promise<AxiosResponse> => axiosInstance.get('/api/v1/numberPlate/group')
  }
}

export default api
