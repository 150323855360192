
import { defineComponent } from 'vue'
import { FormValidate } from '@/utils/format'
export default defineComponent({
  props: {
    logoPath: String,
    title: String
  },
  emits: ['handleLoginEvent'],
  data() {
    return {
      loginForm: {
        username: '',
        password: ''
      },
      FormValidate
    }
  },
  methods: {
    handleClick(formName: any) {
      (this as any).$refs[formName].validate((valid: any) => {
        if (valid) {
          this.$emit('handleLoginEvent', this.loginForm)
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
})
