<template>
  <el-container>
    <div class="playerManagement" ref="getElContainer">
      <div class="playerManagement-title">
        <span>选手管理</span>
      </div>
      <!-- 搜索 -->
      <el-row class="row-bg">
        <div class="playerManagement-search">
          <el-input placeholder="请输入证件号码/手机号码/姓名/号码布" v-model.trim="eventName"></el-input>
          <div class="status">
            <span>领物状态:</span>
            <el-select v-model="defaultCollectionValue">
              <el-option v-for="item in receiveOrNot" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
          <div class="event">
            <span>赛事:</span>
            <el-select v-model="defaultEvent" placeholder="请选择赛事" clearable @change="defaultEventValue = ''">
              <el-option v-for="item in bindEvents" :key="item.eventsId" :label="item.events" :value="item.eventsId"> </el-option>
            </el-select>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <span>赛事组别:</span>
            <el-select v-model="defaultEventValue" placeholder="请选择领物方式" clearable>
              <el-option v-for="item in addGroupsList" :key="item.id" :label="item.name" :value="item.name"> </el-option>
            </el-select>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <el-checkbox v-model="isOneTeam" :true-label="1" :false-label="0" label="查看同团选手" />
          </div>
          <el-button class="searchBtn" type="primary" @click="handleGetInputSearchValue">搜索</el-button>
          <el-button class="searchBtn" type="primary" @click="printAll">一键打印</el-button>
          <!-- <el-select v-model="printDevice" class="m-2" placeholder="请选择打印机">
            <el-option v-for="item in printDeviceOptions" :key="item.value" :label="item.label" :value="item.value" />
          </el-select> -->
        </div>
        <div>
          <el-button @click="handleClickVSC">导入EXCEL</el-button>
          <el-button @click="exportExcelSelect">导出EXCEL</el-button>
          <el-button @click="addPlayer">添加选手</el-button>
        </div>
      </el-row>
      <!-- table -->
      <el-card shadow="hover">
        <div class="table-button"><el-button @click="reverseSelect">反选</el-button></div>
        <el-table
          ref="tableDataRef"
          :data="tableData"
          style="width: 100%;margin-bottom: 35px;"
          :header-cell-style="{ textAlign: 'center', background: '#EFF7FF', color: '#222222' }"
          :cell-style="{ textAlign: 'center', color: '#666666' }"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55" fixed="left" />
          <el-table-column type="index" :index="tableIndex" label="序号"></el-table-column>
          <el-table-column prop="name" width="100" label="姓名" show-overflow-tooltip></el-table-column>
          <el-table-column prop="sex" width="50px" label="性别" style="color:red">
            <template v-slot="scope">
              <span v-if="scope.row.sex === '男'" style="color: #4399FE">男</span>
              <span v-else style="color: #F2646A">女</span>
            </template>
          </el-table-column>
          <el-table-column prop="age" label="年龄" show-overflow-tooltip></el-table-column>
          <el-table-column prop="identityType" label="证件类型" show-overflow-tooltip></el-table-column>
          <el-table-column prop="cardNum" label="证件号码" width="200"></el-table-column>
          <el-table-column prop="status" width="80px" label="领物状态">
            <template v-slot="scope">
              <el-button type="primary" plain size="mini" v-if="scope.row.status === '已领取'">{{ scope.row.status }}</el-button>
              <el-button type="danger" plain v-else>{{ scope.row.status }}</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="pass" width="80px" label="闸机状态">
            <template v-slot="scope">
              <el-button type="primary" plain size="mini" v-if="scope.row.pass === '已进入'">{{ scope.row.pass }}</el-button>
              <el-button type="danger" plain v-else>{{ scope.row.pass }}</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="phone" width="110px" label="手机号码"></el-table-column>
          <el-table-column prop="group" label="组别" width="150px" show-overflow-tooltip></el-table-column>
          <el-table-column prop="numberPlate" label="号码布" width="80">
            <template #default="scope">
              <span :class="scope.row?.toTimer ? 'toTimer' : ''">{{ scope.row?.numberPlate }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="eventsName" width="150px" label="赛事名称" show-overflow-tooltip></el-table-column>
          <el-table-column prop="groupEnId" width="150px" label="团报ID" show-overflow-tooltip>
            <template #default="scope">
              <el-button type="text" @click="copyText(scope.row?.groupEnId)">{{ scope.row?.groupEnId }}</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="city[1]" width="150px" label="城市" show-overflow-tooltip></el-table-column>
          <el-table-column prop="window" label="领物窗口" width="80"></el-table-column>
          <el-table-column prop="bagStorage" label="存包区" width="80"></el-table-column>
          <el-table-column prop="clothesSize" label="衣服尺寸" width="80"></el-table-column>
          <el-table-column prop="bagType" label="参赛包类型" width="80"></el-table-column>
          <el-table-column label="操作" width="150px" fixed="right">
            <template #default="scope">
              <span @click="printOne(scope.row)" class="editStyle">打印</span>
              <span @click="editPlayer(scope.row)" class="editStyle">编辑</span>
              <span v-if="isAdmin" @click="handleDeleteEvent(scope.row)" class="deleteStyle">删除</span>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <paging
          class="fixedRightBottom"
          :pageTotal="pageTotal"
          :currentPageSize="currentPageSize"
          @handle-current-change="handleCurrentChange"
        ></paging>
      </el-card>
      <!-- 添加选手 -->
      <el-dialog title="添加选手" :close-on-click-modal="false" v-model="addPlayerDialog" width="690px" center @close="resetForm">
        <el-form
          ref="ruleRef"
          :inline="true"
          :rules="rules"
          :model="addPlayerForm"
          status-icon
          label-suffix=" :"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="姓名" prop="name">
            <el-input v-model="addPlayerForm.name" autocomplete="off" placeholder="请输入选手名称"></el-input>
          </el-form-item>
          <el-form-item label="性别" prop="sex">
            <el-select v-model="addPlayerForm.sex" placeholder="请选择性别">
              <el-option v-for="item in sex" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="年龄" prop="age">
            <el-input v-model.trim="addPlayerForm.age" autocomplete="off" placeholder="请输入选手年龄"></el-input>
          </el-form-item>
          <el-form-item label="证件类型" prop="identityType">
            <el-select v-model="addPlayerForm.identityType" placeholder="请选择证件类型" @change="changeIdentityType($event)">
              <el-option v-for="item in identityType" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="证件号码" prop="cardNum">
            <el-input v-model.trim="addPlayerForm.cardNum" autocomplete="off" placeholder="请输入证件号码"></el-input>
          </el-form-item>
          <el-form-item label="领取状态" prop="status">
            <el-select v-model="addPlayerForm.status" placeholder="请选择领取状态">
              <el-option v-for="item in status" :key="item.id" :label="item.value" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="手机号码" prop="phone">
            <el-input v-model.trim="addPlayerForm.phone" autocomplete="off" placeholder="请输入手机号码"></el-input>
          </el-form-item>
          <el-form-item label="赛事名称" prop="eventsId">
            <el-select v-model="addPlayerForm.eventsId" placeholder="请选择赛事名称" @change="changeEventsAddValue">
              <el-option v-for="item in eventsGroupsList" :key="item.eventsId" :label="item.events" :value="item.eventsId"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="团报ID" prop="groupEnId">
            <el-input v-model.trim="addPlayerForm.groupEnId" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="组别" prop="group">
            <el-select v-model="addPlayerForm.group" placeholder="请选择组别" @click="handlePrompt(addPlayerForm.eventsId)">
              <el-option v-for="item in groups" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="城市" prop="city">
            <el-select v-model="addPlayerForm.city" filterable placeholder="请选择城市">
              <el-option v-for="item in cityDatas" :key="item.label" :label="item.name" :value="item.name"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="号码布" prop="numberPlate">
            <el-input v-model.trim="addPlayerForm.numberPlate" autocomplete="off" placeholder="请输入号码布"> </el-input>
          </el-form-item>
          <el-form-item label="领物窗口" prop="window">
            <el-input v-model.trim="addPlayerForm.window" autocomplete="off" placeholder="请输入领物窗口"></el-input>
          </el-form-item>
          <el-form-item label="存包区" prop="bagStorage">
            <el-input v-model.trim="addPlayerForm.bagStorage" autocomplete="off" placeholder="请输入存包区"></el-input>
          </el-form-item>
          <el-form-item label="衣服尺码" prop="clothesSize">
            <el-input v-model.trim="addPlayerForm.clothesSize" autocomplete="off" placeholder="请输入衣服尺寸"></el-input>
          </el-form-item>
          <el-form-item label="参赛包类型" prop="bagType">
            <el-input v-model.trim="addPlayerForm.bagType" autocomplete="off" placeholder="请输入参赛包类型"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input
              v-model.trim="addPlayerForm.remark"
              :rows="2"
              type="textarea"
              show-word-limit
              :maxlength="16"
              placeholder="请输入备注"
            ></el-input>
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="primary" plain @click="handleAddPlayerConfirm">确认</el-button>
            <el-button type="info" plain @click="resetForm">取消</el-button>
          </span>
        </template>
      </el-dialog>
      <!-- 编辑选手 -->
      <el-dialog title="编辑选手" :close-on-click-modal="false" v-model="EditPlayerDialog" width="690px" center @close="resetForm">
        <el-form
          ref="ruleRef"
          :inline="true"
          :rules="rules"
          :model="editParams"
          status-icon
          label-suffix=" :"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="姓名" prop="name">
            <el-input v-model="editParams.name" autocomplete="off" placeholder="请输入选手名称"></el-input>
          </el-form-item>
          <el-form-item label="性别" prop="sex">
            <el-select v-model="editParams.sex" placeholder="请选择性别" @change="changeSex">
              <el-option v-for="item in sex" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="年龄" prop="age">
            <el-input v-model.trim="editParams.age" autocomplete="off" placeholder="请输入选手年龄"></el-input>
          </el-form-item>
          <el-form-item label="证件类型" prop="identityType">
            <el-select v-model="editParams.identityType" placeholder="请选择证件类型" @change="changeIdentityType($event)">
              <el-option v-for="item in identityType" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="证件号码" prop="cardNum">
            <el-input v-model.trim="editParams.cardNum" autocomplete="off" placeholder="请输入证件号码"></el-input>
          </el-form-item>
          <el-form-item label="领取状态" prop="status">
            <el-select v-model="editParams.status" placeholder="请选领取状态" @change="changeStatus">
              <el-option v-for="item in status" :key="item.id" :label="item.value" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="手机号码" prop="phone">
            <el-input v-model.trim="editParams.phone" autocomplete="off" placeholder="请输入手机号码"></el-input>
          </el-form-item>
          <el-form-item label="赛事名称" prop="eventsId">
            <el-select v-model="editParams.eventsId" placeholder="请选领取状态" @change="changeEventsEditValue">
              <el-option v-for="item in eventsGroupsList" :key="item.eventsId" :label="item.events" :value="item.eventsId"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="团报ID" prop="groupEnId">
            <el-input v-model.trim="editParams.groupEnId" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="组别" prop="group">
            <el-select v-model="editParams.group" placeholder="请选择组别" @change="changeGroup">
              <el-option v-for="item in groups" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="城市" prop="city">
            <el-select v-model="editParams.city" filterable placeholder="请选择城市">
              <el-option v-for="item in cityDatas" :key="item.label" :label="item.name" :value="item.name"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="号码布" prop="numberPlate">
            <el-input v-model.trim="editParams.numberPlate" autocomplete="off" placeholder="请输入号码布">
              <template #append>
                <el-button @click="receiveNum">领取号码布</el-button>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item label="领物窗口" prop="window">
            <el-input v-model.trim="editParams.window" autocomplete="off" placeholder="请输入领物窗口"></el-input>
          </el-form-item>
          <el-form-item label="存包区" prop="bagStorage">
            <el-input v-model.trim="editParams.bagStorage" autocomplete="off" placeholder="请输入存包区"></el-input>
          </el-form-item>
          <el-form-item label="衣服尺码" prop="clothesSize">
            <el-input v-model.trim="editParams.clothesSize" autocomplete="off" placeholder="请输入衣服尺寸"></el-input>
          </el-form-item>
          <el-form-item label="参赛包类型" prop="bagType">
            <el-input v-model.trim="editParams.bagType" autocomplete="off" placeholder="请输入参赛包类型"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input v-model.trim="editParams.remark" :rows="2" type="textarea" show-word-limit :maxlength="16" placeholder="请输入备注"></el-input>
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="primary" plain @click="handleEditPlayerConfirm">确认</el-button>
            <el-button type="info" plain @click="resetForm">取消</el-button>
          </span>
        </template>
      </el-dialog>
      <!-- 打印容器 -->
      <div ref="printContainerRef">
        <PrintContainer v-for="(data, key) in printData" :data="data" :key="key" :style="printData[key + 1] ? { paddingBottom: '40px' } : {}" />
      </div>
    </div>
  </el-container>
</template>

<script lang="ts">
/* eslint-disable */
import { defineComponent, ref, onMounted, watch, unref, reactive, nextTick, computed } from 'vue'
import { ElMessage, ElMessageBox, ElLoading, ElTable } from 'element-plus'
import useClipboard from 'vue-clipboard3'
import paging from '../paging.vue'
import api from '@/api/api'
import router, { routerNames } from '@/router'
import axios from 'axios'
import { useStore } from 'vuex'
import { FormValidate } from '@/utils/format'
import { cityData } from '@/utils/city'
import PrintContainer from './print/print-template.vue'
import Print from './print/print'
import useBindEvent from './player-hooks/event-list'
import { EventType } from '@/utils/enum'
import useGroupList from './common-hooks/all-groups'
export interface PrintData {
  title: string
  name: string
  sex: string
  age: number
  remark?: string
  event: string
  size: string
  code: string
  wNum: string
}
export default defineComponent({
  components: { paging, PrintContainer },
  setup() {
    const printContainerRef = ref<HTMLElement | null>(null)
    const printInstance = ref()
    const cityDatas = ref(cityData)
    const store = useStore()
    const isAdmin = store.state?.roleId === '60fab62af7fefc45ffa1790a'
    const eventName = ref()
    const tableData = ref()
    const { toClipboard } = useClipboard()
    const printData = ref<PrintData[]>([])
    const { bindEvents } = useBindEvent()
    const { eventGroupsList } = useGroupList()
    // console.log('bindEvents', bindEvents)
    // 编辑
    const receiveParam = reactive({
      playerId: '',
      groupId: ''
    })
    // 领取 赛事/状态
    const defaultCollectionValue = ref(2)
    const defaultEventValue = ref('')
    const defaultEvent = ref('')
    const isOneTeam = ref(1)
    const addPlayerDialog = ref(false)
    const groups: any = ref([])
    const ruleRef = ref(null)
    const EditPlayerDialog = ref(false)
    // 总条数
    const pageTotal = ref(0)
    // 当前页数
    const currentPage = ref(1)
    // 当前页数条数
    const currentPageSize = ref(15)
    const state = ref(true)
    // 搜索状态
    const receiveOrNot = ref([
      {
        value: 2,
        label: '全部'
      },
      {
        value: 0,
        label: '未领取'
      },
      {
        value: 1,
        label: '已领取'
      }
    ])
    // 领取状态
    const status = ref([
      {
        id: 0,
        value: '未领取'
      },
      {
        id: 1,
        value: '已领取'
      }
    ])
    // 证件类型
    const identityType = ref([
      {
        id: 1,
        name: '身份证'
      },
      {
        id: 2,
        name: '护照'
      },
      {
        id: 3,
        name: '港澳居民来往内地通行证'
      },
      {
        id: 4,
        name: '台湾居民来往大陆通行证'
      },
      {
        id: 5,
        name: '军官证'
      }
    ])
    // 性别
    const sex = ref([
      {
        id: 1,
        name: '男'
      },
      {
        id: 2,
        name: '女'
      }
    ])
    const addPlayerForm: any = ref({
      name: '',
      sex: null,
      age: null,
      identityType: null,
      cardNum: '',
      group: '',
      phone: '',
      eventsId: '',
      groupEnId: '',
      city: '',
      numberPlate: '',
      window: '',
      bagStorage: '',
      clothesSize: '',
      bagType: '',
      status: null,
      remark: ''
    })
    const copyText = async (text: string) => {
      try {
        await toClipboard(text)
        ElMessage.success('已粘贴到剪切板！')
      } catch (err) {
        ElMessage.error('拷贝失败！')
      }
    }
    const addGroupsList = computed(() => {
      if (defaultEvent.value == '') {
        return eventGroupsList.value
      } else {
        const eventType = bindEvents.value.find(b => b.eventsId == defaultEvent.value)?.type
        // console.log('eventType', eventType, defaultEvent.value)
        return eventGroupsList.value.filter(e => e.type === eventType)
      }
    })
    // 初始化打印dom
    onMounted(() => {
      printInstance.value = new Print(printContainerRef.value)
    })
    // 一键打印
    const printAll = () => {
      const needPrintPlayers = selectedPlayers.value?.length ? selectedPlayers.value : tableData.value
      if (needPrintPlayers?.length > 6 && !selectedPlayers.value?.length) {
        ElMessage.warning('请选择要打印的数据！')
      } else if (!needPrintPlayers?.length) {
        ElMessage.warning('暂无打印数据！')
      } else {
        const data = needPrintPlayers.map(player => ({
          title: player.eventsName,
          name: player.name,
          event: player.group,
          sex: player.sex,
          age: player.age,
          remark: player.remark,
          code: player.cardNum || '',
          size: player.clothesSize,
          wNum: player.note === 0 ? `${player.window}窗口` : player.note === 1 ? player.numberPlate : `${player.window}窗口 ${player.numberPlate}`
        }))
        printData.value = data
        nextTick(() => {
          printInstance.value.writeIframe(printContainerRef.value?.innerHTML)
        })
      }
    }
    // 单独打印
    const printOne = player => {
      const data = {
        title: player.eventsName,
        name: player.name,
        event: player.group,
        sex: player.sex,
        age: player.age,
        remark: player.remark,
        code: player.cardNum || '',
        size: player.clothesSize,
        wNum: player.note === 0 ? `${player.window}窗口` : player.note === 1 ? player.numberPlate : `${player.window}窗口 ${player.numberPlate}`
      }
      printData.value = [data]
      nextTick(() => {
        // console.log('1111111111111', printContainerRef.value?.innerHTML)
        printInstance.value.writeIframe(printContainerRef.value?.innerHTML)
      })
    }
    // 编辑
    const editParams: any = ref({
      playerId: '',
      name: '',
      age: null,
      sex: null,
      identityType: null,
      cardNum: '',
      group: '',
      phone: '',
      eventsId: '',
      groupEnId: '',
      city: '',
      numberPlate: '',
      window: '',
      bagStorage: '',
      clothesSize: '',
      bagType: '',
      status: null,
      remark: ''
    })
    // 获取局部loading元素
    const getElContainer: any = ref(HTMLInputElement)
    // 获取赛事列表
    const eventsGroupsList: any = ref([])
    const pid = ref('') // 上一页最后一个选手的Id
    const prePages = ref(0) // 上次点击的页数
    const currentId: any = ref() // 当前页面的第一个选手Id
    const selectedPlayers = ref([])
    const tableDataRef = ref<InstanceType<typeof ElTable>>()
    const getEventsList = (): any => {
      api.account
        .getAccountMatchList()
        .then((res: any) => {
          eventsGroupsList.value = res
        })
        .catch(() => {
          ElMessage.error('未获取到赛事列表')
        })
    }
    const handleSelectionChange = val => {
      console.log('val', val)
      selectedPlayers.value = val
    }
    const reverseSelect = () => {
      tableData.value.forEach(row => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        tableDataRef.value!.toggleRowSelection(row, undefined)
      })
    }
    const addPlayer = () => {
      addPlayerForm.value.identityType = null
      getEventsList()
      addPlayerDialog.value = true
    }
    // 新增选手
    const handleAddPlayerConfirm = async () => {
      // 点击确认按钮进行二次表单验证
      const form: any = unref(ruleRef)
      if (!form) return
      try {
        await form.validate()
        const { cardNum } = addPlayerForm.value
        console.log(cardNum)
      } catch (error) {
        if (error) {
          return false
        }
      }
      addPlayerForm.value.age = Number(addPlayerForm.value.age)
      const chineseCity: any = []
      const province: any = ''
      const cities: any = addPlayerForm.value.city
      chineseCity.push(province)
      chineseCity.push(cities)
      // 新增
      api.player
        .addPlayer({
          name: addPlayerForm.value.name.trim(),
          sex: addPlayerForm.value.sex,
          age: addPlayerForm.value.age,
          identityType: addPlayerForm.value.identityType,
          cardNum: addPlayerForm.value.cardNum.replace('x', 'X'),
          group: addPlayerForm.value.group,
          phone: addPlayerForm.value.phone,
          eventsId: addPlayerForm.value.eventsId,
          groupEnId: addPlayerForm.value.groupEnId,
          city: chineseCity,
          numberPlate: addPlayerForm.value.numberPlate,
          window: addPlayerForm.value.window,
          bagStorage: addPlayerForm.value.bagStorage,
          clothesSize: addPlayerForm.value.clothesSize,
          bagType: addPlayerForm.value.bagType,
          status: addPlayerForm.value.status,
          remark: addPlayerForm.value.remark
        })
        .then((res: any) => {
          if (res) {
            ElMessage.success('添加成功')
            addPlayerDialog.value = false
            getRefreshPlayerList()
          }
        })
        .catch(err => {
          const result = err.errMsg.split(' ')
          if (result[result.length - 3] === 'cardNum:') {
            ElMessage.error('身份证号码已存在列表，请勿重复操作')
          } else if (result[result.length - 3] === 'phone:') {
            ElMessage.error('手机证号码已存在列表，请勿重复操作')
          } else if (err.errMsg === '错误的证件号码参数类型') {
            ElMessage.error('输入证件号码错误')
          } else {
            ElMessage.error(err.errMsg)
          }
        })
    }
    const changeIdentityType = (val: any) => {
      editParams.value.identityType = val
      addPlayerForm.value.identityType = val
    }
    // 判断是证件号码 还是身份证号码  进行验证规则
    const cardNumReg = (rule: any, value: any, callback: any) => {
      const regId = /(^\d{18}$)|(^\d{17}(\d|X|x)$)/
      if (!value) {
        return callback(new Error('证件号码不能为空'))
      }
      if (editParams.value.identityType === 1) {
        if (!regId.test(value)) {
          callback(new Error('请输入正确的身份号码'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    // 表单验证
    const rules = ref({
      name: [{ trigger: 'blur', required: true }],
      sex: [{ trigger: 'change', required: true, message: '请选择性别' }],
      age: [{ trigger: 'change', required: true, validator: FormValidate.Form().Age }],
      identityType: [{ trigger: 'change', required: true, message: '请选择证件类型' }],
      cardNum: [{ validator: cardNumReg, trigger: 'change', required: true }],
      group: [{ trigger: 'blur', required: true, message: '请选择组别' }],
      status: [{ trigger: 'change', required: true, message: '请选择领取状态' }],
      eventsId: [{ trigger: 'change', required: true, message: '请选择赛事名称' }],
      city: [{ trigger: 'change', required: true, message: '请选择城市' }],
      phone: [{ validator: FormValidate.Form().Tel, trigger: 'blur', required: true }]
    })
    onMounted(() => {
      getPlayerLists()
    })
    // 删除
    const handleDeleteEvent = (row: any) => {
      ElMessageBox.confirm('是否确定删除该选手', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          // 这里请求删除api
          api.player.deletePlayer(row.id).then((res: any) => {
            console.log(res)
            ElMessage.success('删除成功')
            getPlayerLists()
          })
        })
        .catch(() => {
          ElMessage('取消成功')
        })
    }
    const receiveNum = () => {
      api.player
        .getNumber(receiveParam)
        .then((res: any) => {
          editParams.value.numberPlate = res
        })
        .catch(err => {
          ElMessage.error(err.errMsg || '获取失败')
        })
    }
    const resetForm = async () => {
      const form: any = unref(ruleRef)
      form.resetFields()
      addPlayerDialog.value = false
      EditPlayerDialog.value = false
    }
    const changeSex = (val: any) => {
      editParams.value.sex = val
    }
    const changeGroup = (val: any) => {
      editParams.value.group = val
    }
    const changeStatus = (val: any) => {
      editParams.value.status = val
    }
    // 编辑
    const editPlayer = (row: any) => {
      console.log('row', row)
      getEventsList()
      getEventLists(row.eventsId)
      receiveParam.groupId = row.groupId
      receiveParam.playerId = row.id
      const identityTypeS: any = ref()
      switch (row.identityType) {
        case '身份证':
          identityTypeS.value = 1
          break
        case '护照':
          identityTypeS.value = 2
          break
        case '港澳居民来往内地通行证':
          identityTypeS.value = 3
          break
        case '台湾居民来往大陆通行证':
          identityTypeS.value = 4
          break
        case '军官证':
          identityTypeS.value = 5
          break
        default:
          break
      }
      const chineseCity = row.city[1]
      EditPlayerDialog.value = true
      editParams.value.playerId = row.id // 选手Id
      editParams.value.name = row.name
      editParams.value.sex = row.sex === '男' ? 1 : 2
      editParams.value.age = row.age
      editParams.value.identityType = identityTypeS.value
      editParams.value.cardNum = row.cardNum
      editParams.value.group = row.groupId // groupId
      editParams.value.groupEnId = row.groupEnId // groupEnId
      editParams.value.phone = row.phone
      editParams.value.eventsId = row.eventsId
      editParams.value.city = chineseCity
      editParams.value.numberPlate = row.numberPlate
      editParams.value.window = row.window
      editParams.value.bagStorage = row.bagStorage
      editParams.value.clothesSize = row.clothesSize
      editParams.value.bagType = row.bagType
      editParams.value.status = row.status === '已领取' ? 1 : 0
      editParams.value.remark = row.remark
    }
    const handleEditPlayerConfirm = async () => {
      const form: any = unref(ruleRef)
      if (!form) return
      try {
        await form.validate()
        const { cardNum } = editParams.value
        console.log(cardNum)
      } catch (error) {
        if (error) {
          return false
        }
      }
      editParams.value.age = Number(editParams.value.age)
      const chineseCity: any = []
      const province: any = ''
      const cities: any = editParams.value.city
      chineseCity.push(province)
      chineseCity.push(cities)

      // 编辑
      api.player
        .editPlayer({
          playerId: editParams.value.playerId,
          name: editParams.value.name.trim(),
          sex: editParams.value.sex,
          age: editParams.value.age,
          identityType: editParams.value.identityType,
          cardNum: editParams.value.cardNum.replace('x', 'X'),
          group: editParams.value.group,
          phone: editParams.value.phone,
          eventsId: editParams.value.eventsId,
          groupEnId: editParams.value.groupEnId,
          city: chineseCity,
          numberPlate: editParams.value.numberPlate,
          window: editParams.value.window,
          bagStorage: editParams.value.bagStorage,
          clothesSize: editParams.value.clothesSize,
          bagType: editParams.value.bagType,
          status: editParams.value.status,
          remark: editParams.value.remark
        })
        .then((res: any) => {
          if (res) {
            EditPlayerDialog.value = false
            ElMessage.success('修改成功')
            getPlayerLists()
          }
        })
        .catch(err => {
          const result = err.errMsg.split(' ')
          if (result[result.length - 3] === 'cardNum:') {
            ElMessage.error('身份证号码已存在列表，请勿重复操作')
          } else if (result[result.length - 3] === 'phone:') {
            ElMessage.error('手机证号码已存在列表，请勿重复操作')
          } else if (err.errMsg === '错误的证件号码参数类型') {
            ElMessage.error('输入证件号码错误')
          } else {
            ElMessage.error(err.errMsg)
          }
        })
    }
    // 搜索
    const handleGetInputSearchValue = () => {
      state.value = false
      pid.value = '' // 点搜索重置pid
      if (!state.value) {
        nextTick(() => {
          getPlayerLists()
        })
      }
    }
    // 分页
    const handleCurrentChange = (val: any) => {
      prePages.value = currentPage.value
      currentPage.value = val
      if (prePages.value > Math.ceil(pageTotal.value / currentPageSize.value)) {
        return
      }
      if (currentPage.value < prePages.value) {
        pid.value = currentId.value
      } else {
        pid.value = tableData.value[tableData.value.length - 1].id
      }
      if (state.value) {
        getPlayerLists()
      }
    }
    // 监听数据是否为空
    watch(
      eventName,
      nv => {
        if (nv === '') {
          eventName.value = ' '
          getPlayerLists()
        }
        // 扫码出来的身份证号多个 '
        if (nv?.includes("'")) {
          eventName.value = nv.replace("'", '')
        }
      },
      { deep: true, immediate: true }
    )
    // 获取选手列表
    const getPlayerLists = () => {
      api.player
        .getPlayerList({
          name: encodeURIComponent(eventName.value === undefined ? ' ' : eventName.value.replace('x', 'X')),
          size: currentPageSize.value,
          prePage: prePages.value,
          page: currentPage.value,
          pid: encodeURIComponent(pid.value === '' ? ' ' : pid.value),
          status: defaultCollectionValue.value,
          group: encodeURIComponent(defaultEventValue.value === '' ? ' ' : defaultEventValue.value),
          eventsName: encodeURIComponent(defaultEvent.value === '' ? ' ' : defaultEvent.value),
          checkGroupEnroll: isOneTeam.value
        })
        .then((res: any) => {
          state.value = true
          tableData.value = res.players
          pageTotal.value = res.pages.total
          currentId.value = tableData.value[0].id
        })
        .catch(err => {
          console.log(err)
        })
    }
    // 刷新列表
    const getRefreshPlayerList = async () => {
      await api.player
        .getPlayerList({
          name: encodeURIComponent(' '),
          size: currentPageSize.value,
          prePage: 0,
          page: 1,
          pid: encodeURIComponent(' '),
          status: defaultCollectionValue.value,
          group: encodeURIComponent(defaultEventValue.value === '' ? ' ' : defaultEventValue.value),
          eventsName: encodeURIComponent(defaultEvent.value === '' ? ' ' : defaultEvent.value),
          checkGroupEnroll: isOneTeam.value
        })
        .then(async (res: any) => {
          tableData.value = res.players
          pageTotal.value = res.pages.total
        })
        .catch(err => {
          console.log(err)
        })
    }
    // 导入CSV
    const handleClickVSC = () => {
      router.push({ name: routerNames.importCSV, params: { chipPlayerId: 'player' } })
    }
    const exportExcelSelect = () => {
      const loadingInstance = ElLoading.service({ target: getElContainer.value, text: '正在导出EXCEL...' })
      const BASE_URL: string = process.env.VUE_APP_BASE_URL as string
      axios
        .get(`${BASE_URL}/api/v1/player/excel`, {
          headers: {
            token: store.state.token
          },
          responseType: 'blob'
        })
        .then((res: any) => {
          loadingInstance.close()
          console.log(res)
          const BLOB = new Blob([res.data])
          const url = window.URL.createObjectURL(BLOB)
          const link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          link.setAttribute('download', '选手信息.xlsx')
          document.body.appendChild(link)
          link.click()
        })
    }
    const handlePrompt = (eventsIds: any) => {
      if (!eventsIds) {
        ElMessage.error('请先绑定的赛事')
      }
    }
    // 新增 某场赛事下面的组别
    const changeEventsAddValue = (val: any) => {
      addPlayerForm.value.group = ''
      if (val) {
        getEventLists(val)
      } else {
        ElMessage.error('请先绑定的赛事')
      }
    }
    // 编辑 某场赛事下面的组别
    const changeEventsEditValue = (val: any) => {
      editParams.value.group = ''
      getEventLists(val)
    }
    // 组别
    const getEventLists = (eventsId: any) => {
      api.player.getEventsToGroups(eventsId).then((res: any) => {
        groups.value = res
      })
    }
    // 序列号自增
    const tableIndex = index => {
      return (currentPage.value - 1) * currentPageSize.value + index + 1
    }
    return {
      eventName,
      tableData,
      addGroupsList,
      tableDataRef,
      printData,
      printContainerRef,
      bindEvents,
      isAdmin,

      receiveOrNot,
      defaultCollectionValue,
      defaultEventValue,
      defaultEvent,
      isOneTeam,
      handleSelectionChange,
      reverseSelect,
      // 新增
      addPlayer,
      status,
      addPlayerForm,
      editPlayer,
      addPlayerDialog,
      handleAddPlayerConfirm,
      identityType,
      groups,
      sex,
      receiveNum,
      handleDeleteEvent,
      print,
      copyText,
      printOne,
      printAll,

      pageTotal,
      currentPage,
      currentPageSize,
      handleCurrentChange,
      handleGetInputSearchValue,

      // 导出excel
      exportExcelSelect,
      handleClickVSC,
      ruleRef,
      rules,
      editParams,
      handleEditPlayerConfirm,
      EditPlayerDialog,
      changeSex,
      changeIdentityType,
      changeGroup,
      changeStatus,
      resetForm,
      eventsGroupsList,
      getElContainer,
      handlePrompt,
      changeEventsAddValue,
      changeEventsEditValue,
      cityDatas,
      tableIndex
    }
  }
})
</script>
<style lang="scss" scoped>
.playerManagement {
  width: 100%;
  .playerManagement-title {
    font-size: 22px;
    font-weight: 600;
    color: #222222;
  }
  .table-button {
    padding-bottom: 5px;
  }
  .row-bg {
    width: 100%;
    display: flex;
    background-color: #fff;
    height: 90px;
    padding: 20px;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    box-shadow: 0px 0.5px 10px #ddd;
    .playerManagement-search {
      display: flex;
    }
    .el-button {
      margin: 0px 5px 0px 10px;
    }
    .el-input {
      width: 240px;
    }
    .status,
    .event {
      display: flex;
      align-items: center;
      margin: 0 10px;
      font-size: 13px;
      .el-select {
        width: 100px;
        margin: 0px 5px;
      }
    }
    .event .el-select {
      width: 150px;
    }
  }
  .toTimer {
    color: #52c41a;
  }
  .demo-ruleForm {
    :deep .el-input {
      width: 200px;
    }
    :deep textarea {
      width: 510px;
      font-family: inherit;
      resize: none;
    }
  }
}

.matchStyle {
  .el-input {
    width: 120px;
  }
  .el-button {
    margin: 10px 0px 0 10px;
  }
}
.dialog-footer {
  .el-button {
    margin-left: 40px;
  }
}
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-card {
  min-height: calc(100vh - 238px);
  position: relative;
  .el-table {
    color: #909399;
    font-size: 14px;
  }
  .fixedRightBottom {
    position: absolute;
    bottom: 15px;
    right: 15px;
  }
}
:deep(.el-table .cell) {
  padding: 0px !important;
}
.editStyle {
  color: #2c88fe;
  margin-right: 20px;
  cursor: pointer;
}
.deleteStyle {
  color: #f24b53;
  cursor: pointer;
}
:deep(.el-dialog__header) {
  padding: 20px 20px 15px;
  background-color: #eff7ff;
}
</style>
