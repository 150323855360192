<template>
  <div class="container">
    <img src="@/assets/logo1.svg" />
    <div class="screen">
      <div>
        <div class="fromLogin">
          <div class="login-box">
            <div class="title">{{ title }}</div>
            <el-form :model="loginForm" ref="loginForm" class="demo-dynamic">
              <el-form-item prop="username" :rules="[{ required: true, trigger: 'blur', validator: FormValidate.Form().UserName }]">
                <el-input
                  class="loginItemInput"
                  prefix-icon="el-icon-user"
                  size="medium"
                  placeholder="账户"
                  v-model.trim="loginForm.username"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item prop="password" :rules="[{ required: true, trigger: 'blur', validator: FormValidate.Form().Password }]">
                <el-input
                  class="loginItemInput"
                  prefix-icon="el-icon-lock"
                  size="medium"
                  placeholder="密码"
                  v-model.trim="loginForm.password"
                  type="password"
                  @keyup.enter="handleClick('loginForm')"
                  clearable
                  show-password
                ></el-input>
              </el-form-item>
              <el-form-item>
                <div class="btn-wrapper">
                  <el-button @click="handleClick('loginForm')">登录</el-button>
                </div>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <div class="login_bg">
        <div class="login_text"></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { FormValidate } from '@/utils/format'
export default defineComponent({
  props: {
    logoPath: String,
    title: String
  },
  emits: ['handleLoginEvent'],
  data() {
    return {
      loginForm: {
        username: '',
        password: ''
      },
      FormValidate
    }
  },
  methods: {
    handleClick(formName: any) {
      (this as any).$refs[formName].validate((valid: any) => {
        if (valid) {
          this.$emit('handleLoginEvent', this.loginForm)
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
})
</script>

<style lang="scss" scoped>
.container {
  overflow: hidden;
  img {
    position: fixed;
    left: 40px;
    top: 50px;
  }
  .login_bg {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background: url('../../assets/images/login_bg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .login_text {
      width: 60%;
      height: 35vh;
      background-image: url('../../assets/images/login_text.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: right;
      margin-top: -10vh;
      margin-right: -6vw;
    }
  }
}
.screen {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
:deep(.login-box .el-icon-user):before {
  color: #2e88ff;
}
:deep(.login-box .el-icon-lock):before {
  color: #2e88ff;
}
.login-box {
  text-align: center;
  margin-left: 60px;
  .title {
    text-align: center;
    font-size: 23px;
    letter-spacing: 2px;
    color: #758efe;
    font-weight: 500;
    margin-bottom: 20px;
  }
  .loginItemInput {
    width: 300px;
    margin-bottom: 10px;
  }
  .logonFlex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .automaticLogon {
      width: 80px;
      display: flex;
      justify-content: space-between;
      padding: 25px 0px;
      :deep(.el-checkbox__inner) {
        width: 16px;
        height: 16px;
      }
      .automaticLogonFontSize {
        font-size: 13px;
        color: #4c4c4c;
      }
    }
    .forgetThePasswordFontSize {
      font-size: 14px;
      color: #5d78ff;
    }
  }
}

.btn-wrapper {
  text-align: center;
  .el-button--mini {
    width: 300px;
    height: 36px;
    color: #fbfcff;
    font-size: 17px;
    letter-spacing: 5px;
    border: none;
    background-image: linear-gradient(to bottom right, #52dcff, #549dfe);
  }
}
</style>
