/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import io from 'socket.io-client'
const deviceId = sessionStorage.getItem('deviceId')
// const BASE_URL:any = `http://192.168.1.24:3000/?cid=${deviceId}` // 开发
// const BASE_URL:any = `http://api.goods.inner.578g.com/?cid=${deviceId}` // 测试内网
const BASE_URL:any = `http://api.goods.outest.imagiclouds.com/?cid=${deviceId}` // 测试 外网
export const socket = io(
  BASE_URL,
  {
    autoConnect: false // 是否手动链接socket
    // transports: ['websocket']
  }
)

// 建立连接
socket.on('connect', () => {
  console.log('socket connected====连接成功')
})
// 断开连接
socket.on('disconnect', () => {
  console.log('socket disconnected===已断开链接')
})
// 连接错误
socket.on('connect_error', () => {
  console.log('connection error---')
  // connectSocket()
})
socket.on('reconnect', () => {
  console.log('重新连接中=====')
})
// 连接socket
export function connectSocket () {
  socket.open()
}
// 断开socket
export function disconnectSocket () {
  socket.close()
}
