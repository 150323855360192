import common from '@/common/common'
import { createRouter, createWebHashHistory, Router, RouteRecordRaw } from 'vue-router'
import store from '../store'
export enum routerNames {
  Test = 'Test',
  Login = 'Login',
  importCSV = 'importCSV',
  chipManagement = 'chipManagement',
  materialDistribution = 'materialDistribution',
  playerManagement = 'playerManagement',
  eventManagement = 'eventManagement',
  accountManagement = 'accountManagement',
  groupManagement = 'groupManagement',
  deviceManagement = 'deviceManagement',
  Workspace = 'Workspace',
  reservedNumber = 'ReservedNumber',
  groupList = 'GroupList'
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/test',
    name: routerNames.Test, // 测试页面
    component: () => import(/* webpackChunkName: "login" */ '../test/print.vue')
  },
  {
    path: '/login',
    name: routerNames.Login,
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/',
    name: routerNames.Workspace,
    meta: { needLogin: true },
    redirect: { name: 'materialDistribution' }, // 进入首页重定向到制定页面
    component: () => import(/* webpackChunkName: "workspace" */ '../views/Workspace.vue'),
    children: [
      {
        path: '/chipManagement',
        name: routerNames.chipManagement,
        meta: { needLogin: true },
        component: () => import(/* webpackChunkName: "chipManagement" */ '../common/components/contentComponents/chipManagement.vue')
      },
      {
        path: '/eventManagement',
        name: routerNames.eventManagement,
        meta: { needLogin: true },
        component: () => import(/* webpackChunkName: "eventManagement" */ '../common/components/contentComponents/eventManagement.vue')
      },
      {
        path: '/materialDistribution',
        name: routerNames.materialDistribution,
        meta: { needLogin: true },
        component: () => import(/* webpackChunkName: "materialDistribution" */ '../common/components/contentComponents/materialDistribution.vue')
      },
      {
        path: '/playerManagement',
        name: routerNames.playerManagement,
        component: () => import(/* webpackChunkName: "playerManagement" */ '../common/components/contentComponents/playerManagement.vue')
      },
      {
        path: '/accountManagement',
        name: routerNames.accountManagement,
        meta: { needLogin: true },
        component: () => import(/* webpackChunkName: "accountManagement" */ '../common/components/contentComponents/accountManagement.vue')
      },
      {
        path: '/groupList',
        name: routerNames.groupList,
        meta: { needLogin: true },
        component: () => import(/* webpackChunkName: "accountManagement" */ '../common/components/contentComponents/groupList.vue')
      },
      {
        path: '/groupManagement',
        name: routerNames.groupManagement,
        meta: { needLogin: true },
        component: () => import(/* webpackChunkName: "groupManagement" */ '../common/components/contentComponents/groupManagement.vue')
      },
      {
        path: '/deviceManagement',
        name: routerNames.deviceManagement,
        meta: { needLogin: true },
        component: () => import(/* webpackChunkName: "deviceManagement" */ '../common/components/contentComponents/deviceManagement.vue')
      },
      {
        path: '/importCSV/:chipPlayerId',
        name: routerNames.importCSV,
        component: () => import(/* webpackChunkName: "importCSV" */ '../common/components/importCSV.vue')
      }
    ]
  }
]
const router: Router = createRouter({
  history: createWebHashHistory(),
  routes
})
router.beforeEach(async to => {
  // 如果目的url为/login，用户已登录则跳至/workspace；用户未登录则停留在login页面
  if (to.name === routerNames.Login) {
    if (await isUserLoggedIn()) {
      return { name: routerNames.Workspace }
    } else {
      return true
    }
  }
  // 如果目的url不为/login且当前页面需要用户已登陆，用户已登陆则继续导航至目标页，用户未登录则跳至登录页面
  // 如果目的url不为/login且当前页面不需要用户已登陆，则继续导航至目标页。
  if (to.meta.needLogin) {
    if (await isUserLoggedIn()) {
      return true
    } else {
      return { name: routerNames.Login }
    }
  }
})
async function isUserLoggedIn(): Promise<boolean> {
  const hasLogin = await store.getters.hasLogin(common.keySysAdminSession)
  const isValid = store.getters.isValid
  if (hasLogin) {
    if (isValid) {
      return true
    } else {
      await store.dispatch('restoreSessionFromLocalStorage', { keySysAdminSession: common.keySysAdminSession })
      if (store.getters.isValid) {
        return true
      } else {
        return false
      }
    }
  }
  return false
}
export default router
