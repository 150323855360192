<template>
  <div>
    <div>
      <span
        ><span style="color:#aaa">赛事名称：</span><span>{{ title || '全部' }}</span></span
      >
      <span style="margin-left:20px"
        ><span style="color:#aaa">赛事编号：</span><span>{{ eventsId || '全部' }}</span></span
      >
      <div class="playerManagement-title">
        <!-- 搜索 -->
        <div class="searchFlex" style="margin-top:20px">
          <div class="playerManagement-search">
            <el-input
              ref="searchRef"
              placeholder="请输入证件号码"
              v-model.trim="changeSearchValue"
              :clearable="true"
              @focus="
                e => {
                  changeSearchValue = ''
                }
              "
            ></el-input>
            <el-button type="primary" style="letter-spacing:5px" @click="handleSearchButton">搜索</el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="playerManagement" ref="getElContainer">
      <div>
        <el-card v-if="isSHowInfo">
          <div class="centerBox">
            <div class="centerBox-title">选手信息</div>
            <span class="cancelBox" @click="handleCancel">X</span>
            <table width="100%" style="margin-top:20px">
              <tr class="classNameTr">
                <td class="classNameTd">姓名：</td>
                <td class="classNameValue">{{ playerInformationValue.name }}</td>
                <td class="classNameTd">性别：</td>
                <td class="classNameValue">{{ sexType }}</td>
              </tr>
              <tr class="classNameTr">
                <td class="classNameTd">证件类型：</td>
                <td class="classNameValue">{{ documentType }}</td>
                <td class="classNameTd">证件号码：</td>
                <td class="classNameValue">{{ playerInformationValue.cardNum }}</td>
              </tr>
              <tr class="classNameTr">
                <td class="classNameTd">组别：</td>
                <td class="classNameValue">{{ playerInformationValue.group }}</td>
                <td class="classNameTd">手机号码：</td>
                <td class="classNameValue">{{ playerInformationValue.phone }}</td>
              </tr>
              <tr class="classNameTr">
                <td class="classNameTd">号码布：</td>
                <td class="classNameValue">
                  <el-input v-if="!isBound" ref="numberPlateFocus" :type="numTypeValue" @change="handleInput" v-model="tempVal"></el-input>
                  <span v-else>{{ tempVal }}</span>
                </td>
                <td class="classNameTd">领物窗口：</td>
                <td class="classNameValue">{{ playerInformationValue.window }}</td>
              </tr>
              <tr class="classNameTr">
                <td class="classNameTd">衣服尺码：</td>
                <td class="classNameValue">{{ playerInformationValue.clothesSize }}</td>
                <td class="classNameTd">参赛包类型：</td>
                <td class="classNameValue">{{ playerInformationValue.bagType }}</td>
              </tr>
              <tr class="classNameTr">
                <td class="classNameTd">存包区：</td>
                <td class="classNameValue">{{ playerInformationValue.bagStorage }}</td>
                <td class="classNameTd">领物状态：</td>
                <td class="classNameValue">
                  <el-tag :type="claimStatus == '已领取' ? 'success' : 'danger'" size="large">{{ claimStatus }}</el-tag>
                </td>
              </tr>
              <tr class="classNameTr">
                <td class="classNameTd">人脸状态：</td>
                <td class="classNameValue">
                  <el-tag :type="faceStatus == '已录入' ? 'success' : 'danger'" size="large">{{ faceStatus }}</el-tag>
                </td>
                <td class="classNameTd"></td>
                <td class="classNameTd"></td>
              </tr>
            </table>

            <div v-if="!isBound" class="confirmButtonStyle">
              <el-button size="medium" type="primary" @click="handleConfirmButton">保存</el-button>
            </div>
            <!-- 步骤二信息，只有已绑定号码布的人才展示 -->
            <template v-if="isBound">
              <div class="centerBox-title">验证号码布</div>
              <div class="directoryFlex">
                <span class="directory">验证号码布：</span>
                <el-input
                  v-if="!isNumberPlates"
                  ref="chipFocus"
                  :type="typeValue"
                  @change="handleCheckNumber"
                  v-model.trim="isNumberPlatesValue"
                ></el-input>
                <span v-else class="directoryValue">{{ isNumberPlatesValue }}</span>
              </div>
              <div v-if="isContrast" class="contrast">
                <span v-if="contrast" :class="['contrast' ? 'contrastGreen' : 'contrastRad']"><span class="iconsGreen">√</span> 号码布验证成功</span>
                <span v-else :class="['contrast' ? 'contrastRad' : 'contrastGreen']"><span class="icons">X</span> 号码布验证失败</span>
              </div>
            </template>
          </div>
        </el-card>
      </div>
    </div>
    <el-dialog title="错误提示" v-model="errorConfirmation" width="25%" :show-close="false" center>
      <span>{{ errMsg }}</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button size="medium" @click="handleErrorConfirmation">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent, nextTick, onMounted, ref, watch, computed } from 'vue'
import api from '@/api/api'
import { useStore } from 'vuex'
import { ElLoading, ElMessage, ElMessageBox, ElTag } from 'element-plus'
import { socket, connectSocket } from '@/utils/socket'
import { FaceAxios } from '@/http/index'
export default defineComponent({
  components: { ElTag },
  setup() {
    const store = useStore()
    const searchRef = ref()
    const pickingType = store.state.operation
    const title = ref('')
    title.value = store.state.events
    const tempVal = ref()
    const isSHowInfo = ref(false)
    const playerInformationValue: any = ref({
      name: '',
      sex: '',
      cardNum: '',
      group: '',
      phone: '',
      numberPlate: '',
      window: '',
      clothesSize: '',
      bagType: '',
      identityType: '',
      bagStorage: '',
      status: ''
    })
    const documentType = ref()
    const sexType = ref()
    const claimStatus = ref()
    const faceStatus = ref('未录入')
    const changeSearchValue = ref('')
    const errMsg = ref('')
    const errorConfirmation = ref(false)
    const isBound = computed(() => !!playerInformationValue.value.numberPlate)
    onMounted(() => {
      handleSearchButton()
      init()
      searchRef.value.focus()
    })
    watch(changeSearchValue, newVal => {
      if (newVal.length === 18) {
        handleSearchButton()
      }
      // 扫码出来的身份证号多个 '
      if (newVal?.includes("'")) {
        changeSearchValue.value = newVal.replace("'", '')
      }
    })
    watch(isSHowInfo, newVal => {
      if (!newVal && searchRef.value) {
        searchRef.value.focus()
      }
    })
    watch(playerInformationValue, val => {
      // console.log('val号码薄', val.numberPlate)
      tempVal.value = val.numberPlate
    })
    const identityType = ref()
    const cardNum = ref()
    // 获取焦点定义的变量
    const numberPlateFocus: any = ref(null as HTMLInputElement | null)
    const chipFocus: any = ref(null as HTMLInputElement | null)
    // 芯片号
    const chipNo = ref()
    const numChipNo = ref()
    // 使用change事件只触发一次获取数据
    const typeValue = ref('password')
    const numTypeValue = ref('password')
    // 搜索
    const isNumberPlate = ref('')
    const isNumberPlates = ref(false)
    const isNumberPlatesValue = ref('')
    const contrast = ref(false)
    const isContrast = ref(false)
    // 获取局部loading元素
    const getElContainer: any = ref(HTMLInputElement)
    const handleSearchButton = () => {
      if (changeSearchValue.value === '') {
        return false
      }
      isNumberPlatesValue.value = ''
      tempVal.value = ''
      isNumberPlates.value = false
      contrast.value = false
      isContrast.value = false
      api.delivery
        .getDeliveryList(encodeURIComponent(changeSearchValue.value.replace('x', 'X')))
        .then(async (res: any) => {
          // 进来就获取焦点，但是不应该在搜索里面是socket返回数据的时候获取焦点
          const identityTypeS: any = ref()
          switch (res.identityType) {
            case 1:
              identityTypeS.value = '身份证'
              break
            case 2:
              identityTypeS.value = '护照'
              break
            case 3:
              identityTypeS.value = '港澳居民来往内地通行证'
              break
            case 4:
              identityTypeS.value = '台湾居民来往大陆通行证'
              break
            case 5:
              identityTypeS.value = '军官证'
              break
            default:
              break
          }
          documentType.value = identityTypeS.value
          sexType.value = res.sex === 1 ? '男' : '女'
          claimStatus.value = res.status === false ? '未领取' : '已领取'
          playerInformationValue.value = await res
          identityType.value = res.identityType
          isNumberPlate.value = res.numberPlate
          cardNum.value = res.cardNum
          isSHowInfo.value = true
          await nextTick(() => {
            if (res.numberPlate) {
              chipFocus.value && chipFocus.value.focus()
            } else {
              numberPlateFocus.value && numberPlateFocus.value.focus()
            }
            numTypeValue.value = res.numberPlate ? 'text' : 'password'
            typeValue.value = 'password'
          })
          checkFacePic(res.identityType)
        }).catch(err => {
          ElMessage.error(err.errMsg)
        })
    }
    const checkFacePic = (it) => {
      FaceAxios.post('/api/face/identify/search', {
        card_type: it,
        card_sn: changeSearchValue.value.replace('x', 'X'),
        saas_match_id: store.state.faceMatchId
      }).then(res => {
        if (res.data.data?.face_pic) {
          faceStatus.value = '已录入'
        } else {
          faceStatus.value = '未录入'
          if (store.state.isCheckFace) {
            ElMessageBox.confirm('选手未录入人脸', '警告', {
              confirmButtonText: '继续',
              showCancelButton: false,
              type: 'warning'
            })
              .then(() => {
                console.log('继续')
              })
              .catch(() => {
                // catch error
              })
          }
        }
      })
    }
    // socket实时获取后端返回的信息弹框
    const init = () => {
      // 监听服务端数据
      socket.on('delivery', async (cid: any) => {
        await nextTick(() => {
          typeValue.value = 'password'
          numTypeValue.value = 'password'
          chipFocus.value && chipFocus.value.focus()
        })
        isSHowInfo.value = true
        // 存在号码布存在禁用输入框禁用保存
        if (cid.numberPlate) {
          nextTick(() => {
            typeValue.value = 'password'
            numTypeValue.value = 'password'
            chipFocus.value && chipFocus.value.focus()
          })
          isNumberPlatesValue.value = ''
          isNumberPlates.value = false
          contrast.value = false
          isContrast.value = false
          isNumberPlate.value = cid.numberPlate
          const identityTypeS: any = ref()
          switch (cid.identityType) {
            case 1:
              identityTypeS.value = '身份证'
              break
            case 2:
              identityTypeS.value = '护照'
              break
            case 3:
              identityTypeS.value = '港澳居民来往内地通行证'
              break
            case 4:
              identityTypeS.value = '台湾居民来往大陆通行证'
              break
            case 5:
              identityTypeS.value = '军官证'
              break
            default:
              break
          }
          documentType.value = identityTypeS.value
          sexType.value = cid.sex === 1 ? '男' : '女'
          claimStatus.value = cid.status === false ? '未领取' : '已领取'
          playerInformationValue.value = cid
          identityType.value = cid.identityType
          cardNum.value = cid.cardNum
        } else {
          tempVal.value = ''
          isNumberPlate.value = cid.numberPlate
          await nextTick(() => {
            chipFocus.value && chipFocus.value.focus()
          })
          const identityTypeS: any = ref()
          switch (cid.identityType) {
            case 1:
              identityTypeS.value = '身份证'
              break
            case 2:
              identityTypeS.value = '护照'
              break
            case 3:
              identityTypeS.value = '港澳居民来往内地通行证'
              break
            case 4:
              identityTypeS.value = '台湾居民来往大陆通行证'
              break
            case 5:
              identityTypeS.value = '军官证'
              break
            default:
              break
          }
          documentType.value = identityTypeS.value
          sexType.value = cid.sex === 1 ? '男' : '女'
          claimStatus.value = cid.status === false ? '未领取' : '已领取'
          playerInformationValue.value = cid
          identityType.value = cid.identityType
          cardNum.value = cid.cardNum
          await nextTick(() => {
            chipFocus.value && chipFocus.value.focus()
          })
        }
      })
    }
    // 验证号码薄
    const handleCheckNumber = val => {
      if (val === '') {
        return false
      }
      chipNo.value = val
      api.delivery
        .chipOn(val)
        .then(async (res: any) => {
          isNumberPlatesValue.value = JSON.parse(JSON.stringify(res))
          isNumberPlates.value = true
          typeValue.value = 'text'
          chipFocus.value && chipFocus.value.blur()
          // console.log('校验号码布返回信息====', playerInformationValue.value.numberPlate, res)
          if (playerInformationValue.value.numberPlate === res) {
            isContrast.value = true
            contrast.value = true
          } else {
            isContrast.value = true
            contrast.value = false
          }
        })
        .catch(err => {
          ElMessage.error(err.errMsg)
        })
    }
    // 修改号码布
    const handleInput = (val: any) => {
      if (val === '') {
        return false
      }
      numChipNo.value = val
      api.delivery
        .chipOn(val)
        .then(async (res: any) => {
          tempVal.value = JSON.parse(JSON.stringify(res))
          numTypeValue.value = 'text'
          // typeValue.value = 'text'
          numberPlateFocus.value && numberPlateFocus.value.blur()
        })
        .catch(err => {
          ElMessage.error(err.errMsg)
        })
    }
    // 保存传递芯片号和号码布
    const handleConfirmButton = async () => {
      if (!tempVal.value) {
        ElMessage.warning('请输入号码布！')
        numberPlateFocus.value && numberPlateFocus.value.focus()
        return
      }
      const loadingInstance = ElLoading.service({ target: getElContainer.value })
      const confirmParams = {
        identityType: identityType.value,
        cardNum: cardNum.value,
        numberPlate: tempVal.value,
        chipNo: numChipNo.value ? numChipNo.value : ''
      }
      await nextTick(() => {
        setTimeout(() => {
          api.delivery
            .bindingInfo(confirmParams)
            .then(async (resp: any) => {
              console.log('res', resp)
              const code = resp.code
              if (code === 0) {
                // documentType.value = ''
                // sexType.value = ''
                // claimStatus.value = ''
                // playerInformationValue.value = ''
                // changeSearchValue.value = ''
                playerInformationValue.value.numberPlate = tempVal.value
                loadingInstance.close()
                ElMessage.success('绑定成功')
                claimStatus.value = '已领取'
                isSHowInfo.value = false
              } else if (code === 1) {
                loadingInstance.close()
                errMsg.value = '该号码布已绑定用户'
                errorConfirmation.value = true
              } else if (code === 2) {
                loadingInstance.close()
                errMsg.value = '该用户已绑定号码布'
                errorConfirmation.value = true
              } else if (code === 3) {
                loadingInstance.close()
                errMsg.value = '该用户不存在'
                errorConfirmation.value = true
              } else if (code === 4) {
                loadingInstance.close()
                errMsg.value = '未找到对应芯片号与号码布'
                errorConfirmation.value = true
              } else if (code === 5) {
                loadingInstance.close()
                errMsg.value = '获取芯片号失败'
                errorConfirmation.value = true
              } else if (code === 6) {
                loadingInstance.close()
                errMsg.value = '其它失败'
                errorConfirmation.value = true
              } else if (code === 9) {
                loadingInstance.close()
                errMsg.value = resp.message
                errorConfirmation.value = true
              } else if (code === 10) {
                loadingInstance.close()
                errMsg.value = '当前号码布与选手组别不一致，请检查选手小票是否为当前窗口'
                errorConfirmation.value = true
              } else if (code === 11) {
                loadingInstance.close()
                errMsg.value = '当前号码布与选手性别不一致，请检查选手小票是否为当前窗口'
                errorConfirmation.value = true
              }
            })
            .catch(err => {
              loadingInstance.close()
              errMsg.value = err.errMsg
              errorConfirmation.value = true
            })
        }, 1000)
      })
    }
    watch(
      () => tempVal.value,
      () => {
        if (tempVal.value === '') {
          numTypeValue.value = 'password'
        }
      },
      { deep: true, immediate: true }
    )

    // 判断是否有绑定芯片号过程 有的话直接显示按钮进行绑定  没有则禁止保存按钮
    const handleCancel = () => {
      changeSearchValue.value = ''
      isSHowInfo.value = false
    }
    const handleErrorConfirmation = async () => {
      errorConfirmation.value = false
      await nextTick(() => {
        chipFocus.value && chipFocus.value.focus()
      })
    }
    // 是否链接socket
    const getDeviceId = store.state.deviceId
    const eventsId = store.state.eventsId
    // if (getDeviceId) {
    //   if (process.env.NODE_ENV === 'development') {
    //     // 仅开发模式可用
    //     connectSocket()
    //   } else if (process.env.NODE_ENV === 'production') {
    //     connectSocket()
    //   }
    // }
    if (getDeviceId) {
      // 仅开发模式可用
      connectSocket()
    }
    return {
      tempVal,
      pickingType,
      isBound,
      playerInformationValue,
      handleConfirmButton,
      title,
      changeSearchValue,
      searchRef,
      handleSearchButton,
      numChipNo,
      documentType,
      sexType,
      claimStatus,
      faceStatus,
      isSHowInfo,
      numberPlateFocus,
      chipFocus,
      handleCancel,
      handleCheckNumber,
      handleInput,
      typeValue,
      numTypeValue,
      eventsId,
      errMsg,
      errorConfirmation,
      handleErrorConfirmation,
      isNumberPlate,
      isNumberPlates,
      isNumberPlatesValue,
      getElContainer,
      contrast,
      isContrast
    }
  }
})
</script>
<style lang="scss" scoped>
.playerManagement {
  // height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.searchFlex {
}
.el-card {
  width: 900px;
  min-width: 672px;
  min-height: 460px;
  text-align: center;
  margin-top: 25px;
  .el-table {
    color: #909399;
    font-size: 14px;
  }
}
.centerBox {
  color: #58696c;
  position: relative;
  .cancelBox {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 16px;
    color: #aaa;
    display: inline-block;
    cursor: pointer;
    &:hover {
      cursor: pointer;
      color: #2c88fe;
    }
  }
  .centerBox-title {
    font-size: 21px;
    height: 40px;
    line-height: 40px;
    background-color: #eff7ff;
    font-weight: 600;
    color: #222222;
    position: relative;
    .step-text {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 10px;
    }
  }
  .classNameTr {
    text-align: center;
    .classNameTd {
      text-align: start;
      height: 36px;
      line-height: 36px;
      display: inline-block;
      width: 120px;
      // padding: 10px;
      color: #222222;
      margin-left: 40px;
    }
    .classNameValue {
      text-align: start;
      display: inline-block;
      width: 150px;
    }
  }
  .confirmButtonStyle {
    margin: 16px;
    margin-top: 16px;
    display: flex;
    justify-content: center;
  }
}
.playerManagement-search {
  width: 100%;
  background: #fff;
  height: 90px;
  line-height: 90px;
  :deep(.el-input--mini .el-input__inner) {
    margin-left: 20px;
    width: 180px;
    user-select: none;
  }
}
.el-button {
  margin-left: 10px;
}
.el-input {
  width: 200px;
}
:deep(.el-input--mini .el-input__inner) {
  height: 30px;
  width: 155px;
  line-height: 40px;
  user-select: none;
}
:deep(.el-input-group__append, .el-input-group__prepend) {
  color: #409eff;
  background: #ecf5ff;
  &:hover {
    background-color: #409eff;
    color: #fff;
    cursor: pointer;
    border-color: #409eff;
  }
}
:deep(.el-dialog__header) {
  background: #eff7ff;
  padding: 20px 20px 10px;
  border-bottom: 1px solid #eef7ff;
}
:deep(.el-dialog--center .el-dialog__body) {
  padding: 25px 25px 30px;
  height: 70px;
  text-align: center;
  font-weight: 700;
  line-height: 50px;
  font-size: 20px;
}
.directoryFlex {
  .el-input .el-input--mini {
    width: 88px;
  }
  margin-top: 20px;
  :deep(.el-input--mini .el-input__inner) {
    width: 180px;
  }
  .directory {
    display: inline-block;
    width: 84px;
    height: 27px;
    opacity: 1;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: #7e7979;
    line-height: 26px;
    vertical-align: middle;
  }
  .directoryValue {
    display: inline-block;
    text-align: center;
    font-size: 20px;
    font-weight: 900;
    height: 32px;
    color: #262626;
    line-height: 32px;
    vertical-align: middle;
    width: 202px;
    background: #f2f2f2;
  }
}
.contrast {
  // margin-top: 20px;
  font-size: 20px;
  font-weight: 900;
}
.contrastGreen {
  text-align: start;
  display: inline-block;
  width: 287px;
  background: #b7eb8f;
  font-size: 14px;
  height: 32px;
  line-height: 32px;
  color: #52c41a;
  background: #f6ffed;
  border: 1px solid #b7eb8f;
}
.contrastRad {
  text-align: start;
  display: inline-block;
  width: 287px;
  background: #ccc;
  font-size: 14px;
  height: 32px;
  line-height: 32px;
  color: red;
  background: #fff1f0;
  border: 1px solid #ffa39e;
}
.iconsGreen {
  display: inline-block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  font-size: 11px;
  vertical-align: middle;
  border-radius: 50%;
  background: rgb(22, 219, 22);
  color: #fff;
  margin: 0px 10px;
  text-align: center;
}
.icons {
  display: inline-block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  font-size: 11px;
  vertical-align: middle;
  border-radius: 50%;
  background: red;
  color: #fff;
  margin: 0px 10px;
}
</style>
