
import { defineComponent } from 'vue'
export default defineComponent({
  emits: ['handleCurrentChange'],
  props: {
    pageTotal: {
      type: Number,
      required: true
    },
    currentPageSize: {
      type: Number,
      required: true
    }
  },
  setup (props, context) {
    // 当前选中页数
    const handleCurrentChange = (val:any) => {
      context.emit('handleCurrentChange', val)
    }
    return {
      handleCurrentChange
    }
  }
})
