const username = /^[a-zA-Z0-9_@\u4e00-\u9fa5]{1,64}$/ // 登录用户名1-64字符 中文字母数字下划线,不能为空和空格
const eventsId = /^[a-z0-9]{1,64}$/ // 赛事Id1-64字符 中文字母数字下划线,不能为空和空格
const password = /^[^\u4e00-\u9fa5 ]{6,15}$/ // 密码 6-15位不能为空
const regId = /^\d{11}$/
const tel = /^\d{11}$/
const equipmentSerialNumber = /^(?!([a-zA-Z]+|\d+)$)[a-zA-Z\d]{15,17}$/ // 设备序列号 必须数字加字母
const editPassword = /^$|[^\u4e00-\u9fa5 ]{6,15}$/
const age = /^[1-9][0-9]?$/
const accuracy = /^(1|0(\.\d{1,2})?)$/
export const FormValidate = (function () {
  // eslint-disable-next-line
  function FormValidate() {}
  // From表单验证规则  可用于公用的校验部分
  FormValidate.Form = function () {
    return {
      // 准确率
      Accuracy: function (rule:any, value:any, callback:any) {
        if (!value) {
          return callback(new Error('准确率不能为空'))
        }
        if (!accuracy.test(value)) {
          callback(new Error('请输入0-1之间且可以保留两位小数'))
        } else {
          callback()
        }
      },
      // 年龄
      Age: function (rule:any, value:any, callback:any) {
        if (!value) {
          return callback(new Error('年龄不能为空'))
        }
        if (!age.test(value)) {
          callback(new Error('请输入正确的年龄'))
        } else {
          callback()
        }
      },
      // 序列号
      EquipmentSerialNumber: function (rule:any, value:any, callback:any) {
        if (!value) {
          return callback(new Error('请输入设备序列号'))
        }
        if (!equipmentSerialNumber.test(value)) {
          callback(new Error('请输入15～17字符（字母+数字）'))
        } else {
          callback()
        }
      },
      // 赛事Id
      EventsId: function (rule:any, value:any, callback:any) {
        if (!value) {
          return callback(new Error('请输入赛事Id'))
        }
        if (!eventsId.test(value)) {
          callback(new Error('请输入1～64字符（字母，数字)'))
        } else {
          callback()
        }
      },
      // 用户名
      UserName: function (rule:any, value:any, callback:any) {
        if (!value) {
          return callback(new Error('用户名不能为空'))
        }
        if (!username.test(value)) {
          callback(new Error('请输入1～64字符（中文，字母，数字）'))
        } else {
          callback()
        }
      },
      // 密码
      Password: function (rule:any, value:any, callback:any) {
        if (!value) {
          return callback(new Error('密码不能为空'))
        }
        if (!password.test(value)) {
          callback(new Error('请输入6~15位字符(数字，字母)'))
        } else {
          callback()
        }
      },
      // 编辑密码
      EditPassword: function (rule:any, value:any, callback:any) {
        if (!value) {
          callback()
        }
        if (!editPassword.test(value)) {
          callback(new Error('请输入6~15位字符(数字，字母)'))
        } else {
          callback()
        }
      },
      // 身份证的验证规则
      ID: function (rule:any, value:any, callback:any) {
        console.log(rule, value, callback())
        if (!value) {
          return callback(new Error('身份证不能为空'))
        }
        if (!regId.test(value)) {
          callback(new Error('请输入正确的二代身份证号码'))
        } else {
          callback()
        }
      },
      // 电话号码的验证
      Tel: (rule:any, value:any, callback:any) => {
        if (!value) {
          return callback(new Error('手机号码不能为空'))
        }
        if (!tel.test(value)) {
          callback(new Error('请正确填写手机号码'))
        } else {
          callback()
        }
      }
    }
  }

  // FromOne表单验证规则  用于FromOne个性化的校验
  FormValidate.FormOne = function () {
    return {
      // 普通名字
      Name: function (rule:any, value:any, callback:any) {
        if (!value) {
          return callback(new Error('名称不能为空'))
        }
        if (!username.test(value)) {
          callback(new Error('请输入1～64字符（中文，字母，数字)'))
        } else {
          callback()
        }
      },
      // 不为空
      NoEmpty: function (rule:any, value:any, callback:any) {
        if (!value) {
          return callback(new Error('输入不能为空'))
        } else {
          callback()
        }
      }
    }
  }
  return FormValidate
}())
