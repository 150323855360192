import common from '@/common/common'
import axios, { AxiosInstance } from 'axios'
import { Router } from 'vue-router'
import { Store } from 'vuex'

export enum RESULT_FLAG {
  FAILED = 0,
  SUCCESS = 1
}

export default class Http {
  instance: AxiosInstance
  store: Store<any>
  router: Router
  loginRouteName: string
  redirectFrom403: boolean

  static BASE_URL: string = process.env.VUE_APP_BASE_URL as string

  constructor (store: Store<any>, router:Router, loginRouteName: string, redirectFrom403 = true) {
    this.store = store.state
    this.router = router
    this.loginRouteName = loginRouteName
    this.redirectFrom403 = redirectFrom403
    this.instance = axios.create(
      {
        timeout: 10 * 60 * 1000,
        baseURL: Http.BASE_URL
      }
    )
    this.instance.interceptors.request.use(
      config => {
        // 验证token是否存在请求头中
        const token = store.state.token
        token && (config.headers.token = token)
        return config
      },
      err => Promise.reject(err)
    )
    this.instance.interceptors.response.use(
      res => {
        if (res.data.result === RESULT_FLAG.SUCCESS) {
          return Promise.resolve(res.data.data)
        } else {
          const result = { result: RESULT_FLAG.FAILED, errCode: res.data.errCode, errMsg: res.data.errMsg }
          return Promise.reject(result)
        }
      },
      async (err) => {
        if (err.response.status === 403) {
          // token invalid
          if (this.redirectFrom403) {
            await store.dispatch('logout', { keySysAdminSession: common.keySysAdminSession })
            router.push({ name: loginRouteName })
          }
        }
        if (typeof err.response.data === 'string') {
          const result = { result: RESULT_FLAG.FAILED, errCode: err.response.status, errMsg: err.response.data }
          return Promise.reject(result)
        } else {
          return Promise.reject(err.response.data)
        }
      }
    )
  }
}

export const FaceAxios = axios.create(
  {
    timeout: 10 * 60 * 1000,
    baseURL: process.env.VUE_APP_FACE_BASE_URL
  }
)
