<template>
  <div class="upload">
    <el-upload
      action="#"
      list-type="picture-card"
      :limit="1"
      :auto-upload="false"
      @change="onChangeFile"
      :file-list="fileList"
      accept="image/jpg, image/jpeg, image/png"
      :class="{ hide: fileList.length >= uploadLength }">
        <template #file="{ file }">
        <div>
            <div class="loading" v-show="isUploading">
              <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                <path fill="currentColor" d="M512 64a32 32 0 0 1 32 32v192a32 32 0 0 1-64 0V96a32 32 0 0 1 32-32zm0 640a32 32 0 0 1 32 32v192a32 32 0 1 1-64 0V736a32 32 0 0 1 32-32zm448-192a32 32 0 0 1-32 32H736a32 32 0 1 1 0-64h192a32 32 0 0 1 32 32zm-640 0a32 32 0 0 1-32 32H96a32 32 0 0 1 0-64h192a32 32 0 0 1 32 32zM195.2 195.2a32 32 0 0 1 45.248 0L376.32 331.008a32 32 0 0 1-45.248 45.248L195.2 240.448a32 32 0 0 1 0-45.248zm452.544 452.544a32 32 0 0 1 45.248 0L828.8 783.552a32 32 0 0 1-45.248 45.248L647.744 692.992a32 32 0 0 1 0-45.248zM828.8 195.264a32 32 0 0 1 0 45.184L692.992 376.32a32 32 0 0 1-45.248-45.248l135.808-135.808a32 32 0 0 1 45.248 0zm-452.544 452.48a32 32 0 0 1 0 45.248L240.448 828.8a32 32 0 0 1-45.248-45.248l135.808-135.808a32 32 0 0 1 45.248 0z"></path>
              </svg>
            </div>
            <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
            <span class="el-upload-list__item-actions">
            <span
                class="el-upload-list__item-action"
                @click="handlePictureCardPreview(file)"
            >
                预览
            </span>
            <span
                v-if="!disabled"
                class="el-upload-list__item-action"
                @click="handleRemove(file)"
            >
                删除
            </span>
            </span>
        </div>
        </template>
        <span v-show="!isUploading">点击上传</span>
    </el-upload>

    <el-dialog v-model="dialogVisible" custom-class="previewImg" title="预览">
        <img :src="dialogImageUrl" alt="Preview Image" />
    </el-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, PropType, watch } from 'vue'
import { multipartUpload, baseOSSUrl } from '@/utils/oss'
import { nanoid } from 'nanoid'

interface defaultItem {
  name?: string;
  url : string;
}
export type Sumbform = Array<defaultItem>;

export default defineComponent({
  props: {
    uploadLength: {
      type: Number,
      default: 1
    },
    defaultList: {
      type: Object as PropType<Sumbform> || undefined
    },
    onUploadSuccess: Function,
    onRemove: Function
  },
  setup(props) {
    console.log(nanoid())
    // 查看上传图片地址
    const dialogImageUrl = ref('')
    // dialoag是否展示
    const dialogVisible = ref(false)
    // disabled
    const disabled = ref(false)
    // file maxLength
    const showUploadEntry = ref(true)
    // isUploading
    const isUploading = ref(false)
    // fileList
    const fileList = ref(props.defaultList || [])

    const handleRemove = file => {
      fileList.value = fileList.value.filter(item => item.url !== file.url)
      typeof props.onRemove === 'function' && props.onRemove(file)
    }

    const handlePictureCardPreview = file => {
      dialogImageUrl.value = file.url
      dialogVisible.value = true
    }

    const onChangeFile = async file => {
      isUploading.value = true
      fileList.value.push(file)
      const fileName = nanoid() + file.name.substr(file.name.lastIndexOf('.'))
      const info = await multipartUpload(fileName, file.raw)
      typeof props.onUploadSuccess === 'function' && props.onUploadSuccess(baseOSSUrl + info.name)
      showUploadEntry.value = false
      isUploading.value = false
    }

    watch(() => props.defaultList, (value) => {
      fileList.value = value?.length ? value : []
    })

    return {
      // 上传背景图相关
      fileList,
      isUploading,
      disabled,
      showUploadEntry,
      dialogVisible,
      dialogImageUrl,
      handleRemove,
      handlePictureCardPreview,
      onChangeFile
    }
  }
})
</script>
<style lang="scss" scoped>
.previewImg{
  img{
    max-width: 100%;
  }
}
.upload{
  min-height: 146px;
  .loading{
    position: absolute;
    z-index: 3;
    width: 100%;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, .8);
    svg{
      width: 16px;
      height: 16px;
      animation: turn 2s linear infinite;
    }
  }
}
@keyframes turn {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.el-upload-list__item-action{
  font-size: 12px;
  font-weight: 600;
}
.hide {
  :deep(.el-upload--picture-card) {
    display: none !important;
  }
}
:deep(.el-upload-list__item) {
  transition: none !important
}

</style>
