<template>
  <el-container>
    <div v-show="!showInfo" class="playerManagement">
      <div class="event-title">
        <span>组别</span>
      </div>
      <br />
      <!-- table -->
      <el-card shadow="hover">
        <ul class="group-list">
          <li style="margin-right: 15px;" v-for="group in groups" :key="group.id" @click="checkDetail(group)">{{ group.name }}</li>
        </ul>
      </el-card>
    </div>
    <numberInfo v-show="showInfo" @closeInfo="showInfo = false" ref="infoRef" />
  </el-container>
</template>

<script lang="ts">
import { defineComponent, Ref, ref } from 'vue'
import numberInfo from './reservedNumber.vue'
import { useStore } from 'vuex'
import api from '@/api/api'
export default defineComponent({
  components: { numberInfo },
  setup() {
    const groups: Ref<any> = ref([])
    const showInfo = ref(false)
    const infoRef = ref()
    const store = useStore()
    const eventsId = store.state.eventsId
    // 获取列表
    const getList = () => {
      // api.reservedNumber.getGroupList(eventsId).then((res: any) => {
      api.player.getEventsToGroups(eventsId).then((res: any) => {
        console.log('组列表', res)
        if (res && res.length) {
          groups.value = res
        }
      })
    }
    getList()
    const checkDetail = group => {
      showInfo.value = true
      infoRef.value.open(group)
    }
    return { groups, checkDetail, showInfo, infoRef }
  }
})
</script>
<style lang="scss" scoped>
.playerManagement {
  width: 100%;
  .event-title {
    font-size: 20px;
    font-weight: 600;
    color: #222222;
  }
  .el-card {
    min-height: 750px;
    position: relative;
    .el-table {
      color: #909399;
      font-size: 14px;
    }
    .fixedRightBottom {
      position: absolute;
      bottom: 15px;
      right: 15px;
    }
  }
  .group-list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    > li {
      padding: 5px 20px;
      border: 1px solid #409eff;
      background-color: #409eff;
      text-align: center;
      cursor: pointer;
      width: 150px;
      color: #f5f5f5;
      &:hover {
        color: #fff;
        background-color: #66b1ff;
        border-color: #66b1ff;
      }
    }
  }
  .row-bg {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 90px;
    background-color: #fff;
    padding: 0 20px;
    margin-top: 15px;
    box-shadow: 0px 0.5px 10px #ddd;
    .playerManagement-search {
      display: flex;
      height: 20px;
    }
    .el-button {
      margin-left: 10px;
    }
  }
}
</style>
