<template>
  <el-container>
    <div class="playerManagement">
      <div class="chip-title">
        <span>芯片管理</span>
      </div>
      <!-- 搜索 -->
      <el-row class="row-bg">
        <div class="playerManagement-search">
          <el-input placeholder="请输入芯片号/号码布" v-model.trim="eventName"></el-input>
          <el-button type="primary" @click="handleGetInputSearchValue">搜索</el-button>
        </div>
        <div>
          <el-button @click="handleClickVSC('chip')">导入EXCEL</el-button>
        </div>
      </el-row>
      <!-- table -->
      <el-card shadow="hover">
        <el-table
          :data="tableData"
          style="width: 100%; font-size:14px"
          :header-cell-style="{ textAlign: 'center', background: '#EFF7FF', color: '#222222' }"
          :cell-style="{ textAlign: 'center', color: '#666666' }"
        >
          <el-table-column width="100" type="index" :index="tableIndex" label="序号"></el-table-column>
          <el-table-column prop="matchName" label="赛事名称"></el-table-column>
          <el-table-column width="150" prop="numberPlate" label="号码布"></el-table-column>
          <el-table-column width="150" prop="group" label="组别"></el-table-column>
          <el-table-column width="150" label="性别">
            <template #default="scope">
              {{ scope.row.sex === 1 ? '男' : scope.row.sex === 2 ? '女' : ''}}
            </template>
          </el-table-column>
          <el-table-column prop="chipNo" label="芯片号"></el-table-column>
        </el-table>
        <!-- 分页 -->
        <paging
          class="fixedRightBottom"
          :pageTotal="pageTotal"
          :currentPageSize="currentPageSize"
          @handle-current-change="handleCurrentChange"
        ></paging>
      </el-card>
    </div>
  </el-container>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, watch } from 'vue'
import paging from '../paging.vue'
import api from '@/api/api'
import router, { routerNames } from '@/router'
import { ElMessage } from 'element-plus'
export default defineComponent({
  components: { paging },
  setup() {
    const eventName = ref()
    const tableData = ref()
    // 新增按钮
    onMounted(() => {
      getEventList()
    })
    // 分页
    // 总条数
    const pageTotal = ref(100)
    // 当前页数
    const currentPage = ref(1)
    // 当前页数条数
    const currentPageSize = ref(15)
    const handleCurrentChange = (val: any) => {
      currentPage.value = val
      getEventList()
    }
    // 搜索
    const handleGetInputSearchValue = () => {
      getEventList()
    }
    // 监听数据是否为空
    watch(
      eventName,
      nv => {
        if (nv === '') {
          eventName.value = ' '
          getEventList()
        }
      },
      { deep: true, immediate: true }
    )
    // 获取芯片列表
    const getEventList = () => {
      api.chip
        .getChipList({
          name: encodeURIComponent(eventName.value === undefined ? ' ' : eventName.value),
          page: currentPage.value,
          size: currentPageSize.value
        })
        .then((res: any) => {
          tableData.value = res.chips
          pageTotal.value = res.pages.total
        })
        .catch(err => {
          ElMessage.error(err.errMsg)
        })
    }
    const handleClickVSC = (text: string) => {
      router.push({ name: routerNames.importCSV, params: { chipPlayerId: text } })
    }
    // 序列号自增
    const tableIndex = index => {
      return (currentPage.value - 1) * currentPageSize.value + index + 1
    }
    return {
      eventName,
      tableData,
      pageTotal,
      currentPage,
      currentPageSize,
      handleCurrentChange,
      handleGetInputSearchValue,

      handleClickVSC,
      tableIndex
    }
  }
})
</script>
<style lang="scss" scoped>
.playerManagement {
  width: 100%;
  .chip-title {
    font-size: 20px;
    font-weight: 600;
    color: #222222;
  }
  .row-bg {
    background-color: #fff;
    height: 90px;
    display: flex;
    line-height: 90px;
    padding: 0 20px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 0.5px 10px #ddd;
    margin-top: 15px;
    .playerManagement-search {
      display: flex;
      height: 20px;
    }
    .el-button {
      margin-left: 10px;
    }
  }
}
.matchStyle {
  .el-input {
    width: 120px;
  }
  .el-button {
    margin: 10px 0px 0 10px;
  }
}
.dialog-footer {
  .el-button {
    margin-left: 40px;
  }
}
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-card {
  min-height: 750px;
  position: relative;
  .el-table {
    color: #909399;
    font-size: 14px;
  }
  .fixedRightBottom {
    position: absolute;
    bottom: 15px;
    right: 15px;
  }
}
:deep(.el-dialog__header) {
  padding: 20px 20px 15px;
  background-color: #eff7ff;
}
</style>
