<template>
  <div class="container">
    <div class="iconSize">
      <i :class="[isTrue ? 'el-icon-s-unfold' : 'el-icon-s-fold']" @click="handleIcon"></i>
    </div>
    <div style="color:#000">
        <span>欢迎回来</span>
        <img src="@/assets/images/chen.jpg" alt="">
        <span>{{name}}({{role}})</span>
        <u class="logoutStyle" @click="handleLogout">退出</u>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import api from '@/api/api'
import { ElMessage, ElMessageBox } from 'element-plus'
export default defineComponent({
  emits: ['showSidebar'],
  setup (prop:any, context:any) {
    const store = useStore()
    const name = store.state.name
    const role = store.state.role
    const isTrue = ref(false)
    const handleLogout = () => {
      ElMessageBox.confirm('是否确定退出', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        api.session.logout().then((res:any) => {
          console.log(res) // null
          window.sessionStorage.clear()
          window.location.reload()
        })
      }).catch(() => {
        ElMessage('取消成功')
      })
    }
    const handleIcon = () => {
      context.emit('showSidebar', isTrue.value = !isTrue.value)
    }
    return {
      name,
      role,
      handleLogout,
      handleIcon,
      isTrue
    }
  }
})
</script>
<style lang="scss" scoped>
    .container{
        height: 60px;
        line-height: 60px;
        width:100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;
        box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
        .iconSize {
          margin-left: 20px;
          font-size:20px;
          &:hover{
            cursor: pointer;
            color: #3553a3;
          }
        }
        div{
          .logoutStyle{
            margin-left: 30px;
            margin-right: 40px;
            color: #2C88FE;
            text-decoration: none;
              &:hover{
                  cursor: pointer;
              }
          }
        }
        img{
          width: 28px;
          height:28px;
          border-radius: 50%;
          border: 1px solid #ccc;
          vertical-align: middle;
          margin: 8px;
        }
    }
</style>
