<template>
  <el-container>
    <div class="app-side-nav">
      <div class="app-side-logo">
        <img src="@/assets/images/logo.svg">
      </div>
      <el-menu
        :collapse="isCollapse"
        :uniqueOpened="true"
        :default-active="routerPath"
        class="el-menu-vertical-demo"
        background-color='#3553A3'
        text-color="#fff"
        active-text-color="#324B89"
        router
      >
        <el-menu-item
          :index="item.router"
          v-for="item in sideNav"
          :key="item.id"
        >
          <img
            class="iconImg"
            :src="item.router === routerPath? item.active : item.inactive"
            alt=""
          >
          <template
            class="iconText"
            #title
          >{{item.title}}</template>
        </el-menu-item>
      </el-menu>
    </div>
    <el-container
      class="main-container"
      direction="vertical"
    >
      <app-header @show-sidebar="showSidebar"></app-header>
      <transition name="fade">
        <router-view class="main-center"></router-view>
      </transition>
    </el-container>
  </el-container>
</template>
<script lang="ts">
import { defineComponent, ref, watch } from 'vue'
import appHeader from '@/common/components/appHeader.vue'
import { adminData, SponsorData, contractOutData } from '@/utils/sideData'
import { useStore } from 'vuex'
import router from '@/router'
import { useRoute } from 'vue-router'
export default defineComponent({
  components: { appHeader },
  setup () {
    const isCollapse = ref(false)
    const routerPath = ref()
    watch(router.currentRoute.value, () => {
      routerPath.value = router.currentRoute.value.fullPath
    }, { deep: true, immediate: true })
    const route = useRoute()
    watch(() => route.path, () => {
      routerPath.value = route.path
    })
    const store = useStore()
    // 默认是admin权限
    const sideNav = ref(adminData)
    // 获取store得觉得进行相应的菜单权限展示
    if (store.state.roleId === '60fab62af7fefc45ffa1790a') {
      sideNav.value = adminData
    } else if (store.state.roleId === '60fab63af7fefc45ffa1790c') {
      sideNav.value = SponsorData
    } else {
      sideNav.value = contractOutData
    }
    const showSidebar = (val: any) => {
      isCollapse.value = val
    }
    return {
      sideNav,
      routerPath,
      isCollapse,
      showSidebar
    }
  }
})
</script>
<style lang="scss" scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  letter-spacing: 1px;
  width: 200px;
}
.app-side-nav {
  background-color: #3553a3;
  height: 100vh;
  .app-side-logo {
    height: 60px;
    background-color: #3553a3;
    position: relative;
    img {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      margin: 0 auto;
      width: 60%;
      height: 90%;
      box-sizing: border-box;
    }
  }
}
.el-container {
  overflow-y: auto;
  height: 100vh;
  box-sizing: border-box;
  .main-center {
    padding: 20px;
  }
}
.iconImg {
  margin-top: -4px;
  margin-right: 8px;
}
.el-main {
  color: #333;
  overflow: inherit;
}
.el-menu {
  border-right: none;
}
.el-menu-item.is-active {
  background-color: #e7eaf2 !important;
  color: #fff;
  span {
    color: #fff !important;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
